/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import Toasts from './containers/Toasts/Toasts';
import Main from './containers/Main/Main';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import ApisList from './routes/ApisList/ApisList';
import ConnectionsList from './routes/ConnectionsList/ConnectionsList';
import ConnectionsCreate from './routes/ConnectionsCreate/ConnectionsCreate';
import ProjectCreate from './routes/ProjectCreate';
// import Export from './routes/Export';
import ApisImport from './routes/ApisImport/ApisImport';
import ConnectionsImport from './routes/ConnectionsImport/ConnectionsImport';
import ConnectionsModify from './routes/ConnectionsModify/ConnectionsModify';
import ApisModify from './routes/ApisModify/ApisModify';
import UsersList from './routes/UsersList/UsersList';
import UsersCreate from './routes/UsersCreate/UsersCreate';
import UsersModify from './routes/UsersModify/UsersModify';
import RolesList from './routes/RolesList/RolesList';
import RolesCreate from './routes/RolesCreate/RolesCreate';
import RolesModify from './routes/RolesModify/RolesModify';
import ApisCreate from './routes/ApisCreate/ApisCreate';

import SuccessMessage from './routes/Auth/SuccessMessage';
import Login from './routes/Auth/Login';
import Register from './routes/Auth/Register';
import Invitation from './routes/Auth/Invitation';
import ResetPassword from './routes/Auth/ResetPassword';
import ChangePassword from './routes/Auth/ChangePassword';

import NewProjectWelcome from './routes/NewProjectWelcome/NewProjectWelcome';

import customHistory from './history';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <HistoryRouter history={customHistory}>
        <Routes>
          <Route path="/" element={<ProtectedRoute><Main /></ProtectedRoute>}>
            <Route path=":shortName/create-project" element={<ProjectCreate />} />
            {/* <Route path=":shortName/export" element={<Export />} /> */}
            <Route path=":shortName/endpoints" element={<ApisList />} />
            <Route path=":shortName/endpoints/import" element={<ApisImport />} />
            <Route path=":shortName" element={<Navigate to="endpoints" replace />} />
            <Route path=":shortName/endpoints" element={<ApisList />} />
            <Route path=":shortName/endpoints/folder/:folderId" element={<ApisList />} />
            <Route path=":shortName/endpoints/create" element={<ApisCreate />} />
            <Route path=":shortName/endpoints/folder/:folderId/create" element={<ApisCreate />} />
            <Route path=":shortName/endpoints/:endpointId" element={<ApisModify />} />
            <Route path=":shortName/connections" element={<ConnectionsList />} />
            <Route path=":shortName/connections/folder/:folderId" element={<ConnectionsList />} />
            <Route path=":shortName/connections/import" element={<ConnectionsImport />} />
            <Route path=":shortName/connections/create" element={<ConnectionsCreate />} />
            <Route path=":shortName/connections/folder/:folderId/create" element={<ConnectionsCreate />} />
            <Route path=":shortName/connections/:resourceId" element={<ConnectionsModify />} />
            <Route path=":shortName/users" element={<UsersList />} />
            <Route path=":shortName/users/create" element={<UsersCreate />} />
            <Route path=":shortName/users/:resourceId" element={<UsersModify />} />
            <Route path=":shortName/roles" element={<RolesList />} />
            <Route path=":shortName/roles/create" element={<RolesCreate />} />
            <Route path=":shortName/roles/:resourceId" element={<RolesModify />} />
            <Route
              path="*"
              element={(
                <main style={{ padding: '1rem' }}>
                  <p>There&apos;s nothing here!</p>
                </main>
            )}
            />
          </Route>
          <Route path="/new-project" element={<NewProjectWelcome />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/invite" element={<Invitation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset_pass" element={<ChangePassword />} />

          <Route path="/register/success" element={<SuccessMessage type="register" />} />
          <Route path="/invite/success" element={<SuccessMessage type="invitation" />} />
          <Route path="/reset-password/success" element={<SuccessMessage type="resetPassword" />} />
          <Route path="/change-password/success" element={<SuccessMessage type="changePassword" />} />
        </Routes>
        <Toasts />
      </HistoryRouter>
    </Provider>
  );
}

export default App;
