/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SelectedIcon from '../../pictures/icon-selected.svg';
import ArrowIcon from '../../pictures/icon-arrow.svg';

import * as style from './Select.module.scss';

function Select({
  style: customStyle = {},
  value,
  handleChange,
  placeholder,
  list,
  name,
  handleToggleOptions,
  component,
  disabled,
  type,
  error,
}) {
  const ref = useRef(null);
  const getValueIndex = (l, v) => l?.findIndex((el) => el.value === v);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(getValueIndex(list, value));

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
    handleToggleOptions && handleToggleOptions();
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
    handleChange(name, list[index]?.value);
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (value && value !== selectedOption) {
      const ind = getValueIndex(list, value);
      setSelectedOption(ind);
    }
  }, [value, list]);

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : list.length - 1,
        );
        handleChange(name, list[selectedOption - 1 >= 0 ? selectedOption - 1 : list.length - 1].value);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(
          selectedOption === list.length - 1 ? 0 : selectedOption + 1,
        );
        handleChange(name, list[selectedOption === list.length - 1 ? 0 : selectedOption + 1].value);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={cn(style.select, {
        [style.active]: isOptionsOpen,
        [style.error]: error,
        [style.text]: type === 'text',
      })}
      style={customStyle}
    >
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        className={isOptionsOpen ? 'expanded' : ''}
        onClick={toggleOptions}
        onKeyDown={handleListKeyDown}
        disabled={disabled}
      >
        {
          selectedOption === -1
            ? <span className={style.placeholder}>{placeholder}</span>
            : (
              <div className={style.selectedTitle}>
                {list && list[selectedOption]?.icon}
                <span>{list && list[selectedOption]?.title}</span>
              </div>
            )
        }
        {!disabled && (
          <div className={style.selectAngle}>
            <ArrowIcon />
          </div>
        )}
      </button>
      <ul
        className={cn(style.options, {
          [style.show]: isOptionsOpen,
          [style.top]: type === 'top',
        })}
        role="listbox"
        aria-activedescendant={list && list[selectedOption]}
        tabIndex={-1}
        onKeyDown={handleListKeyDown}
        ref={ref}
      >
        {list?.map((option, index) => (option.component ? (
          <li
            key={option.value}
            role="option"
            onClick={() => {
              setIsOptionsOpen(false);
            }}
          >
            {option.component}
          </li>
        ) : (
          <li
            className={cn({
              [style.delete]: option.value === 'delete',
              [style.hidden]: option.hidden,
            })}
            key={option.value}
            role="option"
            aria-selected={selectedOption === index}
            tabIndex={0}
            onKeyDown={handleKeyDown(index)}
            onClick={() => {
              if (option.disabled) return;
              setSelectedThenCloseDropdown(index);
            }}
            disabled={option.disabled}
          >
            {option.icon}
            <span>{option.title}</span>
            {selectedOption === index && (
              <div className={style.iconSelected}>
                <SelectedIcon />
              </div>
            )}
          </li>
        )))}
      </ul>
    </div>
  );
}

Select.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.object,
  })),
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default Select;
