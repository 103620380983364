/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { uid } from 'uid';
import {
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Button from '../../components/Button/Button';
import { createToastRequest } from '../../store/toasts/actions';
import InputFile from '../../components/InputFile';
import { useCopyToClipboard } from '../../hooks/hooks';
import Input from '../../components/Input/Input';
import ChatGpt from '../../components/ChatGpt';
import NewFeatureLabel from '../../components/NewFeatureLabel';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Select from '../../components/Select/Select';
import {
  roleMapper, paramsParser, formatingJSONToRender, parseSqlTags,
  parseIfJSON, stringifyIfJSON, sqlPlaceholder, isJsonString,
} from '../../helpers/utils';
import {
  typesAPI, loadStatus, backendHost,
} from '../../helpers/const';
import DebugConsole from '../../components/DebugConsole';
import Loader from '../../components/Loader';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import CodeEditor from '../../components/CodeEditor';
import DBIcon from '../../components/DBIcon/DBIcon';
import { createEndpointRequest, testEndpointRequest, gptRequest as gptRequestData } from '../../store/endpoints/actions';
import { listConnectionsRequest } from '../../store/connections/actions';
import IconChecked from '../../pictures/icon-checked.svg';
import IconAlert from '../../pictures/icon-alert.svg';
import { ParamentRows } from '../../components/ParametrRows/ParamentRows';

import * as shared from '../../styles/shared.module.scss';

const getGptButtonText = (status) => {
  switch (status) {
    case loadStatus.LOAD:
      return 'Generating SQL';
    case loadStatus.SUCCESS:
      return 'Regenerate SQL';
    case loadStatus.ERROR:
      return 'Try again';
    default:
      return 'Generate SQL';
  }
};

const defaultValues = {
  string: '',
  number: 0,
};

const connectionTypeList = [
  {
    value: 'database',
    title: 'Database',
    icon: <DBIcon type="database" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'corezoid',
    title: 'Corezoid',
    icon: <DBIcon type="corezoid" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'AWS',
    title: 'AWS',
    icon: <DBIcon type="AWS" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'chatGPT',
    title: 'ChatGPT',
    icon: <DBIcon type="chatGPT" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'none',
    title: 'None',
    icon: <DBIcon type="database" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
];

const emptyParam = {
  name: '', description: '', defaultValue: '', type: 'string', id: uid(8), required: 'no',
};

const validResponseExample = (val) => isJsonString(val) || val === '';
const maxAllowedLength = 64;

const ApisCreateSchema = Yup.object().shape({
  name: Yup.string().min(2).max(64).matches(/^(?!\s)(?!.* {2})/)
    .required('Name required'),
  endpoint: Yup.string().min(2).max(64).matches(/^[\/{}_&=?\-\w]+$/g)
    .required('Endpoint required'),
  method: Yup.string().required('Method required'),
  authorizationMethod: Yup.string().required('AuthMethod required'),
  authLogin: Yup.string()
    .when('authorizationMethod', {
      is: (authorizationMethod) => authorizationMethod === 'basic',
      then: Yup.string().required('Login required'),
    }),
  authPassword: Yup.string()
    .when('authorizationMethod', {
      is: (authorizationMethod) => authorizationMethod === 'basic',
      then: Yup.string().required('Password required'),
    }),
  connectionId: Yup.string()
    .when('connectionType', {
      is: (connectionType) => ['database', 'corezoid', 'chatGPT', 'AWS'].includes(connectionType),
      then: Yup.string().required('connectionId required'),
    }),
  RPS: Yup.number()
    .required('RPS required')
    .typeError('RPS must be a number')
    .test('isInteger', 'Not integer value provided', (value) => Number.isInteger(value))
    .max(100, 'Max limit'),
  processId: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'corezoid',
      then: Yup.string().required('Process Id required'),
    }),
  bucketName: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'AWS',
      then: Yup.string().required('Bucket Name required'),
    }),
  fileName: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'AWS',
      then: Yup.string().required('File Name required'),
    }),
  s3Method: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'AWS',
      then: Yup.string().required('S3 method required'),
    }),
  file: Yup.string()
    .when(['connectionType', 's3Method'], {
      is: (connectionType, s3Method) => connectionType === 'AWS' && ['put', 'post'].includes(s3Method),
      then: Yup.string().required('File required'),
    }),
  query: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'database',
      then: Yup.string().required('SQL required'),
    }),
  gptData: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'chatGPT',
      then: Yup.string().required('Query required'),
    }),
  gptConnectionId: Yup.string()
    .when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string().required('ConnectionId required'),
    }),
  gptRequest: Yup.string()
    .when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string().required('gptRequest required'),
    }),
  params: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Name required'),
        type: Yup.string().required('Type required'),
      }),
    ),
});

function ApisCreate({
  createEndpoint, getConnections, connections, currentUser,
  activeProject, testEndpoint, dataTypes, createToast, gptSendRequest,
}) {
  const { data: connectionsData = [] } = connections || {};
  const [gptResponseStatus, setGptResponseStatus] = useState(loadStatus.INITIAL);
  const [chatGptQueryTriggered, setChatGptQueryTrigerred] = useState(false);

  const navigate = useNavigate();
  const { shortName, folderId } = useParams();
  const [editorModeJs, setEditorModeJs] = useState(false);
  const [testDebugStatus, setTestDebugStatus] = useState(loadStatus.INITIAL);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [parameterType, setParameterType] = useState('desription');
  const listAPI = () => (folderId ? navigate(`/${shortName}/endpoints/folder/${folderId}`) : navigate(`/${shortName}/endpoints`));
  const [copiedText, copyText] = useCopyToClipboard();

  const [gptResponseError, setGptResponseError] = useState(false);

  const [typesList, setTypesList] = useState([
    { value: 'string', title: 'String' },
    { value: 'number', title: 'Number' },
  ]);

  const [requestExample, setRequestExample] = useState('');
  const [requestExampleData, setRequestExampleData] = useState('');
  const [responseExample, setResponseExample] = useState('');
  const [submitTriggered, setSubmitTriggered] = useState(false);

  const [responseRequestCustom, setResponseRequestCustom] = useState({});
  const [responseExampleActiveCode, setResponseExampleActiveCode] = useState('200');

  useEffect(() => {
    if (activeProject?.id) {
      getConnections({ id: activeProject?.id });
    }
  }, [activeProject?.id]);

  useEffect(() => {
    setResponseRequestCustom({
      ...responseRequestCustom,
      [responseExampleActiveCode]: responseExample?.length ? parseIfJSON(responseExample) : '',
    });
  }, [responseExample]);

  useEffect(() => {
    const result = { ...responseRequestCustom, active: responseExampleActiveCode };
    setResponseRequestCustom(result);
    setResponseExample(result[responseExampleActiveCode] ? stringifyIfJSON(result[responseExampleActiveCode]) : '');
  }, [responseExampleActiveCode]);

  useEffect(() => {
    if (currentUser) {
      setInviteList([{
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        userEmail: currentUser.email,
        roleId: 2,
      }]);
    }
  }, [currentUser]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      endpoint: '',
      method: '',
      s3Method: '',
      outputFormat: 'json',
      status: 'active',
      authorizationMethod: 'none',
      authLogin: '',
      authPassword: '',
      instanceType: '',
      connectionType: '',
      connectionId: '',
      processId: '',
      query: '',
      params: [],
      sqlType: 'rawSql',
      RPS: 100,
    },
    validationSchema: ApisCreateSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      if (v.connectionType === 'corezoid' && !v.processId) {
        return;
      }

      if ((v.connectionType === 'none' || !v.connectionType?.length) && !validResponseExample(responseExample)) {
        setSubmitTriggered(true);
        return;
      }

      const req = {
        auth: {
          authParams: v.authorizationMethod === 'basic' ? {
            user: v.authLogin,
            password: v.authPassword,
          } : {},
          authType: v.authorizationMethod,
        },
        folder: folderId ? +folderId : 0,
        connectionId: v.connectionId,
        name: v.name,
        description: v.description,
        httpRequestParams: v.params?.map((p) => ({
          name: p.name,
          description: p.description,
          defaultValue: p.defaultValue,
          type: p.type,
          required: p.required === 'yes',
        })),
        method: v.method,
        path: v.endpoint,
        limit: +v.RPS,
        projectId: +activeProject.id,
      };

      if (v.connectionType === 'corezoid') {
        req.connectionId = v.connectionId;
        req.processId = v.processId;
        req.httpCode = v.httpCode;
      } else if (v.connectionType === 'AWS') {
        req.connectionId = v.connectionId;
        req.bucketName = v.bucketName;
        req.folderName = v.folderName;
        req.fileName = v.fileName;
        req.file = v.file;
      } else if (v.connectionType === 'chatGPT') {
        req.connectionId = v.connectionId;
        req.query = v.gptData;
        req.context = v.context;
      } else if (v.connectionType === 'database') {
        req.connectionId = v.connectionId;
        req.dbQueryParams = {
          query: v.query,
        };
        req.gptConnectionId = v.sqlType === 'chatGptSql' ? v.gptConnectionId : 0;
        req.gptRequest = v.sqlType === 'chatGptSql' ? v.gptRequest : undefined;
        req.gptContext = v.sqlType === 'chatGptSql' ? v.gptContext : undefined;
      }

      const getResponseExample = () => {
        const result = Object.entries(responseRequestCustom).reduce((acc, [code, jsonResp]) => (jsonResp === '' ? acc : { ...acc, [code]: jsonResp }), {});
        return Object.keys(result).length === 1 ? {} : result;
      };

      if (!v.connectionType || v.connectionType === 'none') {
        req.status = 'draft';
        req.responseExample = getResponseExample();
      } else {
        req.responseExample = {};
        req.status = 'active';
      }

      createEndpoint(req, () => (folderId ? navigate(`/${shortName}/endpoints/folder/${folderId}`) : navigate(`/${shortName}/endpoints`)));
    },
  });

  const {
    submitForm, handleChange, setFieldValue, validateForm, values, errors,
  } = formik;

  const setFieldValueProxy = (...arg) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setFieldValue(...arg);
  };

  const handleChangeProxy = (...arg) => {
    setTestDebugStatus(loadStatus.INITIAL);
    handleChange(...arg);
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const saveInviteList = (data) => {
    const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handeChangeRole = (email, role) => {
    const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
    setInviteList(newInviteList);
  };

  const connectionsCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type !== 'corezoid' && d.type !== 'AWS' && d.type !== 'chatGPT') : [];
  const chatGptCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type === 'chatGPT') : [];
  const corezoidCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type === 'corezoid') : [];
  const awsCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type === 'AWS') : [];

  const handeChangeConnectionType = (key, value) => {
    if (value === 'corezoid' && corezoidCollection.length > 0) {
      setFieldValueProxy('connectionId', corezoidCollection[0].id);
      changeInstanceType(corezoidCollection[0].id);
    } else if (value === 'AWS' && awsCollection.length > 0) {
      setFieldValueProxy('connectionId', awsCollection[0].id);
      changeInstanceType(awsCollection[0].id);
    } else if (value === 'chatGPT' && chatGptCollection.length > 0) {
      setFieldValueProxy('connectionId', chatGptCollection[0].id);
      changeInstanceType(chatGptCollection[0].id);
    } else if (value === 'database' && connectionsCollection.length > 0) {
      setFieldValueProxy('connectionId', connectionsCollection[0].id);
      changeInstanceType(connectionsCollection[0].id);
    } else {
      setFieldValueProxy('connectionId', '');
    }
    setFieldValueProxy([key], value);
  };

  const handleChangeEndpoint = (key, value) => {
    let newValue = value;
    if (value && value[0] !== '/') {
      newValue = `/${value}`;
    }
    const validationRegex = /^[\/{}_&=?\-\w]+$/g;
    if (value === '' || (validationRegex.test(value) && value.length <= maxAllowedLength)) {
      setFieldValueProxy([key], newValue.replace(/\s/g, ''));
    }
  };

  const handelChangeName = ({ target }) => {
    const validationRegex = /^(?!\s)(?!.* {2})/;
    if (target.value === '' || (validationRegex.test(target.value) && target.value.length <= maxAllowedLength)) {
      setFieldValueProxy(target.name, target.value);
    }
  };

  const addParameter = () => {
    const prev = values.params;
    setFieldValueProxy('params', [...prev, { ...emptyParam, id: uid(8) }]);
  };

  const updateRequestExample = (name, value, id) => {
    const requestData = {};
    let oldRequest = {};
    if (requestExample.length) {
      try {
        parsed = JSON.parse(requestExample);
        oldRequest = parsed;
      } catch (e) {
        console.log('failed to parse JSON from input');
      }
    }
    values?.params?.forEach((p) => {
      let newValue = oldRequest[p.name];
      const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

      if (!newValue || isEmptyArray(newValue) || isEmptyObject(newValue)) {
        newValue = p.defaultValue || defaultValues[p.type];
      }

      if (name && p.name === name) {
        requestData[p.name] = value;
      } else if (id && p.id === id) {
        requestData[value] = p.defaultValue;
        delete requestData[p.name];
      } else {
        requestData[p.name] = newValue;
      }
    });
    const newR = JSON.stringify(requestData);
    if (newR !== requestExampleData) {
      setRequestParams(newR);
    }
  };

  useEffect(() => {
    updateRequestExample();
  }, [values.params.length]);

  useEffect(() => {
    if (connectionsCollection?.length && values.connectionId) {
      const find = connectionsCollection.find((el) => el.id === values.connectionId);
      const isMongo = find && find.type === 'mongo';

      setEditorModeJs(isMongo);
    }
  }, [connectionsCollection, values.connectionId]);

  const connectionsList = (dbs) => {
    if (dbs) {
      return [...dbs.map((d) => ({
        value: d.id,
        title: `${d.instanceName} (${typesAPI[d.type] || d.type})`,
        icon: <DBIcon type={d.type} style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
      }))];
    }
    return [];
  };

  const parseParams = (valueEdit) => {
    const { params } = values;
    const par = paramsParser(valueEdit);
    const unique = [...new Set(par)];
    const newParams = [];
    let resulting = [];
    unique.forEach((el) => {
      const exist = params.find((p) => p.name === el);
      if (!exist) {
        newParams.push({
          name: el, description: '', defaultValue: '', type: 'string', id: uid(8), required: 'no',
        });
      }
    });
    if (newParams.length === 0 && params[0]?.name === '') {
      resulting = params;
    } else if (params.length === 1 && params[0].name === '') {
      resulting = newParams;
    } else {
      resulting = [...params, ...newParams];
    }
    if (JSON.stringify(resulting) !== JSON.stringify(values.params)) {
      setFieldValueProxy('params', resulting);
    }
  };

  const handleBlurField = (e) => {
    parseParams(e.target.value);
  };

  const sendDebugRequest = async () => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length) return;
      let requestData = requestExampleData
        .replace(/\n/g, '')
        .replace(/ /g, '');

      if (requestExampleData.length) {
        try {
          requestData = JSON.parse(requestData);
        } catch (e) {
          console.log('failed to parse JSON from input');
        }
      }

      setTestDebugStatus(loadStatus.LOAD);

      const {
        authPassword, authLogin, connectionId,
        description, query, params, gptData, context,
        method, status, name, endpoint,
        bucketName, folderName, fileName, file,
        connectionType, processId, sqlType, httpCode,
        gptConnectionId, gptRequest, gptContext,
        authorizationMethod,
      } = values;

      const data = {
        api: {
          description,
          folder: folderId ? +folderId : 0,
          httpRequestParams: params.map((p) => ({
            name: p.name,
            description: p.description,
            defaultValue: p.defaultValue,
            type: p.type,
            required: p.required === 'yes',
          })),
          method,
          status,
          name,
          auth: {
            authParams: authorizationMethod === 'basic' ? {
              user: authLogin,
              password: authPassword,
            } : {},
            authType: authorizationMethod,
          },
          projectId: +activeProject.id,
          path: endpoint,
        },
        request: requestData,
      };

      if (connectionType === 'corezoid') {
        data.api.connectionId = connectionId;
        data.api.processId = processId;
        data.api.httpCode = httpCode;
      } else if (connectionType === 'AWS') {
        data.api.connectionId = connectionId;
        data.api.bucketName = bucketName;
        data.api.folderName = folderName;
        data.api.fileName = fileName;
        data.api.file = file;
      } else if (connectionType === 'chatGPT') {
        data.api.connectionId = connectionId;
        data.api.dbQueryParams = {
          query: gptData,
        };
        data.api.query = gptData;
        data.api.context = context;
      } else if (connectionType === 'database') {
        data.api.connectionId = connectionId;
        data.api.dbQueryParams = {
          query,
        };
        data.api.gptConnectionId = sqlType === 'chatGptSql' ? gptConnectionId : 0;
        data.api.gptRequest = sqlType === 'chatGptSql' ? gptRequest : undefined;
        data.api.gptContext = sqlType === 'chatGptSql' ? gptContext : undefined;
      }

      if (!connectionType || connectionType === 'none') {
        data.api.status = 'draft';
        if (responseRequestCustom.length) data.api.responseExample = parseIfJSON(responseRequestCustom);
      } else {
        data.api.responseExample = {};
        data.api.status = 'active';
      }

      testEndpoint(data, (stats, response) => {
        if (stats !== 'ERROR') setResponseExample(stringifyIfJSON(parseIfJSON(response)));
        if (stats === 'ERROR') setResponseExample(formatingJSONToRender(response));

        setTestDebugStatus(loadStatus[stats]);
      });
    });
  };

  const setRequestParams = (param) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setRequestExampleData(param);
    setRequestExample(formatingJSONToRender(param));
  };

  const renderDebugReqStatus = (st) => {
    switch (st) {
      case loadStatus.INITIAL:
        return <ActionText title="Send test request" onClick={sendDebugRequest} />;
      case loadStatus.SUCCESS:
        return (
          <>
            <p className={shared.successText}>Success</p>
            <IconChecked />
          </>
        );
      case loadStatus.ERROR:
        return (
          <>
            <p className={shared.errorText}>Error</p>
            <IconAlert />
          </>
        );
      case loadStatus.LOAD:
        return (
          <>
            <ActionText disabled title="Sending test request..." onClick={sendDebugRequest} />
            <Loader disabled />
          </>
        );
      default:
        return <ActionText title="Send test request" onClick={sendDebugRequest} />;
    }
  };

  const changeTypesList = (it) => {
    let list = [
      { value: 'string', title: 'String' },
      { value: 'number', title: 'Number' },
    ];
    if (it && dataTypes && dataTypes[it]) {
      list = dataTypes[it].map((t) => ({ value: t.toLowerCase(), title: t }));
    }
    return list;
  };

  const changeInstanceType = (id) => {
    const iT = connectionsData?.find((c) => c.id === id)?.type;
    if (!iT) return;
    setFieldValueProxy('instanceType', iT);
    const newParams = [];
    const checkType = (oldType) => {
      let type = 'string';
      if (dataTypes[iT]?.findIndex((t) => t.toLowerCase() === oldType) !== -1) {
        type = oldType;
      }
      return type;
    };
    values.params.forEach((p) => newParams.push({ ...p, type: checkType(p.type) }));
    setFieldValueProxy('params', newParams);
    setTypesList(changeTypesList(iT));
  };

  const handleGenerateSQL = () => {
    setGptResponseStatus(loadStatus.LOAD);
    gptSendRequest({
      gptConnectionId: values.gptConnectionId,
      connectionId: values.connectionId,
      query: values.gptRequest,
      context: values.gptContext,
    }, (result, res) => {
      setChatGptQueryTrigerred(true);
      if (result !== 'ERROR') {
        const sqlTags = parseSqlTags(res);
        setGptResponseStatus(loadStatus.SUCCESS);
        setFieldValueProxy('chatGptResponse', res);
        setFieldValueProxy('query', sqlTags);
        setGptResponseError(!sqlTags.length);
      } else {
        setGptResponseStatus(loadStatus.ERROR);
        setFieldValueProxy('chatGptResponse', 'Error!');
        setGptResponseError(true);
      }
    });
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Create API</h1>
          <p className={shared.headerDescription}>Version 1.2.0</p>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Cancel" type="secondary" onClick={listAPI} />
          <Button title="Create API" onClick={submitForm} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>API Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter API name"
                  handleChange={handelChangeName}
                  value={values.name}
                  name="name"
                  error={!!errors.name && values.name?.length < 2}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Description (Optional)</span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Endpoint</span>
                <Input
                  placeholder="/endpoint"
                  handleChange={(e) => handleChangeEndpoint('endpoint', e.target.value)}
                  value={values.endpoint}
                  name="endpoint"
                  error={!!errors.endpoint && values.endpoint?.length < 2}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>HTTP method</span>
                <Select
                  list={[
                    { value: 'get', title: 'GET' },
                    { value: 'post', title: 'POST' },
                    { value: 'put', title: 'PUT' },
                    { value: 'patch', title: 'PATCH' },
                    { value: 'delete', title: 'DELETE' },
                  ]}
                  value={values.method}
                  handleChange={setFieldValueProxy}
                  name="method"
                  placeholder="Select method"
                  error={!!errors.method && !values.method}
                  disabled={values.s3Method?.length && values.connectionType === 'AWS'}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Output format</span>
                <Select
                  list={[
                    { value: 'json', title: 'JSON' },
                  ]}
                  value={values.outputFormat}
                  placeholder="Select format"
                  handleChange={setFieldValueProxy}
                  name="outputFormat"
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Status</span>
                <Select
                  list={[
                    { value: 'active', title: 'Active' },
                    { value: 'draft', title: 'Draft' },
                    { value: 'inactive', title: 'Inactive' },
                  ]}
                  value={values.status}
                  handleChange={setFieldValueProxy}
                  name="status"
                  disabled
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Authorization</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Authorization method</span>
                <Select
                  list={[{ value: 'basic', title: 'Basic' }, { value: 'none', title: 'None' }]}
                  value={values.authorizationMethod}
                  handleChange={setFieldValueProxy}
                  placeholder="Choose authorization method"
                  name="authorizationMethod"
                />
              </div>
              <div className={shared.inputWrapper} />
              {values.authorizationMethod === 'basic' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Login</span>
                    <Input
                      placeholder="Enter login"
                      handleChange={handleChangeProxy}
                      value={values.authLogin}
                      iconRight={values.authLogin && 'copy'}
                      handleAction={() => {
                        copyText(values.authLogin) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      name="authLogin"
                      error={!!errors.authLogin && !values.authLogin}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Password</span>
                    <Input
                      placeholder="Enter password"
                      handleChange={handleChangeProxy}
                      value={values.authPassword}
                      iconRight={values.authPassword && 'copy'}
                      isPassword
                      handleAction={() => {
                        copyText(values.authPassword) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      name="authPassword"
                      id="authPassword"
                      type="password"
                      error={!!errors.authPassword && !values.authPassword}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Connection Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Connection type</span>
                <Select
                  list={connectionTypeList}
                  value={values.connectionType}
                  placeholder="Choose connetion type"
                  handleChange={(key, value) => {
                    handeChangeConnectionType(key, value);
                    // method and s3 method must be equeal when AWS connection used
                    if (value === 'AWS' && values.s3Method?.length) setFieldValueProxy('method', values.s3Method);
                  }}
                  name="connectionType"
                  error={!!errors.connectionType && !values.connectionType}
                />
              </div>
              {(values.connectionType === 'AWS' && awsCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(awsCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>S3 method</span>
                    <Select
                      list={[
                        { value: 'post', title: 'Save file' },
                        { value: 'get', title: 'Search file' },
                        { value: 'put', title: 'Update file' },
                        { value: 'delete', title: 'Delete file' },
                      ]}
                      value={values.s3Method}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        setFieldValueProxy('method', value);
                      }}
                      name="s3Method"
                      placeholder="Select method"
                      error={!!errors.s3Method && !values.s3Method}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Bucket name</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingBucket.html"
                        title={(
                          <ActionIcon
                            id="bucketName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="Bucket name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.bucketName}
                      name="bucketName"
                      error={!!errors.bucketName && !values.bucketName}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Folder name</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html"
                        title={(
                          <ActionIcon
                            id="folderName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="Folder name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.folderName}
                      name="folderName"
                      error={!!errors.folderName && !values.folderName}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>File name</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html"
                        title={(
                          <ActionIcon
                            id="fileName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="File name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.fileName}
                      name="fileName"
                      error={!!errors.fileName && !values.fileName}
                    />
                  </div>
                  {(values.s3Method === 'put' || values.s3Method === 'post') ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>File</span>
                      </span>
                      <Input
                        placeholder="File"
                        handleChange={handleChangeProxy}
                        onBlur={handleBlurField}
                        value={values.file}
                        name="file"
                        error={!!errors.file && !values.file}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {(values.connectionType === 'corezoid' && corezoidCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(corezoidCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid Process ID</span>
                    <Input
                      placeholder="Enter process ID"
                      handleChange={handleChangeProxy}
                      value={values.processId}
                      name="processId"
                      iconRight={values.processId && 'copy'}
                      handleAction={() => {
                        copyText(values.processId) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.processId}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Custom HTTP code for Response</span>
                    <Input
                      placeholder="Enter Custom HTTP code value"
                      handleChange={handleChangeProxy}
                      value={values.httpCode}
                      iconRight={values.httpCode && 'copy'}
                      handleAction={() => {
                        copyText(values.httpCode) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      name="httpCode"
                      error={!!errors.httpCode}
                    />
                  </div>
                </>
              ) : null}
              {(values.connectionType === 'chatGPT' && chatGptCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(chatGptCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>ChatGPT Request</span>
                      <ActionText
                        target="https://platform.openai.com/docs/introduction"
                        title={(
                          <ActionIcon
                            id="request_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open Chat GPT documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <CodeEditor
                      value={values.gptData}
                      handleChange={(value) => setFieldValueProxy('gptData', value)}
                      handleBlurCallback={parseParams}
                      name="gptData"
                      type="json"
                      placeholder="Enter request to ChatGPT"
                      error={!!errors.gptData && !values.gptData}
                      style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Context</span>
                      <ActionText
                        target="https://platform.openai.com/docs/introduction"
                        title={(
                          <ActionIcon
                            id="context_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open Chat GPT documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <CodeEditor
                      value={values.context}
                      handleChange={(value) => setFieldValueProxy('context', value)}
                      handleBlurCallback={parseParams}
                      name="context"
                      type="json"
                      placeholder="Context"
                      error={!!errors.context && !values.context}
                      style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                    />
                  </div>
                </>
              ) : null}
              {(values.connectionType === 'database' && connectionsCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(connectionsCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {(values.connectionType === 'database') ? (
              <>
                {!editorModeJs ? (
                  <div className={cn(shared.sectionContainer)}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Request type</span>
                      <Select
                        list={[
                          { value: 'rawSql', title: 'Raw SQL' },
                          { value: 'chatGptSql', title: 'ChatGPT-generated SQL' },
                        ]}
                        value={values.sqlType}
                        placeholder="Select SQL mode"
                        handleChange={(key, value) => {
                          setFieldValueProxy(key, value);
                        }}
                        name="sqlType"
                        error={!!errors.sqlType && !values.sqlType}
                      />
                    </div>

                    {(values.sqlType === 'chatGptSql' && !editorModeJs) ? (
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>ChatGPT instance</span>
                        <Select
                          list={[
                            ...connectionsList(chatGptCollection),
                            { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                          ]}
                          value={values.gptConnectionId}
                          placeholder="Select instance"
                          handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                          handleChange={(key, value) => {
                            setFieldValueProxy(key, value);
                          }}
                          name="gptConnectionId"
                          error={!!errors.gptConnectionId && !values.gptConnectionId}
                        />
                      </div>
                    ) : <div /> }
                  </div>
                ) : null}
                {(values.sqlType === 'chatGptSql' && !editorModeJs) ? (
                  <div className={shared.sectionContainer}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>ChatGPT Request</span>
                        <ActionText
                          target="https://platform.openai.com/docs/introduction"
                          title={(
                            <ActionIcon
                              id="database_request_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Open Chat GPT documentation',
                                place: 'right',
                              }}
                            />
                          )}
                        />
                      </span>
                      <CodeEditor
                        value={values.gptRequest}
                        handleChange={(value) => setFieldValueProxy('gptRequest', value)}
                        handleBlurCallback={parseParams}
                        name="gptRequest"
                        type="json"
                        placeholder="Enter request to ChatGPT"
                        error={!!errors.gptRequest && !values.gptRequest}
                        style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                      />
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Context</span>
                        <ActionText
                          target="https://platform.openai.com/docs/introduction"
                          title={(
                            <ActionIcon
                              id="database_context_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Open Chat GPT documentation',
                                place: 'right',
                              }}
                            />
                          )}
                        />
                      </span>
                      <CodeEditor
                        value={values.gptContext}
                        handleChange={(value) => setFieldValueProxy('gptContext', value)}
                        handleBlurCallback={parseParams}
                        name="gptContext"
                        type="json"
                        placeholder="Context"
                        error={!!errors.gptContext && !values.gptContext}
                        style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                      />
                    </div>
                  </div>
                ) : null}
                {(values.sqlType === 'chatGptSql' && !editorModeJs) && (
                  <div className={cn(shared.sectionContainer, shared.wide)}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>ChatGPT Response</span>
                      {!chatGptQueryTriggered ? (
                        <Input
                          disabled
                          value=""
                          placeholder="Waiting for request..."
                          handleChange={() => {}}
                          name="chatGptResponseFake"
                        />
                      ) : (
                        <CodeEditor
                          readOnly
                          value={values.chatGptResponse}
                          handleChange={(value) => setFieldValueProxy('chatGptResponse', value)}
                          name="chatGptResponse"
                          type="json"
                          placeholder=""
                          error={!!errors.chatGptResponse && !values.chatGptResponse}
                          style={{ maxWidth: 'calc(100vw - 651px)', minWidth: '400px' }}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className={cn(shared.sectionContainer, shared.wide)}>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>{editorModeJs ? 'Query' : 'SQL'}</span>
                    {values.sqlType && values.sqlType === 'chatGptSql' && !chatGptQueryTriggered ? (
                      <Input
                        disabled
                        value=""
                        placeholder="Waiting for request..."
                        handleChange={() => {}}
                        name="queryFake"
                      />
                    ) : (
                      <CodeEditor
                        value={values.query}
                        handleChange={(value) => setFieldValueProxy('query', value)}
                        handleBlurCallback={parseParams}
                        name="query"
                        type={editorModeJs ? 'javascript' : 'sql'}
                        placeholder={sqlPlaceholder(editorModeJs, values.sqlType === 'chatGptSql', gptResponseError)}
                        error={!!errors.query && !values.query}
                        style={{ maxWidth: 'calc(100vw - 651px)', minWidth: '400px' }}
                      />
                    )}
                  </div>
                  {values.sqlType === 'chatGptSql' && !editorModeJs && (
                    <div className={cn(shared.chatGptButtonContainer)}>
                      <div className={shared.btnContainer}>
                        <Button
                          disabled={!((values.gptRequest?.length && values.gptConnectionId)) || gptResponseStatus === loadStatus.LOAD}
                          title={getGptButtonText(gptResponseStatus)}
                          onClick={() => handleGenerateSQL()}
                          loading={gptResponseStatus === loadStatus.LOAD}
                        />
                        {!!errors.query && !values.query && !chatGptQueryTriggered ? (
                          <p
                            className={shared.errorText}
                          >
                            Generate SQL to be able to create API
                          </p>
                        ) : null}
                      </div>
                      <div className={shared.statusContainer}>
                        {gptResponseStatus === loadStatus.SUCCESS && (
                        <>
                          <p className={shared.successText}>Success</p>
                          <IconChecked />
                        </>
                        )}
                        {gptResponseStatus === loadStatus.ERROR && (
                        <>
                          <p className={shared.errorText}>Error</p>
                          <IconAlert />
                        </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </section>
          <hr />
          <section className={shared.section}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={shared.sectionHeader}>Input Parameters</h4>
              <ActionText title="Add parameter" onClick={addParameter} />
            </div>
            {values?.params?.length > 0
              && (
              <div className={cn(shared.sectionContainer, shared.triple)} style={{ marginBottom: '-20px' }}>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Name</span>
                </div>
                <div className={shared.inputWrapper}>
                  <div className={shared.inputLabel}>
                    <ActionText
                      title="Description"
                      onClick={() => setParameterType('desription')}
                      style={parameterType !== 'desription' ? { color: '#93939F' } : {}}
                    />
                    {' / '}
                    <ActionText
                      title="Default value"
                      onClick={() => setParameterType('default')}
                      style={parameterType !== 'default' ? { color: '#93939F' } : {}}
                    />
                  </div>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Type</span>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Required</span>
                </div>
                <div className={cn(shared.inputWrapper, shared.btn)} />
              </div>
              )}
            {values.params
              && (
              <DndProvider backend={HTML5Backend}>
                <ParamentRows
                  params={values.params}
                  errors={errors}
                  parameterType={parameterType}
                  viewRead={false}
                  handleUpdate={setFieldValue}
                  typesList={typesList}
                  updateRequestExample={updateRequestExample}
                  requestExample={requestExample}
                />
              </DndProvider>
              )}
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Limits</h4>
            <div className={cn(shared.sectionContainer)}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Requests per second</span>
                <Input
                  placeholder="RPS up to 100"
                  handleChange={handleChangeProxy}
                  value={values.RPS}
                  name="RPS"
                  error={!!errors.RPS && (!values.RPS || !Number.isInteger(+values.RPS) || values.RPS > 100)}
                  style={{ maxWidth: 140 }}
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={shared.sectionHeader}>Debug console</h4>
              {(values.connectionType !== 'none' && values.connectionType?.length) ? (
                <div style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px',
                }}
                >
                  {renderDebugReqStatus(testDebugStatus)}
                </div>
              ) : null}
            </div>
            <div className={cn(shared.sectionContainer, shared.wide)}>
              <div className={shared.inputWrapper}>
                {shortName && values.endpoint && (
                  <DebugConsole
                    method={values.method}
                    url={`${backendHost}/${shortName}${values.endpoint}`}
                    params={requestExample}
                    onCopy={(text) => {
                      copyText(text) && createToast({ type: 'success', text: 'Copied' });
                    }}
                  />
                )}
              </div>
            </div>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Request example</span>
                <CodeEditor
                  value={requestExample}
                  handleChange={(param) => setRequestExample(param)}
                  type="json"
                  placeholder=" "
                  style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={cn(shared.inputLabel, shared.flexBetween)}>
                  <span>Response example</span>
                  {(values.connectionType === 'none' || !values.connectionType?.length) ? (
                    <span style={{
                      display: 'flex', justifyContent: 'space-between', flexDirection: 'row', whiteSpace: 'nowrap',
                    }}
                    >
                      <span>Status code:</span>
                      <Select
                        type="text"
                        list={[
                          { value: '200', title: '200' },
                          { value: '400', title: '400' },
                          { value: '404', title: '404' },
                          { value: '500', title: '500' },
                          { value: '503', title: '503' },
                        ]}
                        value={responseExampleActiveCode}
                        handleChange={(key, value) => {
                          setResponseExampleActiveCode(value);
                        }}
                        name="responseExampleActiveCode"
                      />
                    </span>
                  ) : null}
                </span>
                <CodeEditor
                  value={responseExample}
                  handleChange={setResponseExample}
                  name="responseExample"
                  type="json"
                  readOnly={!(values.connectionType === 'none' || !values.connectionType?.length)}
                  placeholder=" "
                  style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                  error={(values.connectionType === 'none' || !values.connectionType?.length) && submitTriggered && !validResponseExample(responseExample)}
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelHeading}>
            <h4 className={shared.sectionHeader}>Accesses</h4>
            {/* <ActionText title="Add user or API key" onClick={addUserOfApiKey} /> */}
          </div>
          <div className={shared.sidepanelTable}>
            <div className={shared.sidepanelTableHeading}>
              <div className={shared.lineHeader}>
                <div>Name</div>
                <div>Type</div>
                <div>Role</div>
              </div>
            </div>
            <hr />
            <ul className={shared.sidepanelTableBody}>
              {inviteList && inviteList.map((item) => (
                <li className={shared.historyLine} key={item.userEmail}>
                  <div title={item.userEmail}>
                    {item.userEmail === currentUser.email ? (
                      <span>{`${item.userFirstName} ${item.userLastName}`}</span>
                    ) : (
                      <span>{item.userEmail}</span>
                    )}
                  </div>
                  <div>
                    {item.userEmail === currentUser.email ? (
                      <ChipsStatus title="User" type="user" />
                    ) : (
                      <ChipsStatus title="Invite" type="invite" />
                    )}
                  </div>
                  <div>
                    {item.userEmail === currentUser.email ? (
                      <Select
                        list={[
                          { value: 2, title: roleMapper(2) },
                        ]}
                        style={{ width: 90 }}
                        value={item.roleId}
                        handleChange={(email, value) => handeChangeRole(email, value)}
                        name={item.userEmail}
                        disabled
                      />
                    ) : (
                      <Select
                        list={[
                          { value: 3, title: roleMapper(3) },
                          { value: 4, title: roleMapper(4) },
                        ]}
                        style={{ width: 90 }}
                        value={item.roleId}
                        handleChange={(email, value) => handeChangeRole(email, value)}
                        name={item.userEmail}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  connections, auth: { currentUser }, activeProject, directory: { dataTypes, authorizationMethods },
}) => ({
  activeProject,
  dataTypes,
  connections,
  currentUser,
  authorizationMethods,
});

const mapDispatchToProps = (dispatch) => ({
  createToast: (data) => dispatch(createToastRequest(data)),
  createEndpoint: (data, callback) => dispatch(createEndpointRequest(data, callback)),
  testEndpoint: (data, callback) => dispatch(testEndpointRequest(data, callback)),
  getConnections: (data) => dispatch(listConnectionsRequest(data)),
  gptSendRequest: (data, callback) => dispatch(gptRequestData(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApisCreate);
