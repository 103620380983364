@use "sass:map";
@import '../../styles/variables.scss';

.chip {
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid map.get($colors-green, 100);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    position: relative;
    color: map.get($colors-green, 100);
    position: relative;

    span {
        position: relative;
        bottom: 1px;
        font-size: 10px;
        line-height: 1.15;
    }
}