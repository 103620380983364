import React from 'react';
import cn from 'classnames';
import * as style from './Loader.module.scss';

import LoaderImg from '../../pictures/icon-loading.svg';

function Loader({
  disabled,
  size
}) {
  return (
    <div className={cn(style.loader, {
      [style.disabled]: disabled,
      [style.large]: size === 'large',
    })}
    >
      <LoaderImg />
    </div>
  );
}

export default Loader;
