/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import Button from '../../components/Button/Button';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import Directory from './atoms/Directory/Directory';
import CreateFolderModal from '../../components/CreateFolderModal';
import RenameFolderModal from '../../components/RenameFolderModal';
import RemoveFolderModal from '../../components/RemoveFolderModal';
import Breadscrumbs from '../../components/Breadscrumbs';
import RemoveEndpointModal from '../../components/RemoveEndpointModal';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import ExportModal from '../../components/ExportEndpointModal/ExportEndpointModal';
import Loader from '../../components/Loader';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import DBIcon from '../../components/DBIcon/DBIcon';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  searchEndpointsRequest,
  copyEndpointRequest,
  exportEndpointRequest,
  getEndpointFolderContentRequest,
  moveEndpointFolderRequest,
  moveEndpointRequest,
} from '../../store/endpoints/actions';
import { invitesRequest, addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import {
  getAllData, getAllfoldersData,
  getFoldersDataInFolder, getDataInFolder,
  checker,
} from '../../helpers/utils';
import { backendHost } from '../../helpers/const';
import { useCopyToClipboard } from '../../hooks/hooks';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';
import ApiGraphIcon from '../../pictures/api-graph.svg';
import FolderIcon from '../../pictures/folder.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';

const headers = {
  checkbox: { title: '', width: '7%' },
  // id: { title: 'ID', width: '9%' },
  name: { title: 'Name', width: '20%' },
  method: { title: 'Method', width: '11%' },
  endpoint: { title: 'Endpoint', width: '14%' },
  connection: { title: 'Connection', width: '12%' },
  auth: { title: 'Auth type', width: '12%' },
  status: { title: 'Status', width: '9%' },
  action: { title: 'Actions', width: '11%' },
};

let searchTimeout = null;

function ApisList({
  endpoints,
  getFolder,
  sendInvite,
  createToast,
  activeProject,
  searchEndpoints,
  isRoleAction,
  copyEndpoint,
  exportEndpoint,
  currentUser,
  addAccess,
  moveEndpointFolder,
  moveEndpoint,
}) {
  const [loading, setLoading] = useState(true);
  const { shortName, folderId = 0 } = useParams();
  const [activeModel, setActiveModel] = useState({});

  const [createFolderModalOpened, setCreateFolderModalOpened] = useState(false);
  const [renameFolderModalOpened, setRenameFolderModalOpened] = useState(false);
  const [removeFolderModalOpened, setRemoveFolderModalOpened] = useState(false);

  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inputVisible, setImputVisible] = useState(false);
  const [copiedText, copyText] = useCopyToClipboard();
  const [exportModalOpened, setExportModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [exportItems, setExportItems] = useState([]);
  const { data: endpointsData = [], foldersData = [], breadscrumbData = [] } = endpoints || {};
  const [sortBy, setSortBy] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef(null);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [contextMenuModelActive, setContextMenuModelActive] = useState(false);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  const handleContextMenu = (event, id) => {
    event.preventDefault();

    setContextMenuModelActive(id);

    const { clientX, clientY } = event;

    setContextMenuTop(clientY);
    setContextMenuLeft(clientX);
  };

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);
      setSelectedFolders([]);
      setExportItems([]);

      getFolder({
        folder: folderId || 0,
        projectId: activeProject.id,
      }, () => {
        setLoading(false);
      });
    }
  }, [activeProject?.id, folderId]);

  const copyCon = (path) => {
    copyText(`${backendHost}/${activeProject.shortName}${path}`)
            && createToast({ type: 'success', text: 'Copied' });
  };

  const createFolder = () => setCreateFolderModalOpened(true);

  const createAPI = () => (folderId ? navigate(`/${shortName}/endpoints/folder/${folderId}/create`) : navigate(`/${shortName}/endpoints/create`));
  const importAPI = () => navigate(`/${shortName}/endpoints/import`);
  const modifyAPI = (endpointId) => navigate(`/${shortName}/endpoints/${endpointId}`);
  const openFolder = (f) => navigate(`/${shortName}/endpoints/folder/${f}`);

  const renameFolder = (model) => {
    setContextMenuModelActive(false);
    setActiveModel(model);
    setRenameFolderModalOpened(true);
  };

  const shareFolder = (model) => {
    console.log('share folder');
  };

  const removeFolder = (model) => {
    setContextMenuModelActive(false);
    setActiveModel(model);
    setRemoveFolderModalOpened(true);
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (e.target.value) {
        searchEndpoints({
          query: e.target.value,
          projectId: activeProject.id,
        });
      } else {
        getFolder({
          folder: folderId || 0,
          projectId: activeProject?.id,
        });
      }
    }, 400);
  };

  const copyAPI = (model) => {
    copyEndpoint({
      ...model,
      rootAction: +model.folder === +folderId,
    });
  };

  const clickRemove = (model) => {
    setActiveModel(model);
    setRemoveModalOpened(true);
  };

  const clickRemoveSelected = () => {
    setActiveModel(selectedItems);
    setRemoveModalOpened(true);
  };

  const clickShare = (model) => {
    setActiveModel(model);
    setShareModalOpened(true);
  };

  const clear = () => {
    setSearchValue('');
    getFolder({
      folder: folderId || 0,
      projectId: activeProject?.id,
    });
    setImputVisible(false);
  };

  const sendInviteAction = (data) => {
    // sendInvite(data, () => {
    //   setShareModalOpened(false);
    // });
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setImputVisible(false);
    }

    if (tableRef && tableRef.current && !tableRef.current.contains(event.target)) {
      setContextMenuModelActive(false);
    }
  };

  const exportE = (linkConnections = false) => {
    exportEndpoint({
      projectId: activeProject?.id,
      linkConnections,
      endpoints: exportItems,
    }, (file) => {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'endpoints.zip';
      a.click();
      setExportItems(selectedItems);
    });
  };

  const exportSelected = () => {
    setExportModalOpened(true);
  };

  const exportOne = (item) => {
    setExportModalOpened(true);
    setExportItems([item.id]);
  };

  const selectAll = (isCheked) => {
    if (isCheked) {
      const allIds = getAllData(endpoints);
      const allIdsFolders = getAllfoldersData(endpoints);
      setSelectedItems(allIds);
      setSelectedFolders(allIdsFolders);
      setExportItems(allIds);
    } else {
      setSelectedItems([]);
      setSelectedFolders([]);
      setExportItems([]);
    }
  };

  const selectOne = (isCheked, id) => {
    if (isCheked) {
      setSelectedItems([...selectedItems, id]);
      setExportItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((c) => c !== id));
      setExportItems(selectedItems.filter((c) => c !== id));
    }
  };

  const selectOneFolder = (isCheked, id) => {
    const nestedFolders = getFoldersDataInFolder(endpoints, id) || [];
    const nestedObjs = getDataInFolder(endpoints, id) || [];

    if (isCheked) {
      setSelectedFolders([...selectedFolders, ...[id, ...nestedFolders]]);

      setSelectedItems([...selectedItems, ...nestedObjs]);
      setExportItems([...selectedItems, ...nestedObjs]);
    } else {
      setSelectedFolders(selectedFolders.filter((c) => !nestedFolders.includes(c) && c !== id));
      setSelectedItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
      setExportItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
    }
  };

  const selectItems = (folders, items) => {
    setSelectedFolders([...selectedFolders, ...folders]);
    setSelectedItems([...selectedItems, ...items]);
    setExportItems([...selectedFolders, ...items]);
  };

  const renderHeader = (h, i, arr) => {
    if (h === 'action' && (selectedItems.length > 0 && selectedFolders.length === 0)) {
      return (
        <th key={headers[h].title}>
          {!selectedFolders.length && (
            <ActionIcon
              id="exportSelected"
              icon="export"
              style={{ width: '20px', marginRight: '16px' }}
              hiddenFill
              onClick={() => exportSelected()}
              tooltip={{
                content: 'Export',
              }}
            />
          )}
          <ActionIcon
            id="deleteSelected"
            icon="trash"
            onClick={() => clickRemoveSelected()}
            tooltip={{
              content: 'Delete',
            }}
          />
        </th>
      );
    } if (h !== 'checkbox') {
      return (
        <th key={headers[h].title}>
          <span>{headers[h].title}</span>
          {' '}
          {i < arr.length - 1 && <SortIcon />}
        </th>
      );
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const moveFolder = (f, destination) => {
    setSelectedItems([]);
    moveEndpointFolder({
      ...f,
      projectId: activeProject?.id,
      parentId: destination,
      rootAction: +destination === +folderId,
    });
  };

  const moveObject = (o, destination) => {
    setSelectedItems([]);
    moveEndpoint({
      ...o,
      folder: destination,
      rootAction: +destination === +folderId,
    });
  };

  const openFolderAction = (id) => {
    selectAll(false);
    openFolder(id);
  };

  const handleDropRoot = (e) => {
    const destinationId = folderId || 0;
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'endpoints' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        {!folderId ? (
          <div className={shared.headerTitleGroup}>
            <h1 className={shared.headerTitle}>API List</h1>
            <p className={shared.headerDescription}>Manage API endpoints</p>
          </div>
        ) : <Breadscrumbs folders={breadscrumbData} type="endpoints" />}
        <div className={shared.headerButtonGroup}>
          {inputVisible ? (
            <div ref={ref}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
                style={{ width: '436px' }}
              />
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setImputVisible(true)} />
          )}
          <Button
            id="export_btn"
            type="secondary"
            iconLeft="export"
            onClick={() => (selectedItems.length > 0 && !selectedFolders.length) && exportSelected(true)}
            style={{ width: '36px', padding: '0px', opacity: (selectedItems.length > 0 && !selectedFolders.length) ? '1' : '0.4' }}
            tooltip={{
              content: 'Export',
            }}
          />
          <Button
            id="import_btn"
            type="secondary"
            iconLeft="import"
            onClick={importAPI}
            style={{ width: '36px', padding: '0px' }}
            tooltip={{
              content: 'Import',
            }}
          />
          <Button
            id="folder_btn"
            type="secondary"
            iconLeft="folder"
            onClick={createFolder}
            style={{ width: '36px', padding: '0px' }}
            tooltip={{
              content: 'Create folder',
            }}
          />
          {isRoleAction({ key: 'endpoint', role: 'create' })
            && <Button title="Create API" onClick={createAPI} />}
        </div>
      </div>
      <div className={shared.table}>
        <table
          className={shared.apiTable}
        >
          <thead>
            <tr className={shared.connectionTableRowEndpoints}>
              <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={
                    (endpointsData?.length || foldersData?.length)
                    && checker(selectedItems, endpointsData)
                    && checker(selectedFolders, foldersData)
                  }
                  style={{ marginRight: '0' }}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {loading && (
            <div className={shared.emptyContent}>
              <Loader size="large" />
            </div>
          )}
          {(foldersData?.length || endpointsData?.length) && !loading ? (
            <tbody
              ref={tableRef}
              onDrop={(e) => handleDropRoot(e)}
              onDragOver={(e) => handleDragOver(e)}
              onClick={() => setContextMenuModelActive(false)}
            >
              <Directory
                foldersData={foldersData}
                endpointsData={endpointsData}
                selectedFolders={selectedFolders}
                selectedItems={selectedItems}
                handleContextMenu={handleContextMenu}
                setContextMenuModelActive={setContextMenuModelActive}
                openFolderAction={openFolderAction}
                selectOneFolder={selectOneFolder}
                selectItems={selectItems}
                contextMenuModelActive={contextMenuModelActive}
                contextMenuTop={contextMenuTop}
                contextMenuLeft={contextMenuLeft}
                modifyEndpoint={modifyAPI}
                copyEndpoint={copyAPI}
                selectOne={selectOne}
                renameFolder={renameFolder}
                clickShare={clickShare}
                removeFolder={removeFolder}
                isRoleAction={isRoleAction}
                exportOne={exportOne}
                clickRemove={clickRemove}
                getFolder={getFolder}
                moveFolder={moveFolder}
                moveObject={moveObject}
                copyCon={copyCon}
                activeProject={activeProject}
              />
            </tbody>
          ) : null}
        </table>
        {(!endpointsData?.length && !foldersData?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No APIs found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {(!endpointsData?.length && !foldersData?.length) && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>This project has no APIs yet</h3>
            <p>
              The available APIs for this project will be displayed here.
            </p>
            <div className={shared.action}>
              <ActionText
                title="Click here to create API"
                onClick={createAPI}
              />
            </div>
          </div>
        )}
        {createFolderModalOpened && (
          <CreateFolderModal
            setIsOpen={(status) => {
              setCreateFolderModalOpened(status);
            }}
            parent={Number(folderId)}
            activeProject={activeProject}
            type="endpoints"
          />
        )}
        {renameFolderModalOpened && (
          <RenameFolderModal
            setIsOpen={(status) => {
              setRenameFolderModalOpened(status);
            }}
            model={activeModel}
            activeProject={activeProject}
            type="endpoints"
          />
        )}
        {removeFolderModalOpened && (
          <RemoveFolderModal
            setIsOpen={(status) => {
              setRemoveFolderModalOpened(status);
            }}
            model={activeModel}
            activeProject={activeProject}
            type="endpoints"
          />
        )}
        {removeModalOpened && (
          <RemoveEndpointModal
            setIsOpen={(status, clearSelected) => {
              setRemoveModalOpened(status);
              clearSelected && setSelectedItems([]);
            }}
            endpoint={activeModel}
            endpoints={endpointsData}
          />
        )}
        {shareModalOpened && (
          <InviteToResourceModal
            id={activeModel.id}
            onClose={() => setShareModalOpened(false)}
            onSubmit={sendInviteAction}
            model={activeModel}
            type={activeModel?.type === 'endpoints' ? 'folder' : 'endpoint'}
            inviteList={[]}
          />
        )}
        {exportModalOpened && (
          <ExportModal
            onClose={() => {
              setExportModalOpened(false);
              setExportItems(selectedItems);
            }}
            onSubmit={exportE}
            list={exportItems}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  endpoints, activeProject, auth: { currentUser }, roles,
}) => ({
  endpoints,
  roles,
  currentUser,
  activeProject,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (data, callback) => dispatch(getEndpointFolderContentRequest(data, callback)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  copyEndpoint: (data, callback) => dispatch(copyEndpointRequest(data, callback)),
  exportEndpoint: (data, callback) => dispatch(exportEndpointRequest(data, callback)),
  searchEndpoints: (data) => dispatch(searchEndpointsRequest(data)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data)),
  moveEndpointFolder: (data, callback) => dispatch(moveEndpointFolderRequest(data, callback)),
  moveEndpoint: (data, callback) => dispatch(moveEndpointRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ApisList));
