/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import ActionText from '../ActionText/ActionText';
import Arrow from '../../pictures/icon-arrow-enter.svg';

import * as styles from './Breadscrumbs.module.scss';

function Breadscrumbs({
  folders = [],
  type = 'endpoints',
}) {
  const { shortName } = useParams();
  const navigate = useNavigate();

  const goToFolder = (id) => {
    if (!id && type === 'endpoints') navigate(`/${shortName}/endpoints`);
    if (id && type === 'endpoints') navigate(`/${shortName}/endpoints/folder/${id}`);
    if (!id && type === 'connections') navigate(`/${shortName}/connections`);
    if (id && type === 'connections') navigate(`/${shortName}/connections/folder/${id}`);
  };

  return (
    <div className={styles.breaadscrumbContainer}>
      <div className={styles.breadscrumbItem}>
        <ActionText style={{ fontWeight: 400 }} title={type === 'endpoints' ? 'API List' : 'Connection List'} onClick={() => goToFolder()} type="black" />
      </div>
      {folders.slice(0).reverse().map((f, i, arr) => (
        <div className={styles.breadscrumbItem} key={f.id}>
          <ActionText key={f.id} style={{ fontWeight: 400 }} title={f.name} onClick={() => goToFolder(f.id)} type={i === arr.length - 1 ? 'default' : 'black'} />
          <div className={styles.arrow}>
            <Arrow />
          </div>
        </div>
      ))}
    </div>
  );
}

Breadscrumbs.propTypes = {
  folders: PropTypes.array,
  type: PropTypes.string,
};

export default Breadscrumbs;
