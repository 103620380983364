import { Directory } from './constants';

const initialState = {
  connectionTypes: [],
  roles: [],
  dataTypes: []
};

export const directoryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Directory.listDirectorySuccess:
      return action.data;
    default:
      return state;
  }
};
