/* eslint-disable react/forbid-prop-types */
import React from 'react';
import cn from 'classnames';
import ActionIcon from '../ActionIcon';

import * as styles from './DebugConsole.module.scss';

function DebugConsole({
  method,
  url,
  onCopy,
}) {
  const copyText = () => {
    onCopy(url);
  };

  return (
    <div
      className={cn(styles.debugConsole)}
    >
      <div className={styles.vLine} />
      <div className={styles.method}><span>{method}</span></div>
      <div className={styles.url}>
        <span>{url}</span>
      </div>
      <div className={styles.action}>
        <ActionIcon
          icon="copy"
          onClick={() => copyText()}
        />
      </div>
    </div>
  );
}

export default DebugConsole;
