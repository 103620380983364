import { Users } from './constants';

const initialState = null;

export const usersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Users.listUsersSuccess:
      return action.data;
    case Users.searchUsersSuccess:
      return action.data;
    case Users.removeUserSuccess:
      return state.filter(u => u.id !== action.data.userId);
    default:
      return state;
  }
};
