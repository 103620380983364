import {
  put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import {
  activeProjectSuccess, activeProjectError
} from './actions';
import { createToastRequest } from '../toasts/actions';
import { ActiveProject } from './constants';

export function* setActiveProject(ops) {
  const { projects, shortName } = ops.data;

  try {
    const activeProject = projects.find((el) => el.shortName === shortName);
    if (activeProject) {
      yield put(activeProjectSuccess(activeProject));
    } else {
      yield put(activeProjectError());
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeProjectSaga() {
  yield takeEvery(ActiveProject.activeProjectRequest, setActiveProject);
}

function* configSaga() {
  yield all([fork(activeProjectSaga)]);
}

export default configSaga;
