const createNewVersion = {
  headerText: 'Create new version',
  mainText: (versionNumber) => `Please confirm that you want to create version: ${versionNumber}`,
  mainTextSub: null,
  closeBtnText: 'Cancel',
  saveBtnText: 'Create Version',
};

const versionActivation = {
  headerText: 'Version activation',
  mainText: (versionNumber) => `Please confirm that you want to activate version: ${versionNumber}`,
  mainTextSub: 'After activation, all incoming requests will be handled by this version.',
  closeBtnText: 'Cancel',
  saveBtnText: (versionNumber) => `Activate version ${versionNumber}`,
};

export const ConfigsEnum = {
  CREATE: 'create',
  ACTIVATION: 'activation',
};

export const WarningsConfigsMap = {
  [ConfigsEnum.CREATE]: createNewVersion,
  [ConfigsEnum.ACTIVATION]: versionActivation,
};
