import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createProjectRequest } from '../../store/projects/actions';
import { Button } from '../../components/Button';
import * as styles from './NewProjectWelcome.module.scss';

const validationRegex = /^(?! +$)[a-zA-Z0-9 _-]+$/;

const CreateProjectSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project Name is required.')
    .matches(
      validationRegex,
      'Project Name is required.',
    ),
});

function NewProjectWelcome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    submitForm, values, setFieldValue, isValid,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: CreateProjectSchema,
    onSubmit: (v) => {
      dispatch(createProjectRequest(v, (shortName) => {
        navigate(`/${shortName}/endpoints`);
      }));
    },
  });

  const handleChangeText = (e) => {
    if (validationRegex.test(e.target.value) || e.target.value === '') {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          Let&apos;s start with a name for your project
        </h1>
        <input
          name="name"
          type="text"
          placeholder="Enter your project name"
          onChange={handleChangeText}
          value={values.name}
        />
        <div className={styles.buttonWrapper}>
          <Button
            disabled={!isValid}
            style={{ width: '216px', height: '48px', borderRadius: '6px' }}
            title="Create"
            onClick={submitForm}
          />
        </div>
      </div>
      <div className={styles.imageContainer} />
    </div>
  );
}

export default NewProjectWelcome;
