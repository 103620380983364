import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api, apiWorker, apiWorkerFile } from '../../helpers/api';
import {
  listEndpointsRequest, listEndpointsSuccess,
  searchEndpointsRequest, searchEndpointsSuccess,
  createEndpointRequest, createEndpointSuccess,
  removeEndpointRequest, removeEndpointSuccess,
  removeEndpointsListRequest, removeEndpointsListSuccess,
  updateEndpointRequest, updateEndpointSuccess,
  testEndpointRequest, importEndpointRequest,
  exportEndpointRequest, gptRequest,
  updateEndpointFolderRequest, updateEndpointFolderSuccess,
  removeEndpointFolderRequest, removeEndpointFolderSuccess,
  createEndpointFolderRequest, createEndpointFolderSuccess,
  getEndpointFolderContentRequest, getEndpointFolderContentSuccess,
  moveEndpointFolderRequest, moveEndpointFolderSuccess,
  moveEndpointRequest, moveEndpointSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { Endpoints } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchEndpoints(ops) {
  const { data } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listEndpointsSuccess(parsed.items));
      } else if (response.status === 401) {
        yield put(refreshRequest(listEndpointsRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchEndpoints(ops) {
  const { data } = ops;
  const { projectId, query } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints/search?projectId=${projectId}&query=${query}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchEndpointsSuccess(parsed.items));
      } else if (response.status === 401) {
        yield put(refreshRequest(searchEndpointsRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createEndpoint(ops) {
  const { data, callback, options } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'POST', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        parsed.role = 'owner';
        parsed.connectionName = data.connectionName;

        yield put(createEndpointSuccess({ ...parsed, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'endpoint has been created successfully',
          linkTitle: `/endpoints/${parsed.id}`,
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createEndpointRequest, data, callback, options));
      } else {
        const parsed = yield response.json();
        if (options?.type === 'copy' && options?.step < 5) {
          yield put(createEndpointRequest(
            {
              ...data,
              name: `${data.name}(copy)`,
              path: `${data.path}-copy`,
            },
            () => {
              if (callback) callback();
            },
            {
              step: options.step + 1,
              type: 'copy',
            },
          ));
        } else {
          yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        }
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints/${data.id}`, 'DELETE', null, authData.access_token);
      if (response.ok) {
        yield put(removeEndpointSuccess({ id: data.id }));
        yield put(createToastRequest({ type: 'success', title: data.name, text: 'endpoint was removed' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeEndpointsList(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'DELETE', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        let successList = data.items;
        if (parsed.errors && parsed.errors.length > 0) {
          parsed.errors.forEach((e) => {
            successList = successList.filter((id) => id !== e.id);
          });
        }
        yield put(removeEndpointsListSuccess({ ids: successList }));
        if (callback) callback({ errors: parsed.errors, successList });
      } else if (response.status === 401) {
        yield put(refreshRequest(removeEndpointsListRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'PUT', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'endpoint was updated',
          linkTitle: `/endpoints/${data.id}`,
        }));
        yield put(updateEndpointSuccess(parsed));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateEndpointRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* copyEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      // get access
      const access = yield call(api, `/access/users?resource=endpoint&id=${data.id}`, 'GET', null, authData.access_token);
      if (access.ok) {
        const parsedAccess = yield access.json();
        // create endpoint
        yield put(createEndpointRequest(
          {
            ...data,
            name: `${data.name}(copy)`,
            path: `${data.path}-copy`,
          },
          () => {
            callback && callback();
          },
          {
            step: 1,
            type: 'copy',
          },
        ));
      } else if (response.status === 401) {
        yield put(refreshRequest(createEndpointRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* exportEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorker, '/export/endpoint', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsedAccess = yield response.json();
        if (parsedAccess.file) {
          yield put(createToastRequest({ type: 'success', text: 'Export in progress... Don\'t refresh this page.' }));
          const file = yield call(apiWorker, `/export/file/${parsedAccess.file}`, 'GET', null, authData.access_token);
          file.blob().then((blob) => {
            if (callback) callback(blob);
          });
        } else {
          yield put(createToastRequest({ type: 'error', text: 'Error export on first step' }));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(exportEndpointRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* importEndpoint(ops) {
  const { data, callback } = ops;
  const {
    projectId, linkConnections, endpoints, file,
  } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(apiWorkerFile, `/import/file?projectId=${projectId}&linkConnections=${linkConnections}&endpoints=${endpoints}`, authData.access_token, file);

      const parsed = JSON.parse(response);
      if (parsed.status === 'success') {
        yield put(createToastRequest({ type: 'success', text: 'Endpoints have been added to the list' }));
        if (callback) callback(parsed);
      } else if (parsed.status === 401) {
        yield put(refreshRequest(importEndpointRequest, data));
      } else {
        yield put(createToastRequest({ type: 'error', text: parsed.msg }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* testEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints/test', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        if (callback) callback('SUCCESS', parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(testEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        if (callback) callback('ERROR', parsed?.error);
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* gptSuggestion(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/chat', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        if (callback) callback('SUCCESS', parsed.response);
      } else if (response.status === 401) {
        yield put(refreshRequest(gptRequest, data, callback));
      } else {
        const parsed = yield response.json();
        if (callback) callback('ERROR', parsed?.error);
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'POST', data, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();

        yield put(createEndpointFolderSuccess(parsed));

        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'folder has been created successfully',
        }));
        if (callback) callback(parsed);
      } else if (response.status === 401) {
        yield put(refreshRequest(createEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getEndpointFolder(ops) {
  const { data, callback } = ops;
  const { merge } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const [folders, endpoints] = yield all([
        call(api, `/folders?folder=${data.folder}&type=endpoints&projectId=${data.projectId}`, 'GET', null, authData.access_token),
        call(api, `/endpoints?folder=${data.folder}&projectId=${data.projectId}`, 'GET', null, authData.access_token),
      ]);
      if (folders.ok && endpoints.ok) {
        const parsedFolders = yield folders.json();
        const parsedEndpoints = yield endpoints.json();

        yield put(getEndpointFolderContentSuccess({
          merge,
          folderId: data.folder,
          folders: parsedFolders.next,
          data: parsedEndpoints.items,
          breadscrumbs: parsedFolders.prev,
        }));

        if (callback) {
          callback({
            merge,
            folderId: data.folder,
            folders: parsedFolders.next,
            data: parsedEndpoints.items,
            breadscrumbs: parsedFolders.prev,
          });
        }
      } else if (folders.status === 401 || endpoints.status === 401) {
        yield put(refreshRequest(getEndpointFolderContentRequest, data, callback));
      } else {
        const parsedFolders = yield folders.json();
        const parsedEndpoints = yield endpoints.json();
        yield put(createToastRequest({
          type: 'error',
          text: parsedFolders?.error || parsedEndpoints?.error,
          code: parsedFolders?.code || parsedEndpoints?.error,
        }));
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* updateEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was updated',
        }));
        yield put(updateEndpointFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(updateEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'DELETE', { type: data.type, folder: data.id }, authData.access_token);
      if (response.ok) {
        yield put(removeEndpointFolderSuccess({ id: data.id }));
        yield put(createToastRequest({ type: 'success', title: data.name, text: 'Folder was removed' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveEndpointFolder(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/folders', 'PUT', {
        id: data.id,
        projectId: data.projectId,
        name: data.name,
        type: data.type,
        parentId: data.parentId,
      }, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({
          type: 'success',
          title: data.name,
          text: 'folder was moved',
        }));
        yield put(moveEndpointFolderSuccess(data));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveEndpointFolderRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* moveEndpoint(ops) {
  const { data, callback } = ops;
  const { rootAction, ...otherData } = data || {};
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/endpoints', 'PUT', otherData, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(moveEndpointSuccess({ ...parsed, rootAction }));
        yield put(createToastRequest({
          type: 'success',
          title: parsed.name,
          text: 'endpoint was moved',
        }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(moveEndpointRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* endpointsSaga() {
  yield takeEvery(Endpoints.listEndpointsRequest, fetchEndpoints);
  yield takeEvery(Endpoints.searchEndpointsRequest, searchEndpoints);
  yield takeEvery(Endpoints.createEndpointRequest, createEndpoint);
  yield takeEvery(Endpoints.removeEndpointRequest, removeEndpoint);
  yield takeEvery(Endpoints.removeEndpointsListRequest, removeEndpointsList);
  yield takeEvery(Endpoints.updateEndpointRequest, updateEndpoint);
  yield takeEvery(Endpoints.copyEndpointRequest, copyEndpoint);
  yield takeEvery(Endpoints.exportEndpointRequest, exportEndpoint);
  yield takeEvery(Endpoints.importEndpointRequest, importEndpoint);
  yield takeEvery(Endpoints.testEndpointRequest, testEndpoint);
  yield takeEvery(Endpoints.gptRequest, gptSuggestion);
  yield takeEvery(Endpoints.createEndpointFolderRequest, createEndpointFolder);
  yield takeEvery(Endpoints.removeEndpointFolderRequest, removeEndpointFolder);
  yield takeEvery(Endpoints.updateEndpointFolderRequest, updateEndpointFolder);
  yield takeEvery(Endpoints.getEndpointFolderContentRequest, getEndpointFolder);
  yield takeEvery(Endpoints.moveEndpointFolderRequest, moveEndpointFolder);
  yield takeEvery(Endpoints.moveEndpointRequest, moveEndpoint);
}

function* configSaga() {
  yield all([fork(endpointsSaga)]);
}

export default configSaga;
