/* eslint-disable max-len */
import { Invites } from './constants';

export const invitesRequest = (data, callback) => ({ type: Invites.invitesRequest, data, callback });
export const invitesSuccess = () => ({ type: Invites.invitesSuccess });
export const invitesError = () => ({ type: Invites.invitesError });

export const addAccessRequest = (data, callback) => ({ type: Invites.addAccessRequest, data, callback });
export const addAccessSuccess = () => ({ type: Invites.addAccessSuccess });
export const addAccessError = () => ({ type: Invites.addAccessError });

export const deleteAccessRequest = (data, callback) => ({ type: Invites.deleteAccessRequest, data, callback });
export const deleteAccessSuccess = () => ({ type: Invites.deleteAccessSuccess });
export const deleteAccessError = () => ({ type: Invites.deleteAccessError });
