export const isRoleAction = (Component) => (props) => {
    const isRoleAction = ({ key = 'project', role, id, modelRole }) => {
        const { roles, currentUser } = props;
        const curenUserRole = modelRole || currentUser.role;
        if (!curenUserRole) {
            return false
        };

        const rights = roles.find(r => r.name === curenUserRole)?.rights[key] || [];
        if (currentUser.admin) {
            return true;
        }

        if (id && currentUser.id === id) {
            return true;
        }

        if (rights.includes(role)) {
            return true;
        }

        return false
    }

    return <Component {...props} isRoleAction={isRoleAction} />;
};
