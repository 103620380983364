/* eslint-disable import/prefer-default-export */
export const Endpoints = {
  listEndpointsRequest: 'LIST_ENDPOINTS_PENDING',
  listEndpointsSuccess: 'LIST_ENDPOINTS_SUCCESS',
  listEndpointsError: 'LIST_ENDPOINTS_ERROR',

  searchEndpointsRequest: 'SEARCH_ENDPOINTS_PENDING',
  searchEndpointsSuccess: 'SEARCH_ENDPOINTS_SUCCESS',
  searchEndpointsError: 'SEARCH_ENDPOINTS_ERROR',

  createEndpointRequest: 'CREATE_ENDPOINT_PENDING',
  createEndpointSuccess: 'CREATE_ENDPOINT_SUCCESS',
  createEndpointError: 'CREATE_ENDPOINT_ERROR',

  removeEndpointRequest: 'REMOVE_ENDPOINT_PENDING',
  removeEndpointSuccess: 'REMOVE_ENDPOINT_SUCCESS',
  removeEndpointError: 'REMOVE_ENDPOINT_ERROR',

  removeEndpointsListRequest: 'REMOVE_ENDPOINTS_LIST_PENDING',
  removeEndpointsListSuccess: 'REMOVE_ENDPOINTS_LIST_SUCCESS',
  removeEndpointsListError: 'REMOVE_ENDPOINTS_LIST_ERROR',

  updateEndpointRequest: 'UPDATE_ENDPOINT_PENDING',
  updateEndpointSuccess: 'UPDATE_ENDPOINT_SUCCESS',
  updateEndpointError: 'UPDATE_ENDPOINT_ERROR',

  copyEndpointRequest: 'COPY_ENDPOINT_PENDING',
  copyEndpointSuccess: 'COPY_ENDPOINT_SUCCESS',
  copyEndpointError: 'COPY_ENDPOINT_ERROR',

  exportEndpointRequest: 'EXPORT_ENDPOINT_PENDING',
  exportEndpointSuccess: 'EXPORT_ENDPOINT_SUCCESS',
  exportEndpointError: 'EXPORT_ENDPOINT_ERROR',

  importEndpointRequest: 'IMPORT_ENDPOINT_PENDING',
  importEndpointSuccess: 'IMPORT_ENDPOINT_SUCCESS',
  importEndpointError: 'IMPORT_ENDPOINT_ERROR',

  testEndpointRequest: 'TEST_ENDPOINT_PENDING',
  testEndpointSuccess: 'TEST_ENDPOINT_SUCCESS',
  testEndpointError: 'TEST_ENDPOINT_ERROR',

  gptRequest: 'GPT_PENDING',
  gptSuccess: 'GPT_SUCCESS',
  gptError: 'GPT_ERROR',

  getEndpointFolderContentRequest: 'GET_ENDPOINT_FOLDER_CONTENT_REQUEST',
  getEndpointFolderContentSuccess: 'GET_ENDPOINT_FOLDER_CONTENT_SUCCESS',

  createEndpointFolderRequest: 'CREATE_ENDPOINT_FOLDER_REQUEST',
  createEndpointFolderSuccess: 'CREATE_ENDPOINT_FOLDER_SUCCESS',

  updateEndpointFolderRequest: 'UPDATE__ENDPOINT_FOLDER_REQUEST',
  updateEndpointFolderSuccess: 'UPDATE_ENDPOINT_FOLDER_SUCCESS',

  removeEndpointFolderRequest: 'REMOVE__ENDPOINT_FOLDER_REQUEST',
  removeEndpointFolderSuccess: 'REMOVE_ENDPOINT_FOLDER_SUCCESS',

  moveEndpointFolderRequest: 'MOVE__ENDPOINT_FOLDER_REQUEST',
  moveEndpointFolderSuccess: 'MOVE_ENDPOINT_FOLDER_SUCCESS',

  moveEndpointRequest: 'MOVE__ENDPOINT_REQUEST',
  moveEndpointSuccess: 'MOVE_ENDPOINT_SUCCESS',
};
