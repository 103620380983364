import {
  useState, useEffect, useRef, useCallback,
} from 'react';

export function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

export function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null);

  const copy = async (text) => {
    if (!navigator?.clipboard?.writeText) {
      console.warn('Legacy clipboard action!');

      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      try {
        if (document.execCommand('copy')) {
          console.log('Success legacy copy action!');
          setCopiedText(text);
          document.body.removeChild(el);
          return true;
        }
        console.warn('Exec copy failed');
        document.body.removeChild(el);
        return false;
      } catch (err) {
        console.warn('Copy failed', err);
        document.body.removeChild(el);
        return false;
      }
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export function useOutsideClick(handler) {
  const ref = useRef(null);

  const listener = useCallback((event) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      handler(event);
    }
  }, [ref, handler]);

  useEffect(
    () => {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [listener],
  );

  return ref;
}
