@use "sass:map";
@import '../../styles/variables.scss';

.inputWrapper {
    position: relative;
}

.iconRight {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        svg * {
            fill: map.get($colors-basic, "primaryBlue");
        }
    }
}


// for clear autofill useragent styles
input {
    outline: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px map.get($colors-basic, "inputGrey") !important;
}

.input {
    width: 100%;
    box-sizing: border-box;
    color: map.get($colors-basic, "textBlack");
    border: none;
    outline: none;
    font-size: map.get($font-sizes, "body");
    background-color: map.get($colors-basic, "inputGrey");
    height: 36px;
    padding-right: 32px;
    padding-left: 12px;
    border: 1px solid map.get($colors-basic, "inputGrey");
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;


    &.filled {
        &:hover {
            border: 1px solid map.get($colors-basic, "inputGrey");
        }

        &:focus {
            background-color: white;
        }
    }

    &::placeholder {
        color: map.get($colors-basic, "textGray")
    }

    &:hover {
        background-color: map.get($colors-basic, "selectedOption");
    }

    &:focus {
        background-color: white;
    }

    &.error {
        border: 1px solid map.get($colors-red, 100);
        background-color: #FEF4F4;
    }

    svg * {
        fill: white;
    }
}