/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { connect } from 'react-redux';
import { deleteAccessRequest } from '../../store/invites/actions';
import Button from '../Button/Button';
import * as styles from './RemoveAccessModal.module.scss';

function RemoveAccessModal({ deleteAccess, user, resource, id, setIsOpen, deleteAccessUser }) {
  const handleRemove = () => {
    deleteAccess({
      id: +id,
      resource,
      userId: user.id,
    }, () => {
      setIsOpen(false);
      deleteAccessUser && deleteAccessUser(user);
    })
  };


  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove access</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove access for
              {' '}
              <span className={styles.modelTitle}>
                {`${user.firstName} ${user.lastName}`}
              </span>
              {' '}
              to this {resource}?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '144px', marginLeft: '12px' }} type="red" title="Remove access" onClick={handleRemove} />
          </div>
        </div>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => ({
  deleteAccess: (data, callback) => dispatch(deleteAccessRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(RemoveAccessModal);
