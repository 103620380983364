/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { removeRoleRequest } from '../../store/roles/actions';
import Button from '../Button/Button';
import Select from '../Select/Select';
import * as styles from './RemoveRoleModal.module.scss';
import { listRolesName } from '../../helpers/const';

function RemoveRoleModal({ removeRole, role, roles, setIsOpen, deleteRole }) {
  const [selectRole, setSelectRole] = useState('');
  const handleRemove = () => {
    removeRole({
      replaceId: selectRole,
      roleId: role.id,
      name: role.name
    }, (res) => {
      setIsOpen(false);
    });
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove Role</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove
              <span className={styles.modelTitle}>
                {` ${listRolesName[role.name] || role.name} `}
              </span>
              role?
            </div>
            <div className={styles.modalSelect}>
              You need to select a role that will replace the deleted one
              <div className={styles.selectRole}>
                <Select
                  list={roles.filter(r => r.id !== role.id && r.id !== 1).map(r => ({value: r.id, title: listRolesName[r.name] || r.name }))}
                  value={selectRole}
                  handleChange={(key, value) => setSelectRole(value)}
                  name="role"
                  error={!selectRole}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button
              style={{ width: '120px', marginLeft: '12px' }}
              type="red"
              title="Remove"
              onClick={handleRemove}
              disabled={!selectRole}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  removeRole: (data, callback) => dispatch(removeRoleRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(RemoveRoleModal);
