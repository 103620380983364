/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './NewFeatureLabel.module.scss';

function NewFeatureLabel({
  title = 'new',
  style = {},
}) {
  return (
    <div
      className={cn(
        styles.chip,
      )}
      style={style}
    >
      <span>{title}</span>
    </div>
  );
}

NewFeatureLabel.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

export default NewFeatureLabel;
