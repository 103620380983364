import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import * as style from './Navigation.module.scss';
import { connect } from 'react-redux';

import ConnectionsImg from '../../pictures/connection.svg';
import GraphImg from '../../pictures/graph.svg';
import UsersImg from '../../pictures/users.svg';
import RolesImg from '../../pictures/roles.svg';
import SettingsImg from '../../pictures/settings.svg';

function Navigation({ currentUser }) {
  const navItems = [
    {
      key: 'endpoints',
      title: 'API',
      route: '',
      icon: GraphImg,
      active: true,
    },
    {
      key: 'connections',
      title: 'Connections',
      route: '',
      icon: ConnectionsImg,
      active: true,
    },
    {
      key: 'users',
      title: 'Users',
      route: '',
      icon: UsersImg,
      active: true,
    },
    {
      key: 'roles',
      title: 'Roles',
      route: '',
      icon: RolesImg,
      active: currentUser.admin
    },
    {
      key: 'settings',
      title: 'Settings',
      route: '',
      icon: SettingsImg,
      active: false,
    },
  ];

  const { shortName } = useParams();
  const activeNavItems = navItems.filter((n) => n.active);

  return (
    <nav className={style.navigation}>
      <ul>
        {activeNavItems.map(({
          key, title, icon: Icon,
        }) => (
          <li key={key}>
            <NavLink
              to={`${shortName}/${key}`}
              className={({ isActive }) => `${style.navItem} ${style[key]}${isActive ? ` ${style.active}` : ''}`}
            >
              <div className={style.icon}>
                <Icon />
              </div>
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}


const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

export default connect(mapStateToProps)(Navigation);
