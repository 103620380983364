/* eslint-disable import/prefer-default-export */
export const Projects = {
  listProjectsRequest: 'LIST_PROJECTS_PENDING',
  listProjectsSuccess: 'LIST_PROJECTS_SUCCESS',
  listProjectsError: 'LIST_PROJECTS_ERROR',

  createProjectRequest: 'CREATE_PROJECT_PENDING',
  createProjectSuccess: 'CREATE_PROJECT_SUCCESS',
  createProjectError: 'CREATE_PROJECT_ERROR',

  removeProjectRequest: 'REMOVE_PROJECT_PENDING',
  removeProjectSuccess: 'REMOVE_PROJECT_SUCCESS',
  removeProjectError: 'REMOVE_PROJECT_ERROR',
};
