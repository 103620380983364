@use "sass:map";
@import '../../styles/variables.scss';

.sidepanel {
    min-height: 100%;
    color: map.get($colors-basic, "textBlack");
    background-color: map.get($colors-basic, "sideMenu");
    border-right: 1px solid map.get($colors-basic, "divider");  
    width: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}