/* eslint-disable import/prefer-default-export */
export const ActiveEndpoint = {
  activeEndpointRequest: 'ACTIVE_ENDPOINT_PENDING',
  activeEndpointSuccess: 'ACTIVE_ENDPOINT_SUCCESS',
  activeEndpointError: 'ACTIVE_ENDPOINT_ERROR',

  getAccessUsersEndpointSuccess: 'GET_ACCESS_USERS_ENDPOINT_SUCCESS',
  activeDisplayVersion: 'ACTIVE_DISPLAY_VERSION',
  activateOriginVersion: 'ACTIVATE_ORIGIN_VERSION',
};
