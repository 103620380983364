import React from 'react';
import styles from './WarningModal.module.scss';
import Button from '../Button/Button';

function WarningModal({
  onCancel,
  onSave,
  headerText,
  mainText,
  mainTextSub,
  saveBtnText,
  closeBtnText,
}) {
  return (
    <>
      <div className={styles.header}>
        <span className={styles.title}>{headerText}</span>
      </div>
      <div className={styles.body}>
        <div className={styles.mainContent}>
          <span>{mainText}</span>
          {mainTextSub && <span>{mainTextSub}</span>}
        </div>
        <div className={styles.buttonsGroup}>
          <Button style={{ width: 85 }} title={closeBtnText} type="secondary" onClick={onCancel} />
          <Button
            style={{ width: 'max-content' }}
            title={saveBtnText}
            onClick={onSave}
          />
        </div>
      </div>
    </>
  );
}

export default WarningModal;
