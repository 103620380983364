import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  activeUserRequest, activeUserSuccess,
  userAccessRequest, userAccessSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { ActiveUser } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* getActiveUser(ops) {
  const authData = yield select(getAuth);
  const { id, projectId } = ops.data;
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users/${id}?projectId=${projectId}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeUserSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(activeUserRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getUserAccess(ops) {
  const authData = yield select(getAuth);
  const { id, projectId } = ops.data;
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users/access/${id}?projectId=${projectId}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(userAccessSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(userAccessRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeUserSaga() {
  yield takeEvery(ActiveUser.activeUserRequest, getActiveUser);
  yield takeEvery(ActiveUser.userAccessRequest, getUserAccess);
}

function* configSaga() {
  yield all([fork(activeUserSaga)]);
}

export default configSaga;
