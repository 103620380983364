import { Auth } from './constants';

const initialState = {
  auth: null,
  currentUser: null,
};

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Auth.authSuccess:
      return { ...state, auth: action.data };
    case Auth.refreshSuccess:
      return { ...state, auth: action.data };
    case Auth.meSuccess:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.data
        }
      };
    case Auth.meInProjectSuccess:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.data,
        }
      };
    case Auth.logoutSuccess:
      return initialState;
    case Auth.logoutError:
      return initialState;
    default:
      return state;
  }
};
