import React from 'react';
import ProjectSelection from '../../components/ProjectSelection/ProjectSelection';
import Navigation from '../../components/Navigation/Navigation';
import UserSelection from '../../components/UserSelection/UserSelection';
import * as style from './Sidepanel.module.scss';

function Sidepanel() {
  return (
    <div className={style.sidepanel}>
      <div>
        <ProjectSelection />
        <Navigation />
      </div>
      <UserSelection />
    </div>
  );
}

export default Sidepanel;
