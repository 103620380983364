export const Endpoints = {
  historyRequest: 'HISTORY_ENDPOINT_PENDING',
  historySuccess: 'HISTORY_ENDPOINT_SUCCESS',
  historyError: 'HISTORY_ENDPOINT_ERROR',

  userRequest: 'HISTORY_USER_PENDING',
  userSuccess: 'HISTORY_USER_SUCCESS',
  userError: 'HISTORY_USER_ERROR',

  createVersionRequest: 'HISTORY_CREATE_VERSION_PENDING',
  createVersionSuccess: 'HISTORY_CREATE_VERSION_SUCCESS',
  createVersionError: 'HISTORY_CREATE_VERSION_ERROR',

  overrideVersionRequest: 'HISTORY_OVERRIDE_VERSION_PENDING',
  overrideVersionSuccess: 'HISTORY_OVERRIDE_VERSION_SUCCESS',
  overrideVersionError: 'HISTORY_OVERRIDE_VERSION_ERROR',

  diffVersionRequest: 'DIFF_VERSION_PENDING',
  diffVersionSuccess: 'DIFF_VERSION_SUCCESS',
  diffVersionError: 'DIFF_VERSION_ERROR',

  clearHistoryData: 'HISTORY_CLEAR_DATA',
};

export const latestVersionId = 1000000000000000;
