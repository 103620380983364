/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { useCopyToClipboard } from '../../hooks/hooks';
import Button from '../../components/Button/Button';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import { roleMapper, getProjectRoles } from '../../helpers/utils';
import {
  loadStatus, typesAPI, listRoles, canSelectRole, listRolesName,
} from '../../helpers/const';
import { isRoleAction } from '../../helpers/hocs';
import { updateConnectionRequest } from '../../store/connections/actions';
import { activeConnectionRequest, testActiveConnectionRequest } from '../../store/activeConnection/actions';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { createToastRequest } from '../../store/toasts/actions';
import Loader from '../../components/Loader';
import DBIcon from '../../components/DBIcon/DBIcon';

import * as shared from '../../styles/shared.module.scss';

const maxAllowedLength = 64;

const ConnectionCreateSchema = Yup.object().shape({
  name: Yup.string().min(2).max(64).matches(/^(?!\s)(?!.* {2})/),
  dbType: Yup.string().required('Type required'),
  RPS: Yup.number()
    .required('RPS required')
    .typeError('RPS must be a number')
    .test('isInteger', 'Not integer value provided', (value) => Number.isInteger(value))
    .max(100, 'Max limit'),
  dbUsername: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('User required'),
    }),
  dbPassword: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Password required'),
    }),
  dbHost: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Host required'),
    }),
  dbName: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Database Name required'),
    }),
  dbPort: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Port required'),
    }),
  // mssql
  encrypt: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'mssql',
      then: Yup.string().required('Encrypt required'),
    }),
  trustServerCertificate: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'mssql',
      then: Yup.string().required('Trust Server Certificate required'),
    }),
  // corezoid
  corezoidApiLogin: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Api Login required'),
    }),
  corezoidApiKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Api Key required'),
    }),
  corezoidUrl: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Sync API URL required'),
    }),
  // AWS
  accessKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'AWS',
      then: Yup.string().required('AWS Access Key required'),
    }),
  secretKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'AWS',
      then: Yup.string().required('AWS Secret Key required'),
    }),
  region: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'AWS',
      then: Yup.string().required('Region required'),
    }),
  version: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGPT',
      then: Yup.string().required('Version required'),
    }),
  organization: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGPT',
      then: Yup.string().required('Organization required'),
    }),
  secret: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGPT',
      then: Yup.string().required('Secret required'),
    }),
});

function ConnectionModify({
  updateConnection,
  getActiveConnection,
  testConnection,
  activeConnection,
  currentUser,
  createToast,
  connectionTypes,
  activeProject,
  getAccessUsers,
  addAccess,
  roles,
  isRoleAction,
  AWSRegions,
  chatGPTVersions,
}) {
  const navigate = useNavigate();
  const [testStatus, setTestStatus] = useState(loadStatus.INITIAL);
  const { shortName, resourceId } = useParams();
  const listAPI = () => (activeConnection?.folder ? navigate(`/${shortName}/connections/folder/${activeConnection?.folder}`) : navigate(`/${shortName}/connections`));
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [copiedText, copyText] = useCopyToClipboard();
  const viewRead = !isRoleAction({ key: 'connection', role: 'update', modelRole: activeConnection?.role });
  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    getAccessUsers({
      id: +resourceId,
      resource: 'connection',
    });
  }, []);

  useEffect(() => {
    setInviteList(activeConnection?.inviteList || []);
  }, [activeConnection?.inviteList]);

  const {
    submitForm, handleChange, setFieldValue, validateForm, values, errors, setValues,
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      dbType: '',
      dbName: '',
      dbUsername: '',
      dbHost: '',
      dbPassword: '',
      dbPort: '',
      dbStatus: 'active',
      corezoidUrl: '',
      corezoidApiLogin: '',
      corezoidApiKey: '',
      accessKey: '',
      secretKey: '',
      region: '',
      encrypt: 'true',
      trustServerCertificate: 'true',
    },
    validationSchema: ConnectionCreateSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      const req = {
        folder: activeConnection?.folder || 0,
        instanceName: v.name,
        description: v.description,
        id: +resourceId,
        type: v.dbType,
        status: v.dbStatus,
        limit: +v.RPS,
        connectionSettings: {
          database: v.dbName,
          user: v.dbUsername,
          host: v.dbHost,
          password: v.dbPassword,
          port: v.dbPort,
        },
      };

      if (v.dbType === 'mssql') {
        req.connectionSettings.encrypt = Boolean(v.encrypt === 'true');
        req.connectionSettings.trustServerCertificate = Boolean(v.trustServerCertificate === 'true');
      }

      if (v.dbType === 'corezoid') {
        req.connectionSettings = {
          url: v.corezoidUrl,
          apiKey: v.corezoidApiLogin,
          apiSecret: v.corezoidApiKey,
        };
      }

      if (v.dbType === 'AWS') {
        req.connectionSettings = {
          accessKey: v.accessKey,
          secretKey: v.secretKey,
          region: v.region,
        };
      }

      if (v.dbType === 'chatGPT') {
        req.connectionSettings = {
          organization: v.organization,
          secret: v.secret,
          version: v.version,
        };
      }

      updateConnection(req, () => {
        navigate(`/${shortName}/connections`);
      });
    },
  });

  useEffect(() => {
    getActiveConnection({ id: resourceId });
  }, [resourceId]);

  useEffect(() => {
    if (activeConnection) {
      const {
        instanceName, description, type, connectionSettings, status, limit,
      } = activeConnection || {};
      if (type === 'chatGPT') {
        setValues({
          RPS: limit,
          name: instanceName,
          description,
          dbType: type,
          version: connectionSettings.version,
          secret: connectionSettings.secret,
          organization: connectionSettings.organization,
          dbStatus: status || 'active',
        });
      } else if (type === 'AWS') {
        setValues({
          RPS: limit,
          name: instanceName,
          description,
          dbType: type,
          accessKey: connectionSettings.accessKey,
          secretKey: connectionSettings.secretKey,
          region: connectionSettings.region,
          dbStatus: status || 'active',
        });
      } else if (type === 'corezoid') {
        setValues({
          RPS: limit,
          name: instanceName,
          description,
          dbType: type,
          corezoidUrl: connectionSettings.url,
          corezoidApiLogin: connectionSettings.apiKey,
          corezoidApiKey: connectionSettings.apiSecret,
          dbStatus: status || 'active',
        });
      } else if (type === 'mssql') {
        setValues({
          RPS: limit,
          name: instanceName,
          description,
          dbType: type,
          dbName: connectionSettings.database,
          dbUsername: connectionSettings.user,
          dbHost: connectionSettings.host,
          dbPassword: connectionSettings.password,
          dbPort: connectionSettings.port,
          dbStatus: status || 'active',
          encrypt: connectionSettings.encrypt ? 'true' : 'false',
          trustServerCertificate: connectionSettings.trustServerCertificate ? 'true' : 'false',
        });
      } else {
        setValues({
          RPS: limit,
          name: instanceName,
          description,
          dbType: type,
          dbName: connectionSettings?.database,
          dbUsername: connectionSettings?.user,
          dbHost: connectionSettings?.host,
          dbPassword: connectionSettings?.password,
          dbPort: connectionSettings?.port,
          dbStatus: status || 'active',
        });
      }
    }
  }, [activeConnection]);

  const handleChangeProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    setIsChange(true);
    return handleChange(...arg);
  };

  const setFieldValueProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    setIsChange(true);
    return setFieldValue(...arg);
  };

  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  const generateList = (regions) => {
    if (regions) {
      return [...regions.map((r) => ({
        value: r,
        title: r,
      }))];
    }
    return [];
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess({
      id: +resourceId,
      resource: 'connection',
      projectId: +activeProject.id,
      role,
      userId: user.id,
    }, () => {
      setInviteList(newInviteList);
    });
  };

  const testConnectionConnection = () => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length) return;

      const req = {
        instanceName: values.name,
        description: values.description,
        projectId: +activeProject.id,
        type: values.dbType,
        status: values.dbStatus,
        folder: activeConnection?.folder || 0,
        connectionSettings: {
          database: values.dbName,
          user: values.dbUsername,
          host: values.dbHost,
          password: values.dbPassword,
          port: values.dbPort,
        },
      };

      if (values.dbType === 'mssql') {
        req.connectionSettings.encrypt = Boolean(values.encrypt === 'true');
        req.connectionSettings.trustServerCertificate = Boolean(values.trustServerCertificate === 'true');
      }

      if (values.dbType === 'corezoid') {
        req.connectionSettings = {
          url: values.corezoidUrl,
          apiKey: values.corezoidApiLogin,
          apiSecret: values.corezoidApiKey,
        };
      }

      if (values.dbType === 'AWS') {
        req.connectionSettings = {
          accessKey: values.accessKey,
          secretKey: values.secretKey,
          region: values.region,
        };
      }

      if (values.dbType === 'chatGPT') {
        req.connectionSettings = {
          organization: values.organization,
          secret: values.secret,
          version: values.version,
        };
      }

      setTestStatus(loadStatus.LOAD);

      testConnection(req, (err) => {
        if (err) setTestStatus(loadStatus.ERROR);
        if (!err) setTestStatus(loadStatus.SUCCESS);
      });
    });
  };

  const renderTest = (st) => {
    switch (st) {
      case loadStatus.INITIAL:
        return <ActionText title="Test connection" onClick={testConnectionConnection} />;
      case loadStatus.SUCCESS:
        return <p className={shared.successText}>Connection valid</p>;
      case loadStatus.ERROR:
        return <p className={shared.errorText}>Failed to connect</p>;
      case loadStatus.LOAD:
        return (
          <>
            <ActionText disabled title="Testing..." onClick={testConnectionConnection} />
            <Loader disabled />
          </>
        );
      default:
        return <ActionText title="Test connection" onClick={testConnectionConnection} />;
    }
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const getRoles = () => {
    const listRoles = [];
    const filredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'connection', role: 'share', modelRole: activeConnection?.role })) {
      listRoles.push(...filredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      listRoles.push(...filredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: 'connection', role: 'delete', modelRole: activeConnection?.role })) {
      listRoles.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer' });
    }
    return listRoles;
  };

  const saveInviteList = (data) => {
    const filtered = data.filter((el) => el.id);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handleChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setIsChange(true);
      setFieldValue(target.name, target.value);
    }
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit Connection</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          {values.dbType && values.dbType !== 'corezoid'
            && (
            <>
              {renderTest(testStatus)}
              <div className={shared.verticalLine} />
            </>
            )}
          <Button title="Cancel" type="secondary" onClick={listAPI} />
          <Button title="Edit connection" onClick={() => submitForm()} disabled={viewRead || !isChange} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Instance Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Instance name</span>
                <Input
                  placeholder="Enter instance name"
                  handleChange={handleChangeName}
                  value={values.name}
                  name="name"
                  error={!!errors.name && values.name?.length < 2}
                  disabled={viewRead}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Description (Optional)</span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  disabled={viewRead}
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Connection Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Type</span>
                <Select
                  list={connectionTypes?.map((db) => ({
                    value: db,
                    title: typesAPI[db] || db,
                    icon: <DBIcon type={db} style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
                  }))}
                  value={values.dbType}
                  handleChange={setFieldValueProxy}
                  name="dbType"
                  error={!!errors.dbType && !values.dbType}
                />
              </div>
              {values.dbType === 'AWS' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>AWS Access Key</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html"
                        title={(
                          <ActionIcon
                            id="accessKey_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS Access Key documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="AWS Access Key"
                      handleChange={handleChangeProxy}
                      value={values.accessKey}
                      name="accessKey"
                      error={!!errors.accessKey && !values.accessKey}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>AWS Secret Key</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html"
                        title={(
                          <ActionIcon
                            id="secretKey_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS Access Key documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="AWS Secret Key"
                      handleChange={handleChangeProxy}
                      value={values.secretKey}
                      name="secretKey"
                      error={!!errors.secretKey && !values.secretKey}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Region</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Concepts.RegionsAndAvailabilityZones.html"
                        title={(
                          <ActionIcon
                            id="region_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Select
                      list={[
                        ...generateList(AWSRegions),
                      ]}
                      value={values.region}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                      }}
                      name="region"
                      placeholder="Choose region"
                      error={!!errors.region && !values.region}
                    />
                  </div>
                </>
              ) : null}
              {values.dbType === 'chatGPT' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Version</span>
                    </span>
                    <Select
                      list={[
                        ...generateList(chatGPTVersions),
                      ]}
                      placeholder="Select version"
                      value={values.version}
                      handleChange={setFieldValueProxy}
                      name="version"
                      error={!!errors.version && !values.version}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Secret Key</span>
                    </span>
                    <Input
                      placeholder="Secret Key"
                      handleChange={handleChangeProxy}
                      value={values.secret}
                      name="secret"
                      error={!!errors.secret && !values.secret}
                      disabled={viewRead}
                      iconRight={values.secret && 'copy'}
                      handleAction={() => {
                        copyText(values.secret) && createToast({ type: 'success', text: 'Copied' });
                      }}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Organization</span>
                    </span>
                    <Input
                      placeholder="Organization"
                      handleChange={handleChangeProxy}
                      value={values.organization}
                      name="organization"
                      error={!!errors.organization && !values.organization}
                      disabled={viewRead}
                      iconRight={values.organization && 'copy'}
                      handleAction={() => {
                        copyText(values.organization) && createToast({ type: 'success', text: 'Copied' });
                      }}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType === 'corezoid') ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid Sync API URL</span>
                    <Input
                      placeholder="Enter Corezoid Sync API URL"
                      handleChange={handleChangeProxy}
                      value={values.corezoidUrl}
                      name="corezoidUrl"
                      iconRight={values.corezoidUrl && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidUrl) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidUrl && !values.corezoidUrl}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid API Login</span>
                    <Input
                      placeholder="Enter Corezoid API Login"
                      handleChange={handleChangeProxy}
                      value={values.corezoidApiLogin}
                      name="corezoidApiLogin"
                      iconRight={values.corezoidApiLogin && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidApiLogin) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidApiLogin && !values.corezoidApiLogin}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid API Key</span>
                    <Input
                      placeholder="Enter Corezoid API Key"
                      handleChange={handleChangeProxy}
                      value={values.corezoidApiKey}
                      name="corezoidApiKey"
                      id="corezoidApiKey"
                      type="password"
                      isPassword
                      iconRight={values.corezoidApiKey && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidApiKey) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidApiKey && !values.corezoidApiKey}
                      disabled={viewRead}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType && values.dbType !== 'corezoid' && values.dbType !== 'AWS' && values.dbType !== 'chatGPT') ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Database name</span>
                    <Input
                      placeholder="Enter database name"
                      handleChange={handleChangeProxy}
                      value={values.dbName}
                      name="dbName"
                      error={!!errors.dbName && !values.dbName}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Host</span>
                    <Input
                      placeholder="Enter database host"
                      handleChange={handleChangeProxy}
                      value={values.dbHost}
                      name="dbHost"
                      error={!!errors.dbHost && !values.dbHost}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Port</span>
                    <Input
                      placeholder="Enter database port"
                      handleChange={handleChangeProxy}
                      value={values.dbPort}
                      name="dbPort"
                      error={!!errors.dbPort && !values.dbPort}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Username</span>
                    <Input
                      placeholder="Enter database username"
                      handleChange={handleChangeProxy}
                      value={values.dbUsername}
                      name="dbUsername"
                      iconRight={values.dbUsername && 'copy'}
                      handleAction={() => {
                        copyText(values.dbUsername) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.dbUsername && !values.dbUsername}
                      disabled={viewRead}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Password</span>
                    <Input
                      placeholder="Enter database password"
                      handleChange={handleChangeProxy}
                      value={values.dbPassword}
                      name="dbPassword"
                      id="dbPassword"
                      type="password"
                      iconRight={values.dbPassword && 'copy'}
                      handleAction={() => {
                        copyText(values.dbPassword) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      isPassword
                      error={!!errors.dbPassword && !values.dbPassword}
                      disabled={viewRead}
                    />
                  </div>
                  {values.dbType === 'mssql' && (
                  <>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Encrypted connection</span>
                      <Select
                        list={[
                          { value: 'true', title: 'True' },
                          { value: 'false', title: 'False' },
                        ]}
                        value={values.encrypt}
                        handleChange={setFieldValueProxy}
                        name="encrypt"
                        error={!!errors.encrypt && !values.encrypt}
                        disabled={viewRead}
                      />
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Trust Server Certificate</span>
                      <Select
                        list={[
                          { value: 'true', title: 'True' },
                          { value: 'false', title: 'False' },
                        ]}
                        value={values.trustServerCertificate}
                        handleChange={setFieldValueProxy}
                        name="trustServerCertificate"
                        error={!!errors.trustServerCertificate && !values.trustServerCertificate}
                        disabled={viewRead}
                      />
                    </div>
                  </>
                  )}
                </>
              ) : null}
              {values.dbType
                && (
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Status</span>
                  <Select
                    list={[
                      { value: 'active', title: 'Active' },
                      { value: 'inactive', title: 'Inactive' },
                    ]}
                    value={values.dbStatus}
                    handleChange={setFieldValueProxy}
                    name="dbStatus"
                    disabled
                  />
                </div>
                )}
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Limits</h4>
            <div className={cn(shared.sectionContainer)}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Requests per second</span>
                <Input
                  placeholder="RPS up to 100"
                  handleChange={handleChangeProxy}
                  value={values.RPS}
                  name="RPS"
                  error={!!errors.RPS && (!values.RPS || !Number.isInteger(+values.RPS) || values.RPS > 100)}
                  style={{ maxWidth: 140 }}
                  disabled={viewRead}
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelHeading}>
            <h4 className={shared.sectionHeader}>Accesses</h4>
            {isRoleAction({ key: 'connection', role: 'share', modelRole: activeConnection?.role })
              && <ActionText title="Add user or API key" onClick={addUserOfApiKey} />}
          </div>
          <div className={shared.sidepanelTable}>
            <div className={shared.sidepanelTableHeading}>
              <div className={shared.lineHeader}>
                <div>Name</div>
                <div>Type</div>
                <div>Role</div>
              </div>
            </div>
            <hr />
            <ul className={shared.sidepanelTableBody}>
              {inviteList?.map((item) => (
                <li className={shared.historyLine} key={item.email}>
                  <div title={item.email}>
                    {item.email === currentUser.email ? (
                      <span>{`${item.firstName} ${item.lastName}`}</span>
                    ) : item.firstName || item.lastName ? (
                      <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                    ) : (
                      <span>{item.email}</span>
                    )}
                  </div>
                  <div>
                    {item.email ? (
                      <ChipsStatus title="User" type="user" />
                    ) : (
                      <ChipsStatus title="Invite" type="invite" />
                    )}
                  </div>
                  <div>
                    {viewRead ? (
                      <ChipsStatus title={listRolesName[item.role] || item.role} type="input" style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }} />
                    ) : item.role === 'owner' ? (
                      <ChipsStatus title="Owner" type="input" style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }} />
                    ) : (
                      <Select
                        style={{ width: 90 }}
                        list={getRoles(item.role)}
                        value={item.role}
                        placeholder={item.role}
                        handleChange={(email, value) => handeChangeRole(item, value)}
                        name={item.email}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            id={resourceId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
            type="connection"
            inviteList={inviteList?.map((i) => i.email) || []}
          />
        )}
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="connection"
            id={resourceId}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  activeConnection,
  auth: { currentUser },
  directory: { connectionTypes, AWSRegions, chatGPTVersions },
  activeProject,
  roles,
}) => ({
  roles,
  activeConnection,
  activeProject,
  currentUser,
  connectionTypes,
  AWSRegions,
  chatGPTVersions,
});

const mapDispatchToProps = (dispatch) => ({
  updateConnection: (data, callback) => dispatch(updateConnectionRequest(data, callback)),
  getActiveConnection: (data) => dispatch(activeConnectionRequest(data)),
  testConnection: (data, callback) => dispatch(testActiveConnectionRequest(data, callback)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ConnectionModify));
