@use "sass:map";
@import '../../styles/variables.scss';

.modal {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Overlay effect: translucent background: black w/ partial opacity */
    z-index: 1; /* Overlay effect: positioned over other containers */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    padding-top: 60px; /* Location of the content container */
}
/* Modal content */
.modalContent {
    background-color: #fff;
    width: 70%; /* Width in proportion to its parent container*/
    max-width: 640px; /* Max width where it stops expanding */
    height: auto; /* Height in proportion to its parent container */
    margin: auto; /* Auto margin according to the element width */
    border-radius: 10px;
}

.header {
    padding: 18px 20px;
    border-bottom: 1px solid map.get($colors-basic, "divider");
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-weight: map.get($font-weights, "semibold");
    }
}

.body {
    padding: 16px 20px 20px 20px;
    background-color: map.get($colors-basic, "sideMenu");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .mainContent {
        display: flex;
        padding: 0 0 46px 0;
    }

    .modelTitle {
        color: map.get($colors-basic, "primaryBlue");
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}