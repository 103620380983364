/* eslint-disable max-len */
import { Roles } from './constants';

export const listRolesRequest = (data) => ({ type: Roles.listRolesRequest, data });
export const listRolesSuccess = (data) => ({ type: Roles.listRolesSuccess, data });
export const listRolesError = () => ({ type: Roles.listRolesError });

export const removeRoleRequest = (data, callback) => ({ type: Roles.removeRoleRequest, data, callback });
export const removeRoleSuccess = (data) => ({ type: Roles.removeRoleSuccess, data });
export const removeRoleError = () => ({ type: Roles.removeRoleError });

export const modifyRoleRequest = (data, callback) => ({ type: Roles.modifyRoleRequest, data, callback });
export const modifyRoleSuccess = (data) => ({ type: Roles.modifyRoleSuccess, data });
export const modifyRoleError = () => ({ type: Roles.modifyRoleError });

export const createRoleRequest = (data, callback) => ({ type: Roles.createRoleRequest, data, callback });
export const createRoleSuccess = (data) => ({ type: Roles.createRoleSuccess, data });
export const createRoleError = () => ({ type: Roles.createRoleError });
