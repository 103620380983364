/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './Checkbox.module.scss';

const typeValues = {
  default: 'default',
  colored: 'colored',
};

function Checkbox({
  value,
  label,
  handleChange,
  keyItem,
  type = typeValues.default,
  disabled,
  style = {},
  size = 'middle',
}) {
  const [isChecked, setIsChecked] = useState(value);

  const onClick = () => {
    setIsChecked(!isChecked);
    handleChange && handleChange(!isChecked);
  };
  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <div
      className={cn(styles.checkboxWrap, {
        [styles.colored]: type === typeValues.colored,
      })}
      onClick={onClick}
      key={keyItem}
      style={style}
    >
      <input checked={isChecked} type="checkbox" onChange={onClick} />
      <div className={`${styles.checkbox} ${isChecked ? styles.checked : styles.unchecked} ${styles[size]}`}>
        <svg viewBox="0 0 24 24">
          <polyline points="20 5 9 16 4 11" />
        </svg>
      </div>
      <span>{label}</span>
    </div>
  );
}

Checkbox.propTypes = {
  value: PropTypes.string,
  keyItem: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Checkbox;
