/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UploadIcon from '../../pictures/upload.svg';
import * as style from './InputFile.module.scss';

function InputFile({
  name,
  value,
  handleChange,
  disabled,
  placeholder,
  style: customStyle = {},
  error,
  id,
}) {
  const [file, setFile] = useState('');
  const [base64String, setBase64String] = useState('');
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    if (disabled) return;
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setFile(selectedFile);
        setBase64String(reader.result);
      };
    }
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={style.inputWrapper}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />
      <input
        id={id}
        key={name}
        style={customStyle}
        className={cn(
          style.input,
          {
            [style.filled]: !!file.name,
            [style.error]: error,
          },
        )}
        type="text"
        placeholder={placeholder || ''}
        value={file.name}
        onChange={handleFileInputChange}
        name={name}
        disabled
      />
      <div
        className={style.iconRight}
        tabIndex="0"
        role="button"
        onClick={handleCustomButtonClick}
        onKeyDown={handleCustomButtonClick}
      >
        <UploadIcon />
      </div>
    </div>
  );
}

InputFile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputFile;
