import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  listRolesRequest, listRolesSuccess,
  removeRoleRequest, removeRoleSuccess,
} from './actions';
import { Roles } from './constants';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchRoles(ops) {
  const { data } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/role?projectId=${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listRolesSuccess(parsed.items));
      } else if (response.status === 401) {
        yield put(refreshRequest(listRolesRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeRole(ops) {
  const { data, callback } = ops;
  const { roleId, replaceId, name } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/role', 'DELETE', { roleId, replaceId }, authData.access_token);
      if (response.ok) {
        yield put(removeRoleSuccess(data));
        yield put(createToastRequest({ type: 'success', title: name, text: 'has been deleted' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeRoleRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* modifyRole(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/role', 'PUT', data, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({ type: 'success', title: data.role?.name, text: 'role has been updated successfully' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeRoleRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createRole(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/role', 'POST', data, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({ type: 'success', title: data.role?.name, text: 'role has been created successfully' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeRoleRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* usersSaga() {
  yield takeEvery(Roles.listRolesRequest, fetchRoles);
  yield takeEvery(Roles.removeRoleRequest, removeRole);
  yield takeEvery(Roles.modifyRoleRequest, modifyRole);
  yield takeEvery(Roles.createRoleRequest, createRole);
}

function* configSaga() {
  yield all([fork(usersSaga)]);
}

export default configSaga;
