@use "sass:map";
@import '../../styles/variables.scss';

.projectSelection {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 80px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.iconGroup {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 12px;
}

.titleGroup {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.actionGroup {
    margin: auto 6px;
}

.popupMenu {
    position: absolute;
    background-color: white;
    top: 24px;
    left: 12px;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(8,8,40, 0.1);
    z-index: 9999;

    .unscrolledItem {
        box-sizing: border-box;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 15px 12px;
        border-bottom: 1px solid map.get($colors-basic, "divider");
        position: relative;
    }

    ul {
        width: 100%;
        max-height: 188px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
       }

        .unscolledItem, li {
            box-sizing: border-box;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 15px 12px;
            border-bottom: 1px solid map.get($colors-basic, "divider");
            position: relative;

            &.link {
                padding: 0;

                button {
                    min-height: 46px;
                    cursor: pointer;
                    font-size: map.get($font-sizes, "body");
                    border: 0;
                    background-color: transparent;
                    padding: 15px 12px;
                    display: block;
                    text-align: start;
                    width: 100%;
                    height: 100%;

                    span {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        display: block;
                        text-overflow: ellipsis;
                    }
                }

            }

            &.activeLink {
                background-color: map.get($colors-basic, "selectedOption");

                button {
                    padding: 15px 24px 15px 12px;
                }

                .iconSelected {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .unscolledItem {
            &:first-child {
                padding: 14px 12px 16px 12px;
            }
        }

        .titlePopUpHeading {
            font-weight: 600;
        }

        .number {
            color: map.get($colors-basic, "textGray");
            margin-left: 4px;
        }
}

.heading {
    font-size: map.get($font-sizes, "caption");
    color: map.get($colors-basic, "textGray");
}

.title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}