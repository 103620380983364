/* eslint-disable max-len */
import { Auth } from './constants';

export const registerRequest = (data, callback) => ({ type: Auth.registerRequest, data, callback });
export const registerSuccess = () => ({ type: Auth.registerSuccess });
export const registerError = () => ({ type: Auth.registerError });

export const resetPasswordRequest = (data, callback) => ({ type: Auth.resetPasswordRequest, data, callback });
export const resetPasswordSuccess = () => ({ type: Auth.resetPasswordSuccess });
export const resetPasswordError = () => ({ type: Auth.resetPasswordError });

export const changePasswordRequest = (data, callback) => ({ type: Auth.changePasswordRequest, data, callback });
export const changePasswordSuccess = () => ({ type: Auth.changePasswordSuccess });
export const changePasswordError = () => ({ type: Auth.changePasswordError });

export const authRequest = (data, callback) => ({ type: Auth.authRequest, data, callback });
export const authSuccess = (data) => ({ type: Auth.authSuccess, data });
export const authError = () => ({ type: Auth.authError });

export const logoutRequest = (callback) => ({ type: Auth.logoutRequest, callback });
export const logoutSuccess = () => ({ type: Auth.logoutSuccess });
export const logoutError = () => ({ type: Auth.logoutError });

export const refreshRequest = (action, ...params) => ({ type: Auth.refreshRequest, action, params });
export const refreshSuccess = (data) => ({ type: Auth.refreshSuccess, data });
export const refreshError = () => ({ type: Auth.refreshError });

export const meRequest = () => ({ type: Auth.meRequest });
export const meSuccess = (data) => ({ type: Auth.meSuccess, data });
export const meError = () => ({ type: Auth.meError });

export const meInProjectRequest = (data) => ({ type: Auth.meInProjectRequest, data });
export const meInProjectSuccess = (data) => ({ type: Auth.meInProjectSuccess, data });
export const meInProjectError = () => ({ type: Auth.meInProjectError });

export const checkHashRequest = (data, callback) => ({ type: Auth.checkHashRequest, data, callback });
export const checkHashSuccess = () => ({ type: Auth.checkHashSuccess });
export const checkHashError = () => ({ type: Auth.checkHashError });

export const inviteRequest = (data, callback) => ({ type: Auth.inviteRequest, data, callback });
export const inviteSuccess = () => ({ type: Auth.inviteSuccess });
export const inviteError = () => ({ type: Auth.inviteError });
