/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import { useCopyToClipboard } from '../../hooks/hooks';
import Button from '../../components/Button/Button';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Loader from '../../components/Loader';
import DBIcon from '../../components/DBIcon/DBIcon';

import { loadStatus, typesAPI } from '../../helpers/const';
import { roleMapper } from '../../helpers/utils';
import { createConnectionRequest } from '../../store/connections/actions';
import { testActiveConnectionRequest } from '../../store/activeConnection/actions';
import { createToastRequest } from '../../store/toasts/actions';

import * as shared from '../../styles/shared.module.scss';

const chatGptVersions = [{ value: '3.5', title: '3.5' }];

const maxAllowedLength = 64;

const ConnectionCreateSchema = Yup.object().shape({
  name: Yup.string().min(2).max(64).matches(/^(?!\s)(?!.* {2})/)
    .required('Name required'),
  dbType: Yup.string().required('Type required'),
  RPS: Yup.number()
    .required('RPS required')
    .typeError('RPS must be a number')
    .test('isInteger', 'Not integer value provided', (value) => Number.isInteger(value))
    .max(100, 'Max limit'),
  dbUsername: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('User required'),
    }),
  dbPassword: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Password required'),
    }),
  dbHost: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Host required'),
    }),
  dbName: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Database Name required'),
    }),
  dbPort: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType !== 'corezoid' && dbType !== 'AWS' && dbType !== 'chatGPT',
      then: Yup.string().required('Port required'),
    }),
  // mssql
  encrypt: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'mssql',
      then: Yup.string().required('Encrypt required'),
    }),
  trustServerCertificate: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'mssql',
      then: Yup.string().required('Trust Server Certificate required'),
    }),
  // corezoid
  corezoidApiLogin: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Api Login required'),
    }),
  corezoidApiKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Api Key required'),
    }),
  corezoidUrl: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'corezoid',
      then: Yup.string().required('Corezoid Sync API URL required'),
    }),
  // AWS
  accessKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'AWS',
      then: Yup.string().required('AWS Access Key required'),
    }),
  secretKey: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'AWS',
      then: Yup.string().required('AWS Secret Key required'),
    }),
  region: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'AWS',
      then: Yup.string().required('Region required'),
    }),
  version: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGPT',
      then: Yup.string().required('Version required'),
    }),
  organization: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGPT',
      then: Yup.string().required('Organization required'),
    }),
  secret: Yup.string()
    .when('dbType', {
      is: (dbType) => dbType === 'chatGPT',
      then: Yup.string().required('Secret required'),
    }),
});

function ConnectionCreate({
  createDatabase, testDatabase, currentUser, connectionTypes,
  activeProject, createToast, AWSRegions, chatGPTVersions,
}) {
  const [testStatus, setTestStatus] = useState(loadStatus.LOAD);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const navigate = useNavigate();
  const { shortName, folderId } = useParams();
  const listAPI = () => (folderId ? navigate(`/${shortName}/connections/folder/${folderId}`) : navigate(`/${shortName}/connections`));
  const [copiedText, copyText] = useCopyToClipboard();

  useEffect(() => {
    if (currentUser) {
      setInviteList([{
        userFirstName: currentUser.firstName,
        userLastName: currentUser.lastName,
        userEmail: currentUser.email,
        roleId: 2,
      }]);
    }
  }, [currentUser]);

  const {
    submitForm, handleChange, setFieldValue, validateForm, values, errors,
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      dbType: '',
      dbName: '',
      dbUsername: '',
      dbHost: '',
      dbPassword: '',
      dbPort: '',
      dbStatus: 'active',
      corezoidUrl: '',
      corezoidApiLogin: '',
      corezoidApiKey: '',
      accessKey: '',
      secretKey: '',
      region: '',
      encrypt: 'true',
      trustServerCertificate: 'true',
      version: '',
      RPS: 100,
    },
    validationSchema: ConnectionCreateSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      const req = {
        instanceName: v.name,
        description: v.description,
        projectId: +activeProject.id,
        type: v.dbType,
        status: v.dbStatus,
        limit: +v.RPS,
        folder: folderId ? +folderId : 0,
        connectionSettings: {
          database: v.dbName,
          user: v.dbUsername,
          host: v.dbHost,
          password: v.dbPassword,
          port: v.dbPort,
        },
      };

      if (v.dbType === 'mssql') {
        req.connectionSettings.encrypt = Boolean(v.encrypt === 'true');
        req.connectionSettings.trustServerCertificate = Boolean(v.trustServerCertificate === 'true');
      }

      if (v.dbType === 'corezoid') {
        req.connectionSettings = {
          url: v.corezoidUrl,
          apiKey: v.corezoidApiLogin,
          apiSecret: v.corezoidApiKey,
        };
      }

      if (v.dbType === 'AWS') {
        req.connectionSettings = {
          accessKey: v.accessKey,
          secretKey: v.secretKey,
          region: v.region,
        };
      }

      if (v.dbType === 'chatGPT') {
        req.connectionSettings = {
          organization: v.organization,
          secret: v.secret,
          version: v.version,
        };
      }

      createDatabase(req, () => (folderId ? navigate(`/${shortName}/connections/folder/${folderId}`) : navigate(`/${shortName}/connections`)));
    },
    onChange: () => {
      setTestStatus(loadStatus.INITIAL);
    },
  });

  const generateList = (regions) => {
    if (regions) {
      return [...regions.map((r) => ({
        value: r,
        title: r,
      }))];
    }
    return [];
  };

  const handleChangeProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    return handleChange(...arg);
  };

  const setFieldValueProxy = (...arg) => {
    if (testStatus !== loadStatus.INITIAL) setTestStatus(loadStatus.INITIAL);
    return setFieldValue(...arg);
  };

  const handeChangeRole = (email, role) => {
    const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
    setInviteList(newInviteList);
  };

  const testDatabaseConnection = () => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length) return;

      const req = {
        instanceName: values.name,
        description: values.description,
        projectId: +activeProject.id,
        type: values.dbType,
        status: values.dbStatus,
        folder: folderId ? +folderId : 0,
        connectionSettings: {
          database: values.dbName,
          user: values.dbUsername,
          host: values.dbHost,
          password: values.dbPassword,
          port: values.dbPort,
        },
      };

      if (values.dbType === 'mssql') {
        req.connectionSettings.encrypt = Boolean(values.encrypt === 'true');
        req.connectionSettings.trustServerCertificate = Boolean(values.trustServerCertificate === 'true');
      }

      if (values.dbType === 'corezoid') {
        req.connectionSettings = {
          url: values.corezoidUrl,
          apiKey: values.corezoidApiLogin,
          apiSecret: values.corezoidApiKey,
        };
      }

      if (values.dbType === 'AWS') {
        req.connectionSettings = {
          accessKey: values.accessKey,
          secretKey: values.secretKey,
          region: values.region,
        };
      }

      if (values.dbType === 'chatGPT') {
        req.connectionSettings = {
          organization: values.organization,
          secret: values.secret,
          version: values.version,
        };
      }

      setTestStatus(loadStatus.LOAD);

      testDatabase(req, (err) => {
        if (err) setTestStatus(loadStatus.ERROR);
        if (!err) setTestStatus(loadStatus.SUCCESS);
      });
    });
  };

  const renderTest = (st) => {
    switch (st) {
      case loadStatus.INITIAL:
        return <ActionText title="Test connection" onClick={testDatabaseConnection} />;
      case loadStatus.SUCCESS:
        return <p className={shared.successText}>Connection valid</p>;
      case loadStatus.ERROR:
        return <p className={shared.errorText}>Failed to connect</p>;
      case loadStatus.LOAD:
        return (
          <>
            <ActionText disabled title="Testing..." onClick={testDatabaseConnection} />
            <Loader disabled />
          </>
        );
      default:
        return <ActionText title="Test connection" onClick={testDatabaseConnection} />;
    }
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const saveInviteList = (data) => {
    const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handelChangeName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setFieldValue(target.name, target.value);
    }
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Create Connection</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          {values.dbType && values.dbType !== 'corezoid'
            && (
            <>
              {renderTest(testStatus)}
              <div className={shared.verticalLine} />
            </>
            )}
          <Button title="Cancel" type="secondary" onClick={listAPI} />
          <Button title="Create connection" onClick={submitForm} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Instance Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Instance name</span>
                <Input
                  placeholder="Enter instance name"
                  handleChange={handelChangeName}
                  value={values.name}
                  name="name"
                  error={!!errors.name && values.name?.length < 2}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Description (Optional)</span>
                <Input
                  placeholder="Enter description"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Connection Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Type</span>
                <Select
                  list={connectionTypes?.map((db) => ({
                    value: db,
                    title: typesAPI[db] || db,
                    icon: <DBIcon type={db} style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
                  }))}
                  placeholder="Select connection type"
                  value={values.dbType}
                  handleChange={setFieldValueProxy}
                  name="dbType"
                  error={!!errors.dbType && !values.dbType}
                />
              </div>
              {values.dbType === 'AWS' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>AWS Access Key</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html"
                        title={(
                          <ActionIcon
                            id="accessKey_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS Access Key documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="AWS Access Key"
                      handleChange={handleChangeProxy}
                      value={values.accessKey}
                      name="accessKey"
                      error={!!errors.accessKey && !values.accessKey}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>AWS Secret Key</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html"
                        title={(
                          <ActionIcon
                            id="secretKey_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS Access Key documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="AWS Secret Key"
                      handleChange={handleChangeProxy}
                      value={values.secretKey}
                      name="secretKey"
                      error={!!errors.secretKey && !values.secretKey}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Region</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Concepts.RegionsAndAvailabilityZones.html"
                        title={(
                          <ActionIcon
                            id="region_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Select
                      list={[
                        ...generateList(AWSRegions),
                      ]}
                      value={values.region}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                      }}
                      name="region"
                      placeholder="Choose region"
                      error={!!errors.region && !values.region}
                    />
                  </div>
                </>
              ) : null}
              {values.dbType === 'chatGPT' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Version</span>
                    </span>
                    <Select
                      list={[
                        ...generateList(chatGPTVersions),
                      ]}
                      placeholder="Select version"
                      value={values.version}
                      handleChange={setFieldValueProxy}
                      name="version"
                      error={!!errors.version && !values.version}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Secret Key</span>
                    </span>
                    <Input
                      placeholder="Secret Key"
                      handleChange={handleChangeProxy}
                      value={values.secret}
                      name="secret"
                      error={!!errors.secret && !values.secret}
                      iconRight={values.secret && 'copy'}
                      handleAction={() => {
                        copyText(values.secret) && createToast({ type: 'success', text: 'Copied' });
                      }}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Organization</span>
                    </span>
                    <Input
                      placeholder="Organization"
                      handleChange={handleChangeProxy}
                      value={values.organization}
                      name="organization"
                      error={!!errors.organization && !values.organization}
                      iconRight={values.organization && 'copy'}
                      handleAction={() => {
                        copyText(values.organization) && createToast({ type: 'success', text: 'Copied' });
                      }}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType === 'corezoid') ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid Sync API URL</span>
                    <Input
                      placeholder="Enter Corezoid Sync API URL"
                      handleChange={handleChangeProxy}
                      value={values.corezoidUrl}
                      name="corezoidUrl"
                      iconRight={values.corezoidUrl && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidUrl) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidUrl && !values.corezoidUrl}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid API Login</span>
                    <Input
                      placeholder="Enter Corezoid API Login"
                      handleChange={handleChangeProxy}
                      value={values.corezoidApiLogin}
                      name="corezoidApiLogin"
                      iconRight={values.corezoidApiLogin && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidApiLogin) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidApiLogin && !values.corezoidApiLogin}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid API Key</span>
                    <Input
                      placeholder="Enter Corezoid API Key"
                      handleChange={handleChangeProxy}
                      value={values.corezoidApiKey}
                      name="corezoidApiKey"
                      id="corezoidApiKey"
                      type="password"
                      isPassword
                      iconRight={values.corezoidApiKey && 'copy'}
                      handleAction={() => {
                        copyText(values.corezoidApiKey) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.corezoidApiKey && !values.corezoidApiKey}
                    />
                  </div>
                </>
              ) : null}
              {(values.dbType && values.dbType !== 'AWS' && values.dbType !== 'corezoid' && values.dbType !== 'chatGPT') ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Database name</span>
                    <Input
                      placeholder="Enter database name"
                      handleChange={handleChangeProxy}
                      value={values.dbName}
                      name="dbName"
                      error={!!errors.dbName && !values.dbName}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Host</span>
                    <Input
                      placeholder="Enter host"
                      handleChange={handleChangeProxy}
                      value={values.dbHost}
                      name="dbHost"
                      error={!!errors.dbHost && !values.dbHost}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Port</span>
                    <Input
                      placeholder="Enter port"
                      handleChange={handleChangeProxy}
                      value={values.dbPort}
                      name="dbPort"
                      error={!!errors.dbPort && !values.dbPort}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Username</span>
                    <Input
                      placeholder="Enter username"
                      handleChange={handleChangeProxy}
                      value={values.dbUsername}
                      name="dbUsername"
                      iconRight={values.dbUsername && 'copy'}
                      handleAction={() => {
                        copyText(values.dbUsername) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.dbUsername && !values.dbUsername}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Password</span>
                    <Input
                      placeholder="Enter password"
                      handleChange={handleChangeProxy}
                      value={values.dbPassword}
                      type="password"
                      name="dbPassword"
                      id="dbPassword"
                      isPassword
                      iconRight={values.dbPassword && 'copy'}
                      handleAction={() => {
                        copyText(values.dbPassword) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.dbPassword && !values.dbPassword}
                    />
                  </div>
                  {values.dbType === 'mssql' && (
                  <>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Encrypted connection</span>
                      <Select
                        list={[
                          { value: 'true', title: 'True' },
                          { value: 'false', title: 'False' },
                        ]}
                        value={values.encrypt}
                        handleChange={setFieldValueProxy}
                        name="encrypt"
                        error={!!errors.encrypt && !values.encrypt}
                      />
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Trust Server Certificate</span>
                      <Select
                        list={[
                          { value: 'true', title: 'True' },
                          { value: 'false', title: 'False' },
                        ]}
                        value={values.trustServerCertificate}
                        handleChange={setFieldValueProxy}
                        name="trustServerCertificate"
                        error={!!errors.trustServerCertificate && !values.trustServerCertificate}
                      />
                    </div>
                  </>
                  )}
                </>
              ) : null}
              {values.dbType
                && (
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Status</span>
                  <Select
                    list={[
                      { value: 'active', title: 'Active' },
                      { value: 'inactive', title: 'Inactive' },
                    ]}
                    value={values.dbStatus}
                    handleChange={setFieldValueProxy}
                    name="dbStatus"
                    disabled
                  />
                </div>
                )}
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Limits</h4>
            <div className={cn(shared.sectionContainer)}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Requests per second</span>
                <Input
                  placeholder="RPS up to 100"
                  handleChange={handleChangeProxy}
                  value={values.RPS}
                  name="RPS"
                  error={!!errors.RPS && (!values.RPS || !Number.isInteger(+values.RPS) || values.RPS > 100)}
                  style={{ maxWidth: 140 }}
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelHeading}>
            <div className={shared.subHeaders}>
              <h4 className={shared.sectionHeader}>Accesses</h4>
              {/* <ActionText title="Add user or API key" onClick={addUserOfApiKey} /> */}
            </div>
          </div>
          <div className={shared.sidepanelTable}>
            <div className={shared.sidepanelTableHeading}>
              <div className={shared.lineHeader}>
                <div>Name</div>
                <div>Type</div>
                <div>Role</div>
              </div>
            </div>
            <hr />
            <ul className={shared.sidepanelTableBody}>
              {inviteList && inviteList.map((item) => (
                <li className={shared.historyLine} key={item.userEmail}>
                  <div title={item.userEmail}>
                    {item.userEmail === currentUser.email ? (
                      <span>{`${item.userFirstName} ${item.userLastName}`}</span>
                    ) : (
                      <span>{item.userEmail}</span>
                    )}
                  </div>
                  <div>
                    {item.userEmail === currentUser.email ? (
                      <ChipsStatus title="User" type="user" />
                    ) : (
                      <ChipsStatus title="Invite" type="invite" />
                    )}
                  </div>
                  <div>
                    {item.userEmail === currentUser.email ? (
                      <Select
                        style={{ width: 90 }}
                        list={[
                          { value: 2, title: roleMapper(2) },
                        ]}
                        value={item.roleId}
                        handleChange={(email, value) => handeChangeRole(email, value)}
                        name={item.userEmail}
                        disabled
                      />
                    ) : (
                      <Select
                        style={{ width: 90 }}
                        list={[
                          { value: 3, title: roleMapper(3) },
                          { value: 4, title: roleMapper(4) },
                        ]}
                        value={item.roleId}
                        handleChange={(email, value) => handeChangeRole(email, value)}
                        name={item.userEmail}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser }, directory: { connectionTypes, AWSRegions, chatGPTVersions }, activeProject }) => ({
  activeProject,
  currentUser,
  connectionTypes,
  AWSRegions,
  chatGPTVersions,
});

const mapDispatchToProps = (dispatch) => ({
  createDatabase: (data, callback) => dispatch(createConnectionRequest(data, callback)),
  testDatabase: (data, callback) => dispatch(testActiveConnectionRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionCreate);
