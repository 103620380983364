import { ActiveConnection } from './constants';

const initialState = null;

export const activeConnectionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveConnection.activeConnectionSuccess:
      return {
        ...state,
        ...action.data
      }
    case ActiveConnection.getAccessUsersConnectionSuccess:
      return {
        ...state,
        inviteList: action.data
      }
    default:
      return state;
  }
};
