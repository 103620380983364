/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import * as styles from './ExportEndpointModal.module.scss';
import Checkbox from '../Checkbox/Checkbox';

function ExportEndpointModal({
  onClose,
  onSubmit,
  list
}) {
  const [linkApi, setlinkApi] = useState(false);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Export APIs</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
                <span className={styles.modelTitle}>
                  {list.length}{' '}{list.length > 1 ? 'APIs' : 'API'}
                </span>
                {' '}will be scheduled for exporting.
              </div>
              Once export process is completed, you`ll receive an email with the link to download the JSON file with all APIs.
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroup}>
              <Checkbox handleChange={setlinkApi} label={<div>Export linked <b>Connections</b></div>} size='small' value={linkApi}/>
            </div>
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 160, marginLeft: 12 }}
                title="Export APIs"
                onClick={() => {
                  onSubmit(linkApi)
                  onClose()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportEndpointModal;
