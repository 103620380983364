/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './CommitDiff.module.scss';

function CommitDiff({ data }) {
  const keys = Object.keys(data);
  return (
    <div className={styles.commitDiff}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Before</th>
            <th>After</th>
          </tr>
        </thead>
        <tbody>
          {keys?.map((k) => (
            <tr key={k}>
              <td>{k}</td>
              <td>
                {data[k].oldValue}
              </td>
              <td>
                {data[k].newValue}
              </td>
            </tr>
          ))}
          {keys.length === 0 && (
            <tr>
              <td />
              <td colSpan="1">
                No diff found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

CommitDiff.propTypes = {
  data: PropTypes.object,
};

export default CommitDiff;
