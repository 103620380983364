import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  listErrorsRequest,
  listErrorsSuccess,
} from './actions';
import {
  refreshRequest,
} from '../auth/actions';
import { createToastRequest } from '../toasts/actions';
import { Errors } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchErrors(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    const response = yield call(api, `/errors`, 'GET');
    if (response.ok) {
      const parsed = yield response.json();
      yield put(listErrorsSuccess(parsed));
      if (callback) callback();
    } else if (response.status === 401) {
      yield put(refreshRequest(listErrorsRequest, data, callback));
      if (callback) callback(true);
    } else {
      const parsed = yield response.json();
      yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      if (callback) callback(true);
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* errorsSaga() {
  yield takeEvery(Errors.listErrorsRequest, fetchErrors);
}

function* configSaga() {
  yield all([fork(errorsSaga)]);
}

export default configSaga;
