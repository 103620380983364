/* eslint-disable import/prefer-default-export */
export const Invites = {
  invitesRequest: 'INVITES_PENDING',
  invitesSuccess: 'INVITES_SUCCESS',
  invitesError: 'INVITES_ERROR',

  addAccessRequest: 'ADD_ACCESS_PENDING',
  addAccessSuccess: 'ADD_ACCESS_SUCCESS',
  addAccessError: 'ADD_ACCESS_ERROR',

  deleteAccessRequest: 'DELETE_ACCESS_PENDING',
  deleteAccessSuccess: 'DELETE_ACCESS_SUCCESS',
  deleteAccessError: 'DELETE_ACCESS_ERROR',
};
