/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import CreateFolderModal from '../../components/CreateFolderModal';
import RenameFolderModal from '../../components/RenameFolderModal';
import RemoveFolderModal from '../../components/RemoveFolderModal';
import Breadscrumbs from '../../components/Breadscrumbs';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import ActionText from '../../components/ActionText/ActionText';
import RemoveConnectionModal from '../../components/RemoveConnectionModal/RemoveConnectionModal';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import ExportModal from '../../components/ExportConnectionModal/ExportConnectionModal';
import Directory from './atoms/Directory/Directory';
import Loader from '../../components/Loader';
import DBIcon from '../../components/DBIcon/DBIcon';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  searchConnectionsRequest,
  copyConnectionRequest,
  exportConnectionRequest,
  getConnectionFolderContentRequest,
  moveConnectionFolderRequest,
  moveConnectionRequest,
} from '../../store/connections/actions';
import { invitesRequest, addAccessRequest } from '../../store/invites/actions';
import {
  getAllData, getAllfoldersData,
  getFoldersDataInFolder, getDataInFolder,
  checker,
} from '../../helpers/utils';
import { typesAPI } from '../../helpers/const';
import { isRoleAction } from '../../helpers/hocs';

import SortIcon from '../../pictures/sort.svg';
import ConnectionIcon from '../../pictures/api-database.svg';
import FolderIcon from '../../pictures/folder.svg';

import 'react-tooltip/dist/react-tooltip.css';
import * as shared from '../../styles/shared.module.scss';

const headers = {
  checkbox: { title: '' },
  // id: { title: 'ID' },
  instanceName: { title: 'Instance name' },
  connectionName: { title: 'Connection name' },
  connectionType: { title: 'Connection type' },
  username: { title: 'Username' },
  status: { title: 'Status' },
  action: { title: 'Actions' },
};

let searchTimeout = null;

function ConnectionList({
  getFolder,
  searchConnections,
  connections,
  activeProject,
  isRoleAction,
  copyConnection,
  exportConnection,
  addAccess,
  currentUser,
  moveConnectionFolder,
  moveConnection,
}) {
  const [loading, setLoading] = useState(true);
  const { data: connectionsData = [], foldersData = [], breadscrumbData = [] } = connections || {};
  const [activeModel, setActiveModel] = useState({});
  const [inputVisible, setImputVisible] = useState(false);

  const [createFolderModalOpened, setCreateFolderModalOpened] = useState(false);
  const [renameFolderModalOpened, setRenameFolderModalOpened] = useState(false);
  const [removeFolderModalOpened, setRemoveFolderModalOpened] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [exportModalOpened, setExportModalOpened] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [exportItems, setExportItems] = useState([]);
  const [sortBy, setSortBy] = useState('instanceName');
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { shortName, folderId = 0 } = useParams();
  const navigate = useNavigate();

  const [contextMenuModelActive, setContextMenuModelActive] = useState(false);
  const [contextMenuTop, setContextMenuTop] = useState(0);
  const [contextMenuLeft, setContextMenuLeft] = useState(0);

  const handleContextMenu = (event, id) => {
    event.preventDefault();

    setContextMenuModelActive(id);

    const { clientX, clientY } = event;

    setContextMenuTop(clientY);
    setContextMenuLeft(clientX);
  };

  const createFolder = () => setCreateFolderModalOpened(true);

  useEffect(() => {
    setLoading(true);
    if (activeProject?.id) {
      setSelectedItems([]);
      setSelectedFolders([]);
      setExportItems([]);

      getFolder({
        folder: folderId || 0,
        projectId: activeProject?.id,
      }, () => {
        setLoading(false);
      });
    }
  }, [activeProject, folderId]);

  const createConnection = () => (folderId ? navigate(`/${shortName}/connections/folder/${folderId}/create`) : navigate(`/${shortName}/connections/create`));
  const importConnection = () => navigate(`/${shortName}/connections/import`);
  const modifyConnection = (connectionId) => navigate(`/${shortName}/connections/${connectionId}`);
  const openFolder = (f) => navigate(`/${shortName}/connections/folder/${f}`);

  const moveFolder = (f, destination) => {
    setSelectedItems([]);
    moveConnectionFolder({
      ...f,
      projectId: activeProject?.id,
      parentId: destination,
      rootAction: +destination === +folderId,
    });
  };

  const moveObject = (o, destination) => {
    setSelectedItems([]);
    moveConnection({
      ...o,
      folder: destination,
      rootAction: +destination === +folderId,
    });
  };

  const changeSearch = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (e.target.value) {
        searchConnections({
          query: e.target.value,
          projectId: activeProject.id,
        });
      } else {
        getFolder({
          folder: folderId || 0,
          projectId: activeProject?.id,
        });
      }
    }, 400);
  };

  const copyCon = (model) => {
    copyConnection(
      {
        ...model,
        projectId: activeProject.id,
        rootAction: +model.folder === +folderId,
      },
      (access, id) => {
        console.log('access', access);
      },
    );
  };

  const clear = () => {
    setSearchValue('');
    getFolder({
      folder: folderId || 0,
      projectId: activeProject?.id,
    });
    setImputVisible(false);
  };

  const clickRemove = (model) => {
    setActiveModel(model);
    setRemoveModalOpened(true);
  };

  const clickRemoveSelected = () => {
    setActiveModel(selectedItems);
    setRemoveModalOpened(true);
  };

  const clickShare = (model) => {
    setActiveModel(model);
    setShareModalOpened(true);
  };

  const renameFolder = (model) => {
    setContextMenuModelActive(false);
    setActiveModel(model);
    setRenameFolderModalOpened(true);
  };

  const shareFolder = () => {
    console.log('share');
  };

  const removeFolder = (model) => {
    setContextMenuModelActive(false);
    setActiveModel(model);
    setRemoveFolderModalOpened(true);
  };

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      const input = ref.current.getElementsByTagName('input')[0];
      if (input.value) return;
      setImputVisible(false);
    }

    if (tableRef && tableRef.current && !tableRef.current.contains(event.target)) {
      setContextMenuModelActive(false);
    }
  };

  const sendInviteAction = (data) => {
    // sendInvite(data, () => {
    //   setShareModalOpened(false);
    // });
  };

  const exportC = (linkApi = false) => {
    exportConnection({
      projectId: activeProject?.id,
      linkApi,
      connections: exportItems,
    }, (file) => {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'connections.zip';
      a.click();
      setExportItems(selectedItems);
    });
  };

  const exportSelected = () => {
    setExportModalOpened(true);
  };

  const exportOne = (item) => {
    setExportModalOpened(true);
    setExportItems([item.id]);
  };

  const selectAll = (isCheked) => {
    if (isCheked) {
      const allIdsConnections = getAllData(connections);
      const allIdsFolders = getAllfoldersData(connections);

      setSelectedItems(allIdsConnections);
      setSelectedFolders(allIdsFolders);
      setExportItems(allIdsConnections);
    } else {
      setSelectedItems([]);
      setSelectedFolders([]);
      setExportItems([]);
    }
  };

  const selectOne = (isCheked, id) => {
    if (isCheked) {
      setSelectedItems([...selectedItems, id]);
      setExportItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter((c) => c !== id));
      setExportItems(selectedItems.filter((c) => c !== id));
    }
  };

  const openFolderAction = (id) => {
    selectAll(false);
    openFolder(id);
  };

  const selectOneFolder = (isCheked, id) => {
    const nestedFolders = getFoldersDataInFolder(connections, id) || [];
    const nestedObjs = getDataInFolder(connections, id) || [];

    if (isCheked) {
      setSelectedFolders([...selectedFolders, ...[id, ...nestedFolders]]);

      setSelectedItems([...selectedItems, ...nestedObjs]);
      setExportItems([...selectedItems, ...nestedObjs]);
    } else {
      setSelectedFolders(selectedFolders.filter((c) => !nestedFolders.includes(c) && c !== id));
      setSelectedItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
      setExportItems(selectedItems.filter((c) => !nestedObjs.includes(c)));
    }
  };

  const selectItems = (folders, items) => {
    setSelectedFolders([...selectedFolders, ...folders]);
    setSelectedItems([...selectedItems, ...items]);
    setExportItems([...selectedFolders, ...items]);
  };

  const renderHeader = (h, i, arr) => {
    if (h === 'action' && (selectedItems.length > 0 && selectedFolders.length === 0)) {
      return (
        <th key={headers[h].title}>
          {!selectedFolders.length && (
            <ActionIcon
              id="exportSelected"
              icon="export"
              style={{ width: '20px', marginRight: '16px' }}
              hiddenFill
              onClick={() => exportSelected()}
              tooltip={{
                content: 'Export',
              }}
            />
          )}
          <ActionIcon
            id="deleteSelected"
            icon="trash"
            onClick={() => clickRemoveSelected()}
            tooltip={{
              content: 'Delete',
            }}
          />
        </th>
      );
    } if (h !== 'checkbox') {
      return (
        <th key={headers[h].title}>
          <span>{headers[h].title}</span>
          {' '}
          {i < arr.length - 1 && <SortIcon />}
        </th>
      );
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleDropRoot = (e) => {
    const destinationId = folderId || 0;
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'connections' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        {!folderId ? (
          <div className={shared.headerTitleGroup}>
            <h1 className={shared.headerTitle}>Connection List</h1>
            <p className={shared.headerDescription}>Manage connections</p>
          </div>
        ) : <Breadscrumbs folders={breadscrumbData} type="connections" />}
        <div className={shared.headerButtonGroup}>
          {inputVisible ? (
            <div ref={ref}>
              <Input
                placeholder="Search"
                iconLeft="search"
                autoFocus
                iconRight={searchValue && 'close'}
                handleChange={changeSearch}
                value={searchValue}
                handleAction={clear}
                style={{ width: '436px' }}
              />
            </div>
          ) : (
            <Button type="secondary" iconLeft="search" onClick={() => setImputVisible(true)} />
          )}
          <Button
            id="export_btn"
            type="secondary"
            iconLeft="export"
            onClick={() => (selectedItems.length > 0 && !selectedFolders.length) && exportSelected(true)}
            style={{ width: '36px', padding: '0px', opacity: (selectedItems.length > 0 && !selectedFolders.length) ? '1' : '0.4' }}
            tooltip={{
              content: 'Export',
            }}
          />
          <Button
            id="import_btn"
            type="secondary"
            iconLeft="import"
            onClick={importConnection}
            style={{ width: '36px', padding: '0px' }}
            tooltip={{
              content: 'Import',
            }}
          />
          <Button
            id="folder_btn"
            type="secondary"
            iconLeft="folder"
            onClick={createFolder}
            style={{ width: '36px', padding: '0px' }}
            tooltip={{
              content: 'Create folder',
            }}
          />
          {isRoleAction({ key: 'connection', role: 'create' })
            && <Button title="Create connection" onClick={createConnection} />}
        </div>
      </div>
      <div className={shared.table}>
        <table className={shared.connectionTable} onDrop={(e) => handleDropRoot(e)}>
          <thead>
            <tr className={shared.connectionTableRowConnections}>
              <th key="checkbox">
                <Checkbox
                  handleChange={selectAll}
                  size="small"
                  value={
                    (connectionsData?.length || foldersData?.length)
                    && checker(selectedItems, connectionsData)
                    && checker(selectedFolders, foldersData)
                  }
                  style={{ marginRight: '0' }}
                />
              </th>
              {Object.keys(headers).map((h, i, arr) => renderHeader(h, i, arr))}
            </tr>
          </thead>
          {(foldersData?.length || connectionsData?.length) && !loading ? (
            <tbody
              ref={tableRef}
              onDrop={(e) => handleDropRoot(e)}
              onDragOver={(e) => handleDragOver(e)}
              onClick={() => setContextMenuModelActive(false)}
            >
              <Directory
                foldersData={foldersData}
                connectionsData={connectionsData}
                selectedFolders={selectedFolders}
                selectedItems={selectedItems}
                handleContextMenu={handleContextMenu}
                setContextMenuModelActive={setContextMenuModelActive}
                openFolderAction={openFolderAction}
                selectOneFolder={selectOneFolder}
                selectItems={selectItems}
                contextMenuModelActive={contextMenuModelActive}
                contextMenuTop={contextMenuTop}
                contextMenuLeft={contextMenuLeft}
                modifyConnection={modifyConnection}
                selectOne={selectOne}
                renameFolder={renameFolder}
                clickShare={clickShare}
                removeFolder={removeFolder}
                isRoleAction={isRoleAction}
                exportOne={exportOne}
                clickRemove={clickRemove}
                copyCon={copyCon}
                getFolder={getFolder}
                moveFolder={moveFolder}
                moveObject={moveObject}
                activeProject={activeProject}
              />
            </tbody>
          ) : null}
        </table>
        {loading && (
        <div className={shared.emptyContent}>
          <Loader size="large" />
        </div>
        )}
        {(!connectionsData?.length && !foldersData?.length) && searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>No connections found</h3>
            <p>
              Please change your search query and try again
            </p>
          </div>
        )}
        {(!connectionsData?.length && !foldersData?.length) && !searchValue && !loading && (
          <div className={shared.emptyContent}>
            <h3 className={shared.caption}>This project has no Connections yet</h3>
            <p>
              The available connections for this project will be displayed here.
            </p>
            <div className={shared.action}>
              <ActionText
                title="Click here to create Connection"
                onClick={createConnection}
              />
            </div>
          </div>
        )}
        {createFolderModalOpened && (
          <CreateFolderModal
            setIsOpen={(status) => {
              setCreateFolderModalOpened(status);
            }}
            parent={Number(folderId)}
            activeProject={activeProject}
            type="connections"
          />
        )}
        {renameFolderModalOpened && (
          <RenameFolderModal
            setIsOpen={(status) => {
              setRenameFolderModalOpened(status);
            }}
            model={activeModel}
            activeProject={activeProject}
            type="connections"
          />
        )}
        {removeFolderModalOpened && (
          <RemoveFolderModal
            setIsOpen={(status) => {
              setRemoveFolderModalOpened(status);
            }}
            model={activeModel}
            activeProject={activeProject}
            type="connections"
          />
        )}
        {removeModalOpened && (
          <RemoveConnectionModal
            setIsOpen={(status, clearSelected) => {
              setRemoveModalOpened(status);
              clearSelected && setSelectedItems([]);
            }}
            connection={activeModel}
            connections={connectionsData}
          />
        )}
        {shareModalOpened && (
          <InviteToResourceModal
            id={activeModel.id}
            onClose={() => setShareModalOpened(false)}
            onSubmit={sendInviteAction}
            model={activeModel}
            type={activeModel?.type === 'connections' ? 'folder' : 'connection'}
            inviteList={[]}
          />
        )}
        {exportModalOpened && (
          <ExportModal
            onClose={() => {
              setExportModalOpened(false);
              setExportItems(selectedItems);
            }}
            onSubmit={exportC}
            list={exportItems}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  connections, activeProject, auth: { currentUser }, roles,
}) => ({
  activeProject,
  currentUser,
  roles,
  connections,
});

const mapDispatchToProps = (dispatch) => ({
  getFolder: (data, callback) => dispatch(getConnectionFolderContentRequest(data, callback)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  copyConnection: (data, callback) => dispatch(copyConnectionRequest(data, callback)),
  exportConnection: (data, callback) => dispatch(exportConnectionRequest(data, callback)),
  searchConnections: (data) => dispatch(searchConnectionsRequest(data)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  moveConnectionFolder: (data, callback) => dispatch(moveConnectionFolderRequest(data, callback)),
  moveConnection: (data, callback) => dispatch(moveConnectionRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ConnectionList));
