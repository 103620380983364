class Incrementor {
  constructor() {
    this.uid = 0;
  }

  genearateUid() {
    this.uid += 1;
    return this.uid;
  }
}

export default Incrementor;
