export const backendHost = process.env.REACT_APP_BACKEND_HOST || `${window.location.origin}/api`;

export const kaptchaKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const loadStatus = {
  INITIAL: 'INITIAL',
  LOAD: 'LOAD',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const typesAPI = {
  postgres: 'PostgreSQL',
  mysql: 'MySQL',
  mssql: 'MsSQL',
  corezoid: 'Corezoid',
  mongo: 'MongoDB',
  oracle: 'Oracle',
  AWS: 'AWS S3',
  chatGPT: 'ChatGPT',
};

export const listRolesName = {
  owner: 'Owner',
  editor: 'Editor',
  viewer: 'Viewer',
};

export const canSelectRole = {
  owner: ['owner', 'editor', 'viewer'],
  // owner: ['editor', 'viewer'],
  editor: ['editor', 'viewer'],
  viewer: ['viewer'],
}
