/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { updateEndpointFolderRequest } from '../../store/endpoints/actions';
import { updateConnectionFolderRequest } from '../../store/connections/actions';
import Input from '../Input/Input';
import Button from '../Button/Button';
import * as styles from './RenameFolderModal.module.scss';

const maxAllowedLength = 64;

const UpdateFolderModalSchema = Yup.object().shape({
  name: Yup.string().min(2).max(64).required('Name required'),
});

function RenameFolderModal({
  setIsOpen, updateEndpointFolder, updateConnectionFolder,
  type = 'endpoints', model,
  activeProject
}) {
  const [loading, setLoading] = useState(false);

  const {
    submitForm, setFieldValue, values, errors, setTouched,
  } = useFormik({
    initialValues: {
      name: model?.name || '',
      initName: model?.name || '',
    },
    validationSchema: UpdateFolderModalSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      setLoading(true);
      if (type === 'endpoints') {
        updateEndpointFolder({
          ...model,
          name: v.name,
          projectId: activeProject?.id,
        }, () => {
          setIsOpen(false);
        });
      } else {
        updateConnectionFolder({
          ...model,
          name: v.name,
          projectId: activeProject?.id,
        }, () => {
          setIsOpen(false);
        });
      }
    },
  });

  const handleChangeFolderName = ({ target }) => {
    const regex = /^(?!\s)(?!.* {2})/;
    if (regex.test(target.value) && target.value.length <= maxAllowedLength) {
      setFieldValue(target.name, target.value);
    }
    setTouched('name');
  };

  const renderMainModal = () => (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <div className={styles.title}>Rename folder</div>
      </div>
      <div className={styles.body}>
        <div className={styles.mainContent}>
          <Input
            style={{ width: '100%' }}
            placeholder="Enter folder name"
            handleChange={handleChangeFolderName}
            value={values.name}
            name="name"
            autoFocus
            error={!!errors.name && values.name?.length < 2}
          />
        </div>
        <div className={styles.buttonGroupCol}>
          <Button
            style={{ width: '85px' }}
            title="Cancel"
            type="secondary"
            onClick={() => setIsOpen(false)}
          />
          <Button
            style={{ width: '154px', marginLeft: '12px' }}
            disabled={values.name === values.initName || loading}
            title="Rename"
            onClick={() => submitForm()}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.modal}>
      {renderMainModal()}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateEndpointFolder: (data, callback) => dispatch(updateEndpointFolderRequest(data, callback)),
  updateConnectionFolder: (data, callback) => dispatch(updateConnectionFolderRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(RenameFolderModal);
