import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutRequest } from '../../store/auth/actions';
import ActionIcon from '../ActionIcon/ActionIcon';
import Logout from '../../pictures/logout.svg';
import avatar from '../../pictures/default-avatar.webp';
import * as style from './UserSelection.module.scss';

const items = [
  {
    key: 'logout',
    title: 'Logout',
    icon: Logout,
  },
];

function PopUpMenu({ handleChange, handleClickMenu }) {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      handleChange(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={style.popupMenu} ref={ref}>
      <ul>
        {items.map(({ key, title, icon: Icon }) => (
          <li key={key} role="button" onClick={() => handleClickMenu(key)}>
            <div className={style.icon}>
              <Icon />
            </div>
            {title}
          </li>
        ))}
      </ul>
    </div>
  );
}

PopUpMenu.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
};

function UserSelection({ currentUser, logout }) {
  const [menuOpened, setMenuOpened] = useState(false);
  const { firstName, lastName, email } = currentUser || {};

  const navigate = useNavigate();
  const menuClickAction = (item) => {
    if (item === 'logout') {
      setMenuOpened(false);
      logout(() => navigate('/login'));
    }
  };
  return (
    <div className={style.projectSelection}>
      <div className={style.group}>
        <div className={style.iconGroup}>
          <div className={style.icon}>
            <img className={style.logo} src={avatar} alt="users avatar" />
          </div>
          <div className={style.titleGroup}>
            <div className={style.heading}>{email}</div>
            <div className={style.title}>{`${firstName} ${lastName}`}</div>
          </div>
        </div>
        <div className={style.actionGroup}>
          <ActionIcon
            icon="options"
            onClick={() => setMenuOpened(!menuOpened)}
            active={menuOpened}
          />
        </div>
      </div>
      {menuOpened && (
        <PopUpMenu
          handleChange={setMenuOpened}
          handleClickMenu={menuClickAction}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (callback) => dispatch(logoutRequest(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSelection);
