/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { removeEndpointFolderRequest } from '../../store/endpoints/actions';
import { removeConnectionFolderRequest } from '../../store/connections/actions';
import Button from '../Button/Button';
import * as styles from './RemoveFolderModal.module.scss';

function RemoveFolderModal({
  removeEndpointsFolder,
  removeConnectionsFolder,
  model,
  setIsOpen,
  type = 'endpoints',
  activeProject
}) {
  const [loading, setLoading] = useState(false);
  const handleRemove = () => {
    setLoading(true);
    if (type === 'endpoints') {
      removeEndpointsFolder({ ...model, projectId: activeProject?.id }, () => {
        setIsOpen(false);
      });
    } else {
      removeConnectionsFolder({ ...model, projectId: activeProject?.id }, () => {
        setIsOpen(false);
      });
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove Folder</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove
              {' '}
              <span className={styles.modelTitle}>
                {model.name}
              </span>
              ?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              style={{ width: '85px' }}
              title="Close"
              type="secondary"
              onClick={() => setIsOpen(false)}
            />
            <Button
              style={{ width: '120px', marginLeft: '12px' }}
              type="red"
              title="Remove"
              disabled={loading}
              onClick={handleRemove}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  removeConnectionsFolder: (data, callback) => dispatch(removeConnectionFolderRequest(data, callback)),
  removeEndpointsFolder: (data, callback) => dispatch(removeEndpointFolderRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(RemoveFolderModal);
