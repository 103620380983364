import { ActiveUser } from './constants';

const initialState = null;

export const activeUserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveUser.activeUserSuccess:
      return {
        ...state,
        ...action.data
      };
    case ActiveUser.userAccessSuccess:
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
};
