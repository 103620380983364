/* eslint-disable max-len */
import { Users } from './constants';

export const listUsersRequest = (data, callback) => ({ type: Users.listUsersRequest, data, callback });
export const listUsersSuccess = (data) => ({ type: Users.listUsersSuccess, data });
export const listUsersError = () => ({ type: Users.listUsersError });

export const searchUsersRequest = (data) => ({ type: Users.searchUsersRequest, data });
export const searchUsersSuccess = (data) => ({ type: Users.searchUsersSuccess, data });
export const searchUsersError = () => ({ type: Users.searchUsersError });

export const sendInviteRequest = (data, callback) => ({ type: Users.sendInviteRequest, data, callback });
export const sendInviteSuccess = (data) => ({ type: Users.sendInviteSuccess, data });
export const sendInviteError = () => ({ type: Users.sendInviteError });

export const getAccessUsersRequest = (data, callback) => ({ type: Users.getAccessUsersRequest, data, callback });
export const getAccessUsersSuccess = (data) => ({ type: Users.getAccessUsersSuccess, data });
export const getAccessUsersError = () => ({ type: Users.getAccessUsersError });

export const removeUserRequest = (data, callback) => ({ type: Users.removeUserRequest, data, callback });
export const removeUserSuccess = (data) => ({ type: Users.removeUserSuccess, data });
export const removeUserError = () => ({ type: Users.removeUserError });

export const modifyUserRequest = (data, callback) => ({ type: Users.modifyUserRequest, data, callback });
export const modifyUserSuccess = (data) => ({ type: Users.modifyUserSuccess, data });
export const modifyUserError = () => ({ type: Users.modifyUserError });
