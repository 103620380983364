/* eslint-disable import/prefer-default-export */
export const ActiveUser = {
  activeUserRequest: 'ACTIVE_USER_PENDING',
  activeUserSuccess: 'ACTIVE_USER_SUCCESS',
  activeUserError: 'ACTIVE_USER_ERROR',

  userAccessRequest: 'USER_ACCESS_PENDING',
  userAccessSuccess: 'USER_ACCESS_SUCCESS',
  userAccessError: 'USER_ACCESS_ERROR',
};
