/* eslint-disable import/prefer-default-export */
export const Users = {
  listUsersRequest: 'LIST_USERS_PENDING',
  listUsersSuccess: 'LIST_USERS_SUCCESS',
  listUsersError: 'LIST_USERS_ERROR',

  searchUsersRequest: 'SEARCH_USERS_PENDING',
  searchUsersSuccess: 'SEARCH_USERS_SUCCESS',
  searchUsersError: 'SEARCH_USERS_ERROR',

  sendInviteRequest: 'SEND_INVITE_PENDING',
  sendInviteSuccess: 'SEND_INVITE_SUCCESS',
  sendInviteError: 'SEND_INVITE_ERROR',

  getAccessUsersRequest: 'GET_ACCESS_USER_PENDING',
  getAccessUsersSuccess: 'GET_ACCESS_USER_SUCCESS',
  getAccessUsersError: 'GET_ACCESS_USER_ERROR',

  removeUserRequest: 'REMOVE_USER_PENDING',
  removeUserSuccess: 'REMOVE_USER_SUCCESS',
  removeUserError: 'REMOVE_USER_ERROR',

  modifyUserRequest: 'MODIFY_USER_PENDING',
  modifyUserSuccess: 'MODIFY_USER_SUCCESS',
  modifyUserError: 'MODIFY_USER_ERROR',
};
