/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ActionIcon from '../ActionIcon';
import * as styles from './ChipsAction.module.scss';

function ChipsAction({
  title,
  type = 'email',
  style = {},
  onClose,
}) {
  return (
    <div
      className={cn(
        styles.chipsAction,
        {
          [styles.name]: type === 'name',
        },
      )}
      style={style}
    >
      <span className={styles.nested}>{title}</span>
      <ActionIcon
        icon="close"
        onClick={() => onClose(title)}
      />
    </div>
  );
}

ChipsAction.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['email', 'name']),
  style: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ChipsAction;
