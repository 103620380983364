import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import ActionText from '../../components/ActionText/ActionText';
import Select from '../../components/Select/Select';
import RemoveUserModal from '../../components/RemoveUserModal/RemoveUserModal';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import AddConnectionToUserModal from '../../components/AddConnectionToUserModal/AddConnectionToUserModal';
import AddToEndpointUserModal from '../../components/AddToEndpointUserModal/AddToEndpointUserModal';
import DBIcon from '../../components/DBIcon/DBIcon';
import { typesAPI, listRolesName } from '../../helpers/const';
import { getProjectRoles } from '../../helpers/utils';
import { activeUserRequest, userAccessRequest } from '../../store/activeUser/actions';
import { modifyUserRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { isRoleAction } from '../../helpers/hocs';

import * as shared from '../../styles/shared.module.scss';

const UsersModifySchema = Yup.object().shape({
  firstName: Yup.string().required('Name required'),
  email: Yup.string().email('noValid').required('Email required'),
});

function UsersModify({
  roles,
  activeUser,
  getActiveUser,
  currentUser,
  modifyUser,
  activeProject,
  getUserAccess,
  addAccess,
  isRoleAction
}) {
  const navigate = useNavigate();
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [removeAccessModalOpened, setRemoveAccessModalOpened] = useState(false);
  const [addConnectionModalOpened, setAddConnectionModalOpened] = useState(false);
  const [addEndpointModalOpened, setAddEndpointModalOpened] = useState(false);
  const { shortName, resourceId } = useParams();
  const [connectionsList, setConnectionsList] = useState([]);
  const [endpointsList, setEndpointsList] = useState([]);
  const [activeItem, setActiveItem] = useState([]);
  const listUsers = () => navigate(`/${shortName}/users`);
  const [isChange, setIsChange] = useState(false);

  const addApiAccess = () => {
    setAddEndpointModalOpened(true)
  }

  const addConnectionAccess = () => {
    setAddConnectionModalOpened(true)
  }

  const saveConnectionsList = (data) => {
    setAddConnectionModalOpened(false);
    getUserAccess({ id: resourceId, projectId: activeProject.id })
  };

  const saveEndpointsList = (data) => {
    setAddEndpointModalOpened(false);
    getUserAccess({ id: resourceId, projectId: activeProject.id })
  };

  const getRoles = (resource) => {
    const listRoles = [];
    const filredRoles = getProjectRoles(roles, activeProject.id).filter(r => r.name !== 'owner');
    if (isRoleAction({ key: resource, role: 'share' })) {
      listRoles.push(...filredRoles.map(r => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      listRoles.push(...filredRoles.map(r => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: resource, role: 'delete' })) {
      listRoles.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer' });
    }
    return listRoles;
  }

  useEffect(() => {
    setConnectionsList(activeUser?.connections || [])
    setEndpointsList(activeUser?.endpoints || [])
  }, [activeUser?.connections, activeUser?.endpoints])

  const {
    submitForm, handleChange, setFieldValue, values, setValues, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      firstName: '',
      email: '',
      status: 'active',
      lastName: '',
      role: 'admin',
    },
    validationSchema: UsersModifySchema,
    onSubmit: (v) => {
      console.log('currentUser.admin', currentUser, activeUser);
      // admin modify
      if (currentUser.admin) {
        modifyUser({
          id: activeUser.id === currentUser.id ? 'me' : activeUser.id,
          projectId: activeProject.id,
          first_name: v.firstName,
          last_name: v.lastName,
          email: v.email,
          role: v.role
        }, listUsers)
      } else {
        // change role
        addAccess({
          email: v.email,
          userId: activeUser.id,
          role: v.role,
          id: activeProject.id,
          resource: 'project',
        }, listUsers)
      }
    },
  });

  const handleChangeProxy = (e) => {
    setIsChange(true);
    handleChange(e)
  }

  const setFieldValueProxy = (...arg) => {
    setIsChange(true);
    setFieldValue(...arg)
  }

  const deleteUser = () => {
    setRemoveModalOpened(true);
  }

  const deleteAccess = (item) => {
    if (activeItem.type === 'connection') {
      const newConnectionsList = connectionsList.filter((el) => el.id !== activeItem.id);
      setConnectionsList(newConnectionsList);
      setRemoveAccessModalOpened(false);
      setActiveItem({})
    }
    if (activeItem.type === 'endpoint') {
      const newEndpointsList = endpointsList.filter((el) => el.id !== activeItem.id);
      setEndpointsList(newEndpointsList);
      setRemoveAccessModalOpened(false);
      setActiveItem({})
    }
  }

  useEffect(() => {
    getActiveUser({ id: resourceId, projectId: activeProject.id })
    getUserAccess({ id: resourceId, projectId: activeProject.id })
  }, [resourceId]);

  const handeChangeRole = (item, role, type) => {
    if (role === 'delete') {
      setRemoveAccessModalOpened(true);
      setActiveItem({ ...item, type })
      return;
    }

    addAccess({
      id: +item.id,
      resource: type,
      projectId: activeProject.id,
      role: role,
      userId: activeUser.id,
    }, () => {
      if (type === 'connection') {
        const newConnectionsList = connectionsList.map((el) => (el.id === item.id ? { ...el, role: role } : el));
        setConnectionsList(newConnectionsList);
      }
      if (type === 'endpoint') {
        const newEndpointsList = endpointsList.map((el) => (el.id === item.id ? { ...el, role: role } : el));
        setEndpointsList(newEndpointsList);
      }
    })
  };

  useEffect(() => {
    if (activeUser) {
      const {
        email, firstName, lastName, status, role
      } = activeUser || {};
      setValues({
        firstName,
        email,
        status: status || 'active',
        lastName,
        role: role || 'admin',
      });
    }
  }, [activeUser]);

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Edit user</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          {activeUser?.id !== currentUser.id &&
            <>
              <ActionText style={{ color: '#E4315F' }} title="Delete user" onClick={deleteUser} />
              <div className={shared.verticalLine} />
            </>
          }
          <Button title="Cancel" type="secondary" onClick={listUsers} />
          <Button title="Save user" onClick={() => submitForm()} disabled={!isChange}/>
        </div>
      </div>
      <div className={cn(shared.body, shared.vertical)}>
        <div className={cn(shared.mainContent, shared.mainContentUsers)}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>User details</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Email</span>
                <Input
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  handleChange={handleChangeProxy}
                  disabled={!currentUser.admin}
                  error={!!errors.email && (!values.email || values.email !== 'noValid')}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>First name</span>
                <Input
                  placeholder="Enter first name"
                  name="firstName"
                  value={values.firstName}
                  handleChange={handleChangeProxy}
                  disabled={!currentUser.admin}
                  error={!!errors.firstName && !values.firstName}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Last name</span>
                <Input
                  placeholder="Enter last name"
                  name="lastName"
                  value={values.lastName}
                  handleChange={handleChangeProxy}
                  disabled={!currentUser.admin}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Role</span>
                {activeUser?.role === 'owner' ? 
                  <Select
                    list={getProjectRoles(roles, activeProject.id).map(r => ({ value: r.name, title: listRolesName[r.name] || r.name }))}
                    name="role"
                    value={values.role}
                    handleChange={setFieldValueProxy}
                    disabled={true}
                  /> :
                  <Select
                    list={getProjectRoles(roles, activeProject.id).filter(r => r.name !== 'owner').map(r => ({ value: r.name, title: listRolesName[r.name] || r.name }))}
                    name="role"
                    value={values.role}
                    handleChange={setFieldValueProxy}
                    disabled={!isRoleAction({ key: 'project', role: 'update' })}
                  />
                }
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Status</span>
                <Select
                  list={[
                    { value: 'active', title: 'Active' },
                    { value: 'inactive', title: 'Inactive' },
                  ]}
                  name="status"
                  value={values.status}
                  disabled
                  handleChange={setFieldValueProxy}
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.bottomPanel}>
          <section className={shared.section}>
            <div className={shared.sidepanelHeading}>
              <h4 className={shared.sectionHeader}>
                <span>API accesses</span>
                <span>{`(${endpointsList?.length || 0})`}</span>
              </h4>
              {endpointsList.length > 0 && <ActionText title="Add API access" onClick={addApiAccess} />}
            </div>
            {endpointsList.length > 0 ? (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.line}>
                    <div>Name</div>
                    <div>Method</div>
                    <div>Role</div>
                  </div>
                </div>
                <hr />
                <ul className={shared.sidepanelTableBody}>
                  {endpointsList?.map((item) => (
                    <li className={shared.line} key={item.id}>
                      <div title={item.name}>
                        <a href={`/${shortName}/endpoints/${item.id}`} target={'_blank'}>
                          {item.name}
                        </a>
                      </div>
                      <div className={shared.uppercased}>
                        {item.method}
                      </div>
                      <div>
                        {item.role === 'owner' ? (
                          <Select
                            list={[{ value: 'owner', title: 'Owner' }]}
                            value={item.role}
                            handleChange={() => { }}
                            name={item.email}
                            disabled={item.role === 'owner'}
                          />
                        ) : (
                          <Select
                            list={getRoles('endpoint')}
                            value={item.role}
                            handleChange={(email, value) => handeChangeRole(item, value, 'endpoint')}
                            name={item.email}
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className={shared.table}>
                <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                  <h3 className={shared.caption}>This user doesn't have APIs yet.</h3>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>Share</p>
                    <div style={{ margin: '0 4px' }}>
                      <ActionText
                        title="API"
                        onClick={addApiAccess}
                      />
                    </div>
                    <p>with this user or let him create a new one.</p>
                  </div>
                </div>
              </div>
            )}
          </section>
          <div className={shared.vertical} />
          <section className={shared.section}>
            <div className={shared.sidepanelHeading}>
              <h4 className={shared.sectionHeader}>
                <span>Connections accesses</span>
                <span>{`(${connectionsList?.length || 0})`}</span>
              </h4>
              {connectionsList.length > 0 && <ActionText title="Add Connection access" onClick={addConnectionAccess} />}
            </div>
            {connectionsList.length > 0 ? (
              <div className={shared.sidepanelTable}>
                <div className={shared.sidepanelTableHeading}>
                  <div className={shared.line}>
                    <div>Name</div>
                    <div>Type</div>
                    <div>Role</div>
                  </div>
                </div>
                <hr />
                <ul className={shared.sidepanelTableBody}>
                  {connectionsList.map((item) => (
                    <li className={shared.line} key={item.id}>
                      <div title={item.name}>
                        <a href={`/${shortName}/connections/${item.id}`} target={'_blank'}>
                          {item.instanceName}
                        </a>
                      </div>
                      <div>
                        <DBIcon type={item.type} style={{ marginRight: '12px' }} />
                        <span>{typesAPI[item.type]}</span>
                      </div>
                      <div>
                        {item.role === 'owner' ? (
                          <Select
                            list={[{ value: 'owner', title: 'Owner' }]}
                            value={item.role}
                            handleChange={() => { }}
                            name={item.email}
                            disabled={item.role === 'owner'}
                          />
                        ) : (
                          <Select
                            list={getRoles('connection')}
                            value={item.role}
                            handleChange={(email, value) => handeChangeRole(item, value, 'connection')}
                            name={item.email}
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className={shared.table}>
                <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                  <h3 className={shared.caption}>This user doesn't have Connections yet.</h3>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>Share</p>
                    <div style={{ margin: '0 4px' }}>
                      <ActionText
                        title="Connections"
                        onClick={addConnectionAccess}
                      />
                    </div>
                    <p>with this user or let him create a new one.</p>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div >
      </div >
      {removeModalOpened && (
        <RemoveUserModal
          setIsOpen={setRemoveModalOpened}
          deleteUser={listUsers}
          user={{
            id: activeProject.id,
            userId: resourceId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email
          }}
        />
      )}
      {removeAccessModalOpened && (
        <RemoveAccessModal
          setIsOpen={setRemoveAccessModalOpened}
          deleteAccessUser={deleteAccess}
          user={activeUser}
          resource={activeItem.type}
          id={activeItem.id}
        />
      )}
      {addEndpointModalOpened && (
        <AddToEndpointUserModal
          onClose={() => setAddEndpointModalOpened(false)}
          onSubmit={saveEndpointsList}
          activeUser={activeUser}
          inviteList={connectionsList.map(i => i.id) || []}
        />
      )}
      {addConnectionModalOpened && (
        <AddConnectionToUserModal
          onClose={() => setAddConnectionModalOpened(false)}
          onSubmit={saveConnectionsList}
          activeUser={activeUser}
          inviteList={connectionsList.map(i => i.id) || []}
        />
      )}
    </div >
  );
}

const mapStateToProps = ({ roles, activeUser, auth: { currentUser }, activeProject }) => ({
  roles,
  activeUser,
  currentUser,
  activeProject
});

const mapDispatchToProps = (dispatch) => ({
  getActiveUser: (data) => dispatch(activeUserRequest(data)),
  getUserAccess: (data) => dispatch(userAccessRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  modifyUser: (data, callback) => dispatch(modifyUserRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(UsersModify));
