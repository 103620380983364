/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import * as styles from './ImportEndpointModal.module.scss';
import Checkbox from '../Checkbox/Checkbox';

function ImportEndpointModal({
  onClose,
  onSubmit,
  list
}) {
  const [linkApi, setlinkApi] = useState(false);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Import APIs</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              <div>
                <span className={styles.modelTitle}>
                  {list.length}{' '}{list.length > 1 ? 'APIs' : 'API'}
                </span>
                {' '} were selected for import.
              </div>
              <div>
                <span className={styles.modelTitle}>
                  {list.filter(item => item.status === 'conflicted').length}{' '}
                  {list.filter(item => item.status === 'conflicted').length > 1 ? 'APIs' : 'API'}
                </span>
                {' '} have conflicts.
              </div>
              Once you import APIs, they will be overrided with the new instances.
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <div className={styles.buttonGroup}>
              <Checkbox handleChange={setlinkApi} label={<div>Import linked <b>Connections</b></div>} size='small' value={linkApi}/>
            </div>
            <div className={styles.buttonGroup}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
              <Button
                style={{ width: 200, marginLeft: 12 }}
                title="Import APIs"
                onClick={() => {
                  onSubmit(linkApi)
                  onClose()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportEndpointModal;
