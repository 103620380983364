@use "sass:map";
@import '../../styles/variables.scss';

.breaadscrumbContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 32px;
    font-size: 14px;
    line-height: 38px;
    color: map.get($colors-basic, "textBlack");
}

.breadscrumbItem {
    position: relative;
}

.arrow {
    width: 24px;
    height: 24px;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
}