/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Search from '../../pictures/search.svg';
import Close from '../../pictures/close.svg';
import Copy from '../../pictures/copy.svg';
import Eye from '../../pictures/eye.svg';
import EyeNot from '../../pictures/eyeNot.svg';
import * as style from './Input.module.scss';

const iconEnum = {
  search: Search,
  close: Close,
  copy: Copy,
};

const rightType = {
  action: 'action',
  status: 'status',
};

const sizeEnum = {
  M: 'M',
  L: 'L',
};

const typeEnum = {
  text: 'text',
  primary: 'primary',
  secondary: 'secondary',
};

function Input({
  isPassword,
  name,
  value,
  type = 'text',
  handleAction,
  handleChange,
  onBlur = () => {},
  handleKeyDown = () => {},
  placeholder,
  iconRight,
  iconRightType = rightType.action,
  iconLeft,
  disabled,
  style: customStyle = {},
  size,
  error,
  id,
  autoFocus = false,
  multiline,
}) {
  const getRightIcon = (icon) => {
    if (icon && typeof icon === 'string') return iconEnum[icon];
    if (icon && typeof icon !== 'string') return icon;
    return null;
  };
  const [showPassword, setShowPassword] = useState(false);
  const RightIcon = getRightIcon(iconRight);
  const PasswordTrue = showPassword ? EyeNot : Eye;
  const LeftIcon = iconLeft && iconEnum[iconLeft];

  return (
    <div className={style.inputWrapper}>
      {multiline ? (
        <textarea
          rows={5}
          id={id}
          key={name}
          style={{
            resize: 'none',
            ...customStyle,
          }}
          className={cn(
            style.input,
            {
              [style.leftPadding]: !!iconLeft,
              [style.rightPadding]: !!iconRight,
              [style.filled]: !!value,
              [style.error]: error,
            },
          )}
          placeholder={placeholder || ''}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
        />
      ) : (
        <input
          id={id}
          key={name}
          style={isPassword ? {
            ...customStyle,
            paddingRight: isPassword && RightIcon ? '68px' : '36px',
          } : {
            ...customStyle,
          }}
          autoFocus={autoFocus}
          className={cn(
            style.input,
            {
              [style.leftPadding]: !!iconLeft,
              [style.rightPadding]: !!iconRight,
              [style.filled]: !!value,
              [style.error]: error,
            },
          )}
          type={showPassword && type === 'password' ? 'text' : type}
          placeholder={placeholder || ''}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
        />
      )}
      {iconLeft && (
        <div className={style.iconLeft}>
          <LeftIcon />
        </div>
      )}
      {isPassword && (
        <div
          className={style.isPassword}
          style={{ right: RightIcon ? '41px' : '11px' }}
          role="button"
          onClick={() => setShowPassword(!showPassword)}
        >
          <PasswordTrue />
        </div>
      )}
      {iconRight && iconRightType === rightType.action && (
        <div className={style.iconRight} role="button" onClick={handleAction} onKeyDown={handleAction}>
          <RightIcon />
        </div>
      )}
      {iconRight && iconRightType === rightType.status && (
        <div className={style.iconRightStatus}>
          <RightIcon />
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleAction: PropTypes.func,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  iconRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
  ]),
  iconLeft: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.bool,
};

export default Input;
