import { all, fork } from 'redux-saga/effects';
import projectsSaga from './projects/saga';
import endpointsSaga from './endpoints/saga';
import historySaga from './history/saga';
import connectionsSaga from './connections/saga';
import usersSaga from './users/saga';
import rolesSaga from './roles/saga';
import authSaga from './auth/saga';
import toastsSaga from './toasts/saga';
import activeConnectionSaga from './activeConnection/saga';
import activeEndpointSaga from './activeEndpoint/saga';
import activeUserSaga from './activeUser/saga';
import activeProjectSaga from './activeProject/saga';
import invitesSaga from './invites/saga';
import directorySaga from './directory/saga';
import errorsSaga from './errors/saga';

// eslint-disable-next-line func-names
const rootSaga = function* () {
  yield all([
    fork(projectsSaga),
    fork(endpointsSaga),
    fork(connectionsSaga),
    fork(usersSaga),
    fork(rolesSaga),
    fork(authSaga),
    fork(toastsSaga),
    fork(activeConnectionSaga),
    fork(activeEndpointSaga),
    fork(activeUserSaga),
    fork(activeProjectSaga),
    fork(invitesSaga),
    fork(directorySaga),
    fork(historySaga),
    fork(errorsSaga),
  ]);
};

export default rootSaga;
