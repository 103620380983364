/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { connect } from 'react-redux';
import { removeEndpointRequest, removeEndpointsListRequest } from '../../store/endpoints/actions';
import { createToastRequest } from '../../store/toasts/actions';
import Button from '../Button/Button';
import * as styles from './RemoveEndpointModal.module.scss';

function RemoveEndpointModal({ removeEndpoint, removeEndpointsList, endpoint, endpoints, setIsOpen, createToast }) {
  const handleRemove = () => {
    removeEndpoint({ ...endpoint }, () => {
      setIsOpen(false, true);
    });
  };

  const handleRemoveList = () => {
    removeEndpointsList({ items: endpoint }, ({ errors, successList }) => {
      setIsOpen(false, true);
      if (errors && errors.length > 0) {
        errors.forEach(e => {
          createToast({ type: 'error', text: e.error })
        })
      }
      if (successList && successList.length > 0) {
        successList.forEach(id => {
          const name = endpoints.find(e => e.id === id)?.name || '';
          createToast({ type: 'success', title: `API ${name} `, text: 'has been delete successfully' })

        })
      }
    });
  };

  if (Array.isArray(endpoint)) {
    return (
      <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove APIs</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove
              {' '}
              <span className={styles.modelTitle}>
                {endpoint.length}
                {' '}
                {endpoint.length > 1 ? 'APIs' : 'API'}
              </span>
              ?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '120px', marginLeft: '12px' }} type="red" title="Remove" onClick={handleRemoveList} />
          </div>
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove API</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove
              {' '}
              <span className={styles.modelTitle}>
                {endpoint.path}
              </span>
              {' '}
              endpoint
              ?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '120px', marginLeft: '12px' }} type="red" title="Remove" onClick={handleRemove} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  removeEndpoint: (data, callback) => dispatch(removeEndpointRequest(data, callback)),
  removeEndpointsList: (data, callback) => dispatch(removeEndpointsListRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data))
});

export default connect(null, mapDispatchToProps)(RemoveEndpointModal);
