@use "sass:map";
@import '../../styles/variables.scss';

.chipsStatus {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid map.get($colors-green, 70);
    background-color: map.get($colors-green, 30);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 32px;

    span.nested {
        margin: 0;
    }

    &.error {
        border: 1px solid map.get($colors-red, 100);
        background-color: map.get($colors-red, 30);
    }

    &.user {
        border: 1px solid map.get($colors-peach, 70);
        background-color: map.get($colors-peach, 30);
    }

    &.invite, &.draft {
        border: 1px solid map.get($colors-blue, 70);
        background-color: map.get($colors-blue, 30);
    }

    &.input {
        border: 1px solid map.get($colors-basic, "inputGrey");
        background-color: map.get($colors-basic, "inputGrey");
    }
}