import React, { useState, useEffect } from 'react';
import { ParamentRow } from '../ParametrRow/ParamentRow';
import { debounce } from '../../helpers/utils';

export function ParamentRows({
  params,
  errors,
  viewRead,
  parameterType,
  typesList,
  handleUpdate,
  updateRequestExample,
  requestExample
}) {
  const [list, setList] = useState(params);

  useEffect(() => {
    params && setList(params);
  }, [params]);

  const removeParameter = (parameter) => {
    const newParams = list.filter((el) => el.id !== parameter.id);
    setList(newParams);
    debounce('handleChangeProxy', () => {
      handleUpdate('params', newParams);
    }, 500);
  };

  const handleChangeProxy = (e) => {
    const { value, name } = e.target;
    const [field, idX] = name.split('.');
    const newParams = list.map((el) => (el.id === idX ? (({
      ...el,
      [field]: value,
    })) : el));

    setList(newParams);
    debounce('handleChangeProxy', () => {
      handleUpdate('params', newParams);
      updateRequestExample(field, value, idX);
    }, 500);
  };

  const formatValue = (type, value) => {
    switch (type) {
      case 'string':
        return String(value);
      case 'number':
        return !Number.isNaN(Number(value)) ? Number(value) : String(value);
      default:
        return value;
    }
  };

  const moveParam = (dragIndex, hoverIndex) => {
    const swap = (arr = [], start = 0, end = 0) => {
      const newArr = arr.slice(0);
      const startItem = arr[start];
      const endItem = arr[end];
      newArr[end] = startItem;
      newArr[start] = endItem;
      return newArr;
    };
    const newList = swap(list, dragIndex, hoverIndex);
    setList(newList);
    debounce('moveParam', () => {
      handleUpdate('params', newList);
    }, 500);
  };

  const applyTypeOnChange = (field, index, newType) => {
    let parsed;
    let value;
    try {
      parsed = JSON.parse(requestExample);
    } catch (e) {
      console.log('failed to parse JSON from input');
    }
    if (parsed) {
      value = formatValue(newType, parsed[field]);

      debounce('handleChangeProxy', () => {
        updateRequestExample(field, value, index);
      }, 500);
    }
  };

  const setFieldValueProxy = (f, value) => {
    const [field, idX] = f.split('.');
    const newP = params.find((el) => el.id === idX);
    const dV = {
      string: '',
      number: 0,
      object: {},
      array: [],
    };
    let defaultValue = '';
    const isObject = (A) => {
      if ((typeof A === 'object' || typeof A === 'function') && (A !== null)) {
        return true;
      }
      return false;
    };
    if (
      (
        !newP?.defaultValue
        || newP?.defaultValue && newP?.defaultValue.length < 1
        || isObject(newP?.defaultValue) && Object.keys(newP?.defaultValue).length < 1
      )
      && field === 'type'
    ) {
      defaultValue = dV[value];
    }

    let newParams = [];
    if (defaultValue || defaultValue === 0) {
      newParams = params.map((el, i) => (el.id === idX ? (({
        ...el,
        [field]: value,
        defaultValue,
      })) : el));
    } else {
      newParams = params.map((el, i) => (el.id === idX ? (({
        ...el,
        [field]: value,
      })) : el));
    }

    setList(newParams);
    debounce('setFieldValueProxy', () => {
      handleUpdate('params', newParams);
      if (field === 'type') applyTypeOnChange(newP.name, idX, value);
    }, 500);
  };

  return (
    <>
      {list.map((param, i) => ((
        <ParamentRow
          key={param.id}
          index={i}
          id={param.id}
          param={param}
          errors={errors}
          viewRead={viewRead}
          parameterType={parameterType}
          moveParam={moveParam}
          removeParameter={removeParameter}
          handleChangeProxy={handleChangeProxy}
          setFieldValueProxy={setFieldValueProxy}
          updateRequestExample={updateRequestExample}
          typesList={typesList}
        />
      )))}
    </>
  );
}
