/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pg from '../../pictures/postgres-logo.png';
import ms from '../../pictures/mysql-logo.png';
import cz from '../../pictures/corezoid-logo.png';
import database from '../../pictures/icon-database.png';
import mn from '../../pictures/mongo-logo.png';
import mss from '../../pictures/mssql-logo.png';
import oracle from '../../pictures/oracle.png';
import AWS from '../../pictures/AWS.png';
import openAI from '../../pictures/openai.png';
import * as styles from './DBIcon.module.scss';

const types = {
  postgres: pg,
  mysql: ms,
  mssql: mss,
  corezoid: cz,
  mongo: mn,
  oracle,
  AWS,
  chatGPT: openAI,
  database,
};

function DBIcon({
  type = 'postgres',
  style = {},
}) {
  return (
    <div
      className={cn(
        styles.dbIcon,
      )}
      style={style}
    >
      <img src={types[type]} alt={type} />
    </div>
  );
}

DBIcon.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
};

export default DBIcon;
