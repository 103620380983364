/* eslint-disable import/prefer-default-export */
export const Roles = {
  listRolesRequest: 'LIST_ROLES_PENDING',
  listRolesSuccess: 'LIST_ROLES_SUCCESS',
  listRolesError: 'LIST_ROLES_ERROR',

  removeRoleRequest: 'REMOVE_ROLE_PENDING',
  removeRoleSuccess: 'REMOVE_ROLE_SUCCESS',
  removeRoleError: 'REMOVE_ROLE_ERROR',

  modifyRoleRequest: 'MODIFY_ROLE_PENDING',
  modifyRoleSuccess: 'MODIFY_ROLE_SUCCESS',
  modifyRoleError: 'MODIFY_ROLE_ERROR',

  createRoleRequest: 'CREATE_ROLE_PENDING',
  createRoleSuccess: 'CREATE_ROLE_SUCCESS',
  createRoleError: 'CREATE_ROLE_ERROR',
};
