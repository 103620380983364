import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ProjectDefault from '../../pictures/project-default.svg';
import ActionText from '../../components/ActionText/ActionText';
import { authRequest, resetPasswordRequest } from '../../store/auth/actions';

import * as styles from './Auth.module.scss';

const Schema = Yup.object().shape({
  username: Yup.string().email('Use valid email address').required('Email required'),
});

function ResetPassword({ auth, resetPassword }) {
  const navigate = useNavigate();
  const {
    handleSubmit, handleChange, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Schema,
    onSubmit: (v, { setSubmitting }) => {
      resetPassword({ email: v.username }, (success) => {
        if (success) navigate('/reset-password/success');
        if (!success) setSubmitting(false);
      });
    },
  });

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.logoBox}>
          <ProjectDefault />
          <h2>Trigger API Gateway</h2>
        </div>
        <div className={styles.titleBox}>
          <h3>Reset password</h3>
          <div className={styles.actionButton}><ActionText title="Login" onClick={goToLogin} /></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <input
              className={cn({ [styles.errorInput]: errors.username && touched.username })}
              autoComplete="email"
              type="text"
              name="username"
              id="username"
              placeholder="Email"
              onChange={handleChange}
              value={values.username}
            />
            {errors.username && touched.username && (
              <span className={styles.errorMessage}>{errors.username}</span>
            )}
          </div>

          <div className={styles.row}>
            <button
              type="submit"
              className={cn({ [styles.buttonSubmitting]: isSubmitting })}
            >
              Reset password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  auth: (data, callback) => dispatch(authRequest(data, callback)),
  resetPassword: (data, callback) => dispatch(resetPasswordRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
