/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CodeEditor from '../CodeEditor';
import { loadStatus } from '../../helpers/const';
import { gptRequest } from '../../store/endpoints/actions';
import IconChecked from '../../pictures/icon-checked.svg';
import IconAlert from '../../pictures/icon-alert.svg';
import EnterArrow from '../../pictures/icon-arrow-enter.svg';
import Loader from '../Loader';
import Input from '../Input/Input';

const steps = {
  empty: 'empty',
  initial: 'initial',
  success: 'success',
  error: 'error',
  load: 'load',
};

function ChatGpt({
  query,
  context,
  gptQuery,
  connectionId,
  onSuccess,
}) {
  const [request, setRequest] = useState('');
  const [response, setResponse] = useState('');
  const [status, setStatus] = useState(loadStatus.INITIAL);
  const [icon, setIcon] = useState(steps.empty);

  function parseSqlTags(content) {
    const regex = /<sql>(.*?)<\/sql>/gs;
    const matches = content.match(regex);
    if (!matches) return '';
    return matches.map((match) => `${match.replace(/<\/?sql>/g, '')}\n`).join('');
  }

  const runGptQueryRequest = () => {
    if (icon !== steps.empty && icon !== steps.initial) return;
    setStatus(loadStatus.LOAD);
    gptQuery({ connectionId, query: request }, (result, res) => {
      if (result !== 'ERROR') {
        setStatus(loadStatus.SUCCESS);
        setResponse(res);
        onSuccess(parseSqlTags(res));
      } else {
        setStatus(loadStatus.ERROR);
        setResponse('Oops! Something going wrong');
      }
    });
  };

  const getIcon = (s) => {
    switch (s) {
      case 'empty':
        return EnterArrow;
      case 'initial':
        return EnterArrow;
      case 'load':
        return Loader;
      case 'success':
        return IconChecked;
      case 'error':
        return IconAlert;
      default:
        return EnterArrow;
    }
  };

  useEffect(() => {
    if (status === loadStatus.LOAD) setIcon(steps.load);
    if (status === loadStatus.ERROR) setIcon(steps.error);
    if (status === loadStatus.SUCCESS) setIcon(steps.success);
  }, [status]);

  useEffect(() => {
    if (!request.length) setIcon(steps.empty);
    if (request.length) setIcon(steps.initial);
  }, [request]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      return runGptQueryRequest();
    }
    return null;
  };

  return (
    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Input
        multiline
        placeholder="ChatGPT Response"
        handleChange={(e) => setResponse(e.taget?.value)}
        value={response}
        name="response"
      />
    </div>
  );
}

ChatGpt.propTypes = {
  query: PropTypes.string,
  context: PropTypes.string,
  gptQuery: PropTypes.func.isRequired,
  connectionId: PropTypes.number,
  onSuccess: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  gptQuery: (data, callback) => dispatch(gptRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(ChatGpt);
