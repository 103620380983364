import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  activeEndpointRequest, activeEndpointSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { ActiveEndpoint } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchActiveEndpoint(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/endpoints/${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeEndpointSuccess(parsed));
        callback?.();
      } else if (response.status === 401) {
        yield put(refreshRequest(activeEndpointRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* activeEndpointSaga() {
  yield takeEvery(ActiveEndpoint.activeEndpointRequest, fetchActiveEndpoint);
}

function* configSaga() {
  yield all([fork(activeEndpointSaga)]);
}

export default configSaga;
