/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { connect } from 'react-redux';
import { removeConnectionRequest, removeConnectionsListRequest } from '../../store/connections/actions';
import { createToastRequest } from '../../store/toasts/actions';
import Button from '../Button/Button';
import * as styles from './RemoveConnectionModal.module.scss';

function RemoveConnectionModal({ removeConnection, removeConnectionsList, connection, connections, setIsOpen, createToast }) {
  const handleRemove = () => {
    removeConnection({ ...connection }, () => {
      setIsOpen(false, true);
    });
  };

  const handleRemoveList = () => {
    removeConnectionsList({ items: connection }, ({ errors, successList }) => {
      setIsOpen(false, true);
      if (errors && errors.length > 0) {
        errors.forEach(e => {
          createToast({ type: 'error', text: e.error })
        })
      }
      if (successList && successList.length > 0) {
        successList.forEach(id => {
          const name = connections.find(e => e.id === id)?.instanceName || '';
          createToast({ type: 'success', title: `Connection ${name} `, text: 'has been delete successfully' })
        })
      }
    });
  };

  if (Array.isArray(connection)) {
    return (
      <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove Connections</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove
              {' '}
              <span className={styles.modelTitle}>
                {connection.length}
                {' '}
                {connection.length > 1 ? 'Connections' : 'Connection'}
              </span>
              ?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '120px', marginLeft: '12px' }} type="red" title="Remove" onClick={handleRemoveList} />
          </div>
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Remove Connection</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div>
              Are you sure you want to remove
              {' '}
              <span className={styles.modelTitle}>
                {connection.instanceName}
              </span>
              {' '}
              Connection
              ?
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button style={{ width: '85px' }} title="Close" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '120px', marginLeft: '12px' }} type="red" title="Remove" onClick={handleRemove} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  removeConnection: (data, callback) => dispatch(removeConnectionRequest(data, callback)),
  removeConnectionsList: (data, callback) => dispatch(removeConnectionsListRequest(data, callback)),
  createToast: (data) => dispatch(createToastRequest(data))
});

export default connect(null, mapDispatchToProps)(RemoveConnectionModal);
