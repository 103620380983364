import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  activeConnectionRequest, activeConnectionSuccess,
  testActiveConnectionRequest, testActiveConnectionSuccess,
  testActiveConnectionError,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { ActiveConnection } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchActiveConnection(ops) {
  const { data } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/connections/${data.id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(activeConnectionSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(activeConnectionRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* testConnection(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);
  
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/connections/test', 'POST', data, authData.access_token);
      if (response.ok) {
        yield put(testActiveConnectionSuccess());
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(testActiveConnectionRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
        yield put(testActiveConnectionError());
        if (callback) callback(true);
      }
    }
  } catch (e) {
    yield put(testActiveConnectionError());
    if (callback) callback(true);
  }
}

function* activeConnectionSaga() {
  yield takeEvery(ActiveConnection.activeConnectionRequest, fetchActiveConnection);
  yield takeEvery(ActiveConnection.testActiveConnectionRequest, testConnection);
}

function* configSaga() {
  yield all([fork(activeConnectionSaga)]);
}

export default configSaga;
