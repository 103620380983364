import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { removeToastRequest } from '../../store/toasts/actions';
import { listErrorsRequest } from '../../store/errors/actions';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import IconChecked from '../../pictures/icon-checked.svg';
import IconAlert from '../../pictures/icon-alert.svg';
import * as style from './Toasts.module.scss';

const icon = {
  success: IconChecked,
  error: IconAlert,
};

function Toast({ item, action, errors, shortName }) {
  const navigate = useNavigate();
  const { type = 'success', text, title, code, id, linkTitle } = item;

  const Icon = icon[type];

  const getTextError = () => {
    if (code && errors) {
      return errors.find(e => e.code === code)?.msg?.en || text
    }
    return text
  }

  const getTitle = () => {
    if (linkTitle) {
      const action = () => navigate(`/${shortName}${linkTitle}`);
      return (
        <>
          <ActionText title={title} onClick={action} type="black"/>
          {` `}
        </>
      )
    }
    return (
      <span className={style.title}>{title} </span>
    )
  }

  return (
    <div className={cn(style.toast, {
      [style.error]: type === 'error',
    })}
    >
      <div className={style.group}>
        <Icon />
        <div className={style.groupContent}>
          {type === 'error' && (
            <span className={style.title}>Error: </span>
          )}
          {type === 'success' && title && getTitle(title)}
          {getTextError()}
        </div>
      </div>
      <div>
        <ActionIcon
          icon="close"
          hoverDisabled
          small
          onClick={() => action(id)}
        />
      </div>
    </div>
  );
}

function Toasts({ toasts, removeToast, getErrors, errors, activeProject }) {
  const handleAction = (id) => {
    removeToast({ id });
  };

  useEffect(() => {
    if (!errors) {
      getErrors();
    }
  }, [errors, window.location.href]);

  return (
    <div className={style.toastsContainer}>
      {toasts && toasts.length ? toasts.map((t) => (
        <Toast
          key={t.key}
          item={t}
          errors={errors}
          action={handleAction}
          shortName={activeProject?.shortName}
        />
      )) : (
        <div />
      )}
    </div>
  );
}

const mapStateToProps = ({ toasts, errors, activeProject }) => ({
  toasts,
  errors,
  activeProject
});

const mapDispatchToProps = (dispatch) => ({
  removeToast: (data) => dispatch(removeToastRequest(data)),
  getErrors: () => dispatch(listErrorsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
