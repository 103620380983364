@use "sass:map";
@import '../../styles/variables.scss';

.dbIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: map.get($colors-basic, "secondary");
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 14px;
        max-height: 14px;
    }
}