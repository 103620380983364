import { Projects } from './constants';

const initialState = null;

export const projectsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Projects.listProjectsSuccess:
      return action.data;
    case Projects.createProjectSuccess:
      return state ? [...state, action.data] : [action.data];
    case Projects.removeProjectSuccess:
      return [
        ...state.filter((el) => el.id !== action.data.projectId),
      ];
    default:
      return state;
  }
};
