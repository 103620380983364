import { Errors } from './constants';

const initialState = [];

export const errorsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Errors.listErrorsSuccess:
      return action.data;
    default:
      return state;
  }
};
