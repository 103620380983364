/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
export const checker = (arr, target) => target.every((v) => arr.includes(v?.id)) || (!arr?.length && !target?.length);

export const shortenText = (str) => {
  if (typeof str === 'string' && str.length > 8) {
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
  }
  return str;
};

export const shortenEmail = (email, length) => {
  if (typeof email === 'string' && email.length > length) {
    const [part, ...emailPart] = email.split('@');
    return `${part.slice(0, length - 3)}...@${emailPart.join('')}`;
  }
  return email;
};

export const roleMapper = (roleId) => {
  switch (roleId) {
    case 2:
      return 'Owner';
    case 3:
      return 'Editor';
    case 4:
      return 'Viewer';
    default:
      return 'Viewer';
  }
};

export const sqlPlaceholder = (isJS, isSqlGptMode, isGptError) => {
  if (isJS) return 'Enter query';
  if (isGptError && isSqlGptMode) return 'Please copy and paste SQL from ChatGPT response';
  return 'Enter SQL query';
};

export const shorNameGenerator = (name) => {
  const matched = (name.match(/[a-zA-Z- ]/gm) || []).join('').toLowerCase();
  return matched.replace(/ /ig, '-');
};

export const paramsParser = (name) => {
  const matched = (name || '').match(/\{{(.*?)\}}/g) || [];
  return matched.map((m) => m.replace('{{', '').replace('}}', ''));
};

export const getProjectRoles = (rolesList = [], projectId) => rolesList.filter((r) => r.projects?.length === 0 || r.projects?.includes(projectId)) || [];

export const formatingJSONToRender = (value) => {
  let tab = 0;
  let newRow = [];
  const getTab = () => {
    let n = 0;
    let s = '';
    while (n < tab) {
      n++;
      s += '  ';
    }
    return s;
  };

  const isArray = (arr) => Array.isArray(arr);

  const isObject = (obj) => Object.keys(obj) && Object.getPrototypeOf(obj) === Object.prototype;

  const createArray = (key, arr, isLast) => {
    const newRowArr = [];
    arr.forEach((a, index) => {
      if (index === 0 && key) {
        tab += 1;
        newRowArr.push(`"${key}": [\n${getTab()}`);
      } else if (index === 0) {
        tab += 1;
        newRowArr.push(`[\n${getTab()}`);
      }

      if (index === arr.length - 1) {
        tab -= 1;
      }
      if (typeof a === 'number') {
        if (index === arr.length - 1) {
          newRowArr.push(`${a}\n${getTab()}`);
        } else {
          newRowArr.push(`${a},\n${getTab()}`);
        }
      } else if (isObject(a)) {
        tab += 1;
        newRowArr.push(createObj(a, index === arr.length - 1));
      } else if (isArray(a)) {
        newRowArr.push(createArray('', a, index === arr.length - 1));
      } else if (index === arr.length - 1) {
        newRowArr.push(`"${a}"\n${getTab()}`);
      } else {
        newRowArr.push(`"${a}",\n${getTab()}`);
      }
      if (index === arr.length - 1) {
        if (isLast) {
          tab -= 1;
          newRowArr.push(`]\n${getTab()}`);
        } else {
          newRowArr.push(`],\n${getTab()}`);
        }
      }
    });
    return newRowArr.join('');
  };

  const createObj = (obj, isLast) => {
    const newRowObj = [];
    const arr = Object.keys(obj);
    arr.forEach((key, index) => {
      if (index === 0) {
        tab += 1;
        newRowObj.push(`{\n${getTab()}`);
      }
      if (typeof obj[key] === 'number') {
        if (index === arr.length - 1) {
          tab -= 1;
          newRowObj.push(`"${key}": ${obj[key]}\n${getTab()}`);
        } else {
          newRowObj.push(`"${key}": ${obj[key]},\n${getTab()}`);
        }
      } else if (isObject(obj[key])) {
        newRowObj.push(`"${key}": ${createObj(obj[key], index === arr.length - 1)}`);
      } else if (isArray(obj[key])) {
        newRowObj.push(createArray(key, obj[key], index === arr.length - 1));
      } else if (index === arr.length - 1) {
        tab -= 1;
        newRowObj.push(`"${key}": "${obj[key]}"\n${getTab()}`);
      } else {
        newRowObj.push(`"${key}": "${obj[key]}",\n${getTab()}`);
      }

      if (index === arr.length - 1) {
        if (isLast) {
          tab -= 1;
          newRowObj.push(`}\n${getTab()}`);
        } else {
          newRowObj.push(`},\n${getTab()}`);
        }
      }
    });
    return newRowObj.join('');
  };

  try {
    newRow = createObj(JSON.parse(value), true);
  } catch (e) {
    newRow = value;
  }

  return newRow;
};

const debounceTimeout = {};

export const debounce = (id, fn, timeout = 500) => {
  if (debounceTimeout[id]) clearTimeout(debounceTimeout[id]);
  debounceTimeout[id] = setTimeout(fn, timeout);
};

export const parseSqlTags = (content) => {
  const regex = /<sql>(.*?)<\/sql>|```(.*?)```/gs;
  const matches = content.match(regex) || [];
  const finalText = matches.map((match) => {
    if (match.includes('<sql>')) {
      return `${match.replace(/<\/?sql>/g, '')}\n`;
    }
    return `${match.replace(/```/g, '')}\n`;
  }).join('');

  return finalText;
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseIfJSON = (str) => {
  let parsed;
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    return str;
  }
  return parsed;
};

export const stringifyIfJSON = (str) => {
  let parsed;
  try {
    parsed = JSON.stringify(str, null, 2);
  } catch (e) {
    return str;
  }
  return parsed;
};

export const isEmptyObject = (el) => !Object.keys(el).length;

export const injectChunk = (obj, update) => {
  const { folderId } = update;

  // check if the current object has a "foldersData" property
  if (obj.foldersData) {
    // iterate over each folder object
    const updatedFoldersData = obj.foldersData.map((folder) => {
      // check if the folder has the specified update ID
      if (folder.id === folderId) {
        // if it does, update it and return the updated folder
        return {
          ...folder,
          items: {
            data: update.data,
            foldersData: update.folders,
            breadscrumbData: update.breadscrumbs,
          },
        };
      } if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: injectChunk(folder.items, update),
        };
      }
      return folder;
    });

    // return a copy of the original object with the updated "foldersData" property
    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const injectChunkEndpoint = (obj, update) => {
  const { folderId } = update;

  // check if the current object has a "foldersData" property
  if (obj.foldersData) {
    // iterate over each folder object
    const updatedFoldersData = obj.foldersData.map((folder) => {
      // check if the folder has the specified update ID
      if (folder.id === folderId) {
        // if it does, update it and return the updated folder
        return {
          ...folder,
          items: {
            data: update.endpoints,
            foldersData: update.folders,
            breadscrumbData: update.breadscrumbs,
          },
        };
      } if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: injectChunkEndpoint(folder.items, update),
        };
      }
      return folder;
    });

    // return a copy of the original object with the updated "foldersData" property
    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const addFolder = (obj, update) => {
  const { parentId, rootAction } = update;
  if (rootAction) {
    return {
      ...obj,
      foldersData: [
        ...obj.foldersData,
        update,
      ],
    };
  }

  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData.map((folder) => {
      if (folder.id === parentId) {
        return {
          ...folder,
          items: {
            ...(folder.items ? folder.items : { data: [], breadscrumbData: [] }),
            foldersData: [
              ...(folder.items ? folder.items.foldersData : []),
              update,
            ],
          },
        };
      } if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: {
            ...folder.items,
            foldersData: addFolder(folder.items, update).foldersData,
          },
        };
      }
      return folder;
    });

    return {
      ...obj,
      foldersData: [
        ...updatedFoldersData,
        // ...((found && !reccursive) ? [] : [update]),
      ],
    };
  }

  return {
    ...obj,
    foldersData: [update],
  };
};

export const removeFolder = (obj, update) => {
  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData
      .filter((folder) => folder.id !== update.id)
      .map((folder) => {
        if (folder.items && folder.items.foldersData) {
          return {
            ...folder,
            items: {
              ...folder.items,
              foldersData: removeFolder(folder.items, update).foldersData,
            },
          };
        }
        return folder;
      });

    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const updateFolder = (obj, update) => {
  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData
      .map((folder) => {
        if (folder.id === update.id) {
          return {
            ...folder,
            name: update.name,
          };
        }
        if (folder.items && folder.items.foldersData) {
          return {
            ...folder,
            items: {
              ...folder.items,
              foldersData: updateFolder(folder.items, update).foldersData,
            },
          };
        }
        return folder;
      });

    return {
      ...obj,
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const removeObj = (obj, update) => {
  const { id } = update;

  if (obj.foldersData) {
    const updatedFoldersData = obj.foldersData.map((folder) => {
      if (folder.items && folder.items.foldersData) {
        return {
          ...folder,
          items: {
            ...folder.items,
            data: folder.items.data.filter((item) => item.id !== id),
            foldersData: removeObj(folder.items, update).foldersData,
          },
        };
      }
      return folder;
    });

    return {
      ...obj,
      data: obj.data.filter((item) => item.id !== id),
      foldersData: updatedFoldersData,
    };
  }

  return obj;
};

export const createObj = (obj, update) => {
  const { folder: folderId, rootAction } = update;

  if (rootAction) {
    return {
      ...obj,
      data: [
        ...obj.data,
        update,
      ],
    };
  }

  const newFoldersData = obj.foldersData.map((folder) => {
    if (folder.id === folderId) {
      return {
        ...folder,
        items: {
          ...(folder.items ? folder.items : { foldersData: [], breadscrumbData: [] }),
          data: [
            ...(folder.items ? folder.items.data : []),
            update,
          ],
        },
      };
    } if (folder.items && folder.items.foldersData) {
      return {
        ...folder,
        items: {
          ...folder.items,
          foldersData: createObj(folder.items, update, true).foldersData,
        },
      };
    }
    return folder;
  });

  return {
    ...obj,
    foldersData: newFoldersData,
  };
};

export const moveFolder = (obj, update) => {
  const result = removeFolder(obj, update);

  return addFolder(result, update);
};

export const moveObj = (obj, update) => {
  const result = removeObj(obj, update);

  return createObj(result, update);
};

export const getAllData = (obj) => {
  let items = [];

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'data')) {
    items = obj.data.map((el) => el.id);
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'foldersData')) {
    obj.foldersData.forEach((folder) => {
      items = items.concat(getAllData(folder.items));
    });
  }

  return items;
};

export const getAllfoldersData = (obj) => {
  const folderIds = [];

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'id')) {
    folderIds.push(obj.id);
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'foldersData')) {
    obj.foldersData.forEach((folder) => {
      folderIds.push(...getAllfoldersData(folder));
    });
  }

  if (obj && Object.prototype.hasOwnProperty.call(obj, 'items')) {
    folderIds.push(...getAllfoldersData(obj.items));
  }

  return folderIds;
};

export const getFolderData = (obj, id) => {
  if (!obj) {
    return null;
  }

  const { foldersData = [] } = obj;

  for (const folder of foldersData) {
    if (folder.id === id) {
      return folder.items;
    }

    if (folder.items) {
      const subFolderData = getFolderData(folder.items, id);
      if (subFolderData) {
        return subFolderData;
      }
    }
  }

  return null;
};

export const getFoldersDataInFolder = (obj, id) => {
  const scheme = getFolderData(obj, id);

  return getAllfoldersData(scheme, id);
};

export const getDataInFolder = (obj, id) => {
  const scheme = getFolderData(obj, id);

  return getAllData(scheme, id);
};
