import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidepanel from '../Sidepanel/Sidepanel';
import * as style from './Main.module.scss';

function Main() {
  return (
    <div className={style.main}>
      <Sidepanel />
      <Outlet />
    </div>
  );
}

export default Main;
