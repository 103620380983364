import React, { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import cn from 'classnames';
import ActionIcon from '../ActionIcon';
import Input from '../Input/Input';
import Select from '../Select/Select';
import CodeEditor from '../CodeEditor';
import { debounce } from '../../helpers/utils';

import * as shared from '../../styles/shared.module.scss';
import { ViewModes } from '../CodeEditor/codeEditorConstants';

export function ParamentRow({
  id,
  param,
  index,
  moveParam,
  handleChangeProxy,
  setFieldValueProxy,
  updateRequestExample,
  removeParameter,
  errors,
  parameterType,
  typesList,
  viewRead = false,
}) {
  const ref = useRef(null);
  const [errorFormat, setErrorFormat] = useState(false);
  const [defaultValue, setDefaultValue] = useState(param.defaultValue);

  useEffect(() => {
    if (param.defaultValue !== defaultValue) {
      setDefaultValue(param.defaultValue);
    }
  }, [param.defaultValue]);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveParam(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const parseVelue = (value, type) => {
    try {
      setErrorFormat(false);
      const res = JSON.parse(value);
      setDefaultValue(res);
      return res;
    } catch {
      setErrorFormat(true);
      setDefaultValue(value);
    }
  };

  return (
    <div
      className={cn(shared.sectionContainer, shared.triple)}
      style={{ opacity }}
      ref={ref}
    >
      <div className={shared.inputWrapper}>
        <Input
          placeholder="Name"
          handleChange={handleChangeProxy}
          value={param.name}
          name={`name.${param.id}`}
          error={errors?.params && errors.params[index]?.name && !param.name}
          disabled={viewRead}
        />
      </div>
      <div className={shared.inputWrapper}>
        {parameterType === 'desription'
          ? (
            <Input
              placeholder="Description"
              handleChange={handleChangeProxy}
              value={param.description}
              name={`description.${param.id}`}
              disabled={viewRead}
            />
          )
          : (
            param.type === 'array' || param.type === 'object'
              ? (
                <div className={cn(shared.codeEditorWrap, shared.codeEditorWrapInline)}>
                  <CodeEditor
                    value={errorFormat ? `${defaultValue}` : defaultValue && JSON.stringify(defaultValue) || ''}
                    handleBlurCallback={(value) => {
                      if (value === JSON.stringify(defaultValue)) return;
                      const newV = parseVelue(value, param.type);
                      newV && handleChangeProxy({ target: { value: newV, name: `defaultValue.${param.id}` } });
                      debounce('codeEditorDefaultValue', () => {
                        updateRequestExample(param.name, newV);
                      }, 500);
                    }}
                    name={`defaultValue.${param.id}`}
                    type="sql"
                    placeholder="Default value"
                    disabled={viewRead}
                    error={errorFormat}
                    viewMode={ViewModes.Inline}
                  />
                </div>
              )
              : (
                <Input
                  placeholder="Default value"
                  handleChange={(e) => {
                    if (param.type === 'number') {
                      handleChangeProxy({
                        target: {
                          value: Number(e.target.value),
                          name: `defaultValue.${param.id}`,
                        },
                      });
                      debounce('inpurDefaultValue', () => {
                        updateRequestExample(param.name, Number(e.target.value));
                      }, 500);
                    } else {
                      handleChangeProxy(e);
                      debounce('inpurDefaultValue', () => {
                        updateRequestExample(param.name, e.target.value);
                      }, 500);
                    }
                  }}
                  value={param.defaultValue}
                  name={`defaultValue.${param.id}`}
                  type={param.type === 'number' ? 'number' : 'text'}
                  disabled={viewRead}
                />
              )
          )}
      </div>
      <div className={shared.inputWrapper}>
        <Select
          list={typesList}
          value={param.type}
          handleChange={setFieldValueProxy}
          name={`type.${param.id}`}
          placeholder="Select"
          type="top"
          error={errors?.params && errors.params[index]?.type && !param.type}
          disabled={viewRead}
        />
      </div>
      <div className={shared.inputWrapper}>
        <Select
          list={[
            { value: 'yes', title: 'Yes' },
            { value: 'no', title: 'No' },
          ]}
          value={param.required}
          handleChange={setFieldValueProxy}
          name={`required.${param.id}`}
          placeholder=""
          type="top"
          error={errors?.params && errors.params[index]?.required && !param.required}
          disabled={viewRead}
        />
      </div>
      <div className={cn(shared.inputWrapper, shared.btn)}>
        <div data-handler-id={handlerId}>
          <ActionIcon
            icon="list"
            style={{ marginRight: '16px', cursor: 'move' }}
          />
        </div>
        {!viewRead
          && (
          <ActionIcon
            icon="trash"
            onClick={() => removeParameter(param)}
          />
          )}
      </div>
    </div>
  );
}
