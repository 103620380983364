@use "sass:map";
@import '../../styles/variables.scss';

.debugConsole {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: map.get($colors-basic, "selectedOption");
  position: relative;
  padding: 8px 8px 9px 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;

  .vLine {
    border-left: 4px solid map.get($colors-basic, "primaryBlue");
    border-radius: 2px;
  }

  .method {
    font-weight: 600;
    text-transform: uppercase;
  }

  .url {
      width: calc(100% - 76px);
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .url > span {
    color: map.get($colors-basic, "primaryBlue");
    font-family: 'Menlo', sans-serif;
  }

  .action {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }


}
