import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import ProjectDefault from '../../pictures/project-default.svg';
import SelectedIcon from '../../pictures/icon-selected.svg';
import ActionIcon from '../ActionIcon/ActionIcon';
import Button from '../Button/Button';
import CreateProjectModal from '../CreateProjectModal/CreateProjectModal';
import * as style from './ProjectSelection.module.scss';

function PopUpMenu({
  handleChange, list, activeProject, buttonAction,
}) {
  const ref = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      handleChange(false);
    }
  };

  const handleClickButton = () => {
    handleChange(false);
    buttonAction(true);
  };

  const changeProjectTo = (shortName) => {
    handleChange(false);
    navigate(`/${shortName}/endpoints`);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={style.popupMenu} ref={ref}>
      <div className={style.unscrolledItem}>
        <span className={style.titlePopUpHeading}>Projects</span>
        {' '}
        <span className={style.number}>
          (
          {list.length}
          )
        </span>
      </div>
      <ul>
        {list.map(({ id, name, shortName }) => (
          <li
            className={cn(style.link, {
              [style.activeLink]: activeProject?.id === id,
            })}
            key={id}
          >
            <button type="button" onClick={() => changeProjectTo(shortName)}>
              <span>{name}</span>
              {activeProject?.id === id && (
                <div className={style.iconSelected}>
                  <SelectedIcon />
                </div>
              )}
            </button>
          </li>
        ))}
      </ul>
      <div className={style.unscrolledItem}>
        <Button
          onClick={handleClickButton}
          title="Create new project"
          style={{ width: '100%' }}
          size="M"
        />
      </div>
    </div>
  );
}

PopUpMenu.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  })),
};

function ProjectSelection({ projects = [] }) {
  const { shortName } = useParams();
  const navigate = useNavigate();
  const [menuOpened, setMenuOpened] = useState(false);
  const activeProject = projects.find((el) => el.shortName === shortName);

  const goToCreate = () => {
    navigate(`/${shortName}/create-project`, { state: { secure: true } });
  };

  return (
    <div className={style.projectSelection}>
      <div className={style.group}>
        <div className={style.iconGroup}>
          <div className={style.icon}>
            <ProjectDefault />
          </div>
          <div className={style.titleGroup}>
            <div className={style.heading}>Selected Project</div>
            <div className={style.title}>{activeProject?.name}</div>
          </div>
        </div>
        <div className={style.actionGroup}>
          <ActionIcon
            icon="options"
            onClick={() => setMenuOpened(!menuOpened)}
            active={menuOpened}
          />
        </div>
      </div>
      {menuOpened && (
        <PopUpMenu
          buttonAction={goToCreate}
          handleChange={setMenuOpened}
          list={projects}
          activeProject={activeProject}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ projects }) => ({
  projects,
});

export default connect(mapStateToProps)(ProjectSelection);
