import React from 'react';
import * as style from './Modal.module.scss';
import { useOutsideClick } from '../../hooks/hooks';
import ModalProvider from './ModalProvider';

function Modal({ children, onClose, isOpen }) {
  const ref = useOutsideClick(onClose);

  return (
    <ModalProvider isOpen={isOpen}>
      <div className={style.modal}>
        <div className={style.modalContent} ref={ref}>
          {children}
        </div>
      </div>
    </ModalProvider>
  );
}

export default Modal;
