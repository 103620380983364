/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { createProjectRequest } from '../../store/projects/actions';
import { invitesRequest } from '../../store/invites/actions';
import Button from '../../components/Button/Button';
import ChipsStatus from '../../components/ChipsStatus/ChipsStatus';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import ActionText from '../../components/ActionText/ActionText';
import { roleMapper, shorNameGenerator } from '../../helpers/utils';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';

import * as shared from '../../styles/shared.module.scss';

const validationRegex = /^(?!\s)(?!.* {2})/;

const ProjectCreateSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project Name is required.')
    .matches(
      validationRegex,
      'Project Name is required.',
    )
    .min(2)
    .max(64),
  shortName: Yup.string().required('shortName required'),
});

function ProjectsCreate({ createProject, sendInvite, currentUser }) {
  const [inviteList, setInviteList] = useState([]);
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { secure } = state || {};

  const secureGoBack = () => {
    if (secure) navigate(-1);
    if (!secure) navigate('/');
  };

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const saveInviteList = (data) => {
    const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
    const unique = Object.values(
      [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
    );
    setInviteList(unique);
    setShareModalOpened(false);
  };

  const handeChangeRole = (email, role) => {
    const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
    setInviteList(newInviteList);
  };

  const {
    submitForm, setFieldValue, values: { name, shortName }, errors, touched,
  } = useFormik({
    initialValues: {
      name: '',
      shortName: '',
    },
    validationSchema: ProjectCreateSchema,
    onSubmit: (v) => {
      createProject({ name: v.name, shortName: v.shortName }, (id) => {
        navigate(`/${id}/endpoints`);
      });
    },
  });

  const handleChangeProxy = (e) => {
    const { value } = e.target;
    const transformed = shorNameGenerator(value);
    setFieldValue('shortName', transformed);
  };

  useEffect(() => {
    setFieldValue('shortName', shorNameGenerator(name));
  }, [name]);

  const handleChangeProjectName = (e) => {
    if (validationRegex.test(e.target.value) || e.target.value === '') {
      setFieldValue(e.target.name, e.target.value);
    }
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Create Project</h1>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Cancel" type="secondary" onClick={() => secureGoBack()} />
          <Button title="Create project" onClick={() => submitForm()} />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Project Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter project name"
                  handleChange={handleChangeProjectName}
                  value={name}
                  name="name"
                  error={errors.name && touched.name}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Short Name</span>
                <Input
                  placeholder="enter-short-name"
                  handleChange={handleChangeProxy}
                  value={shortName}
                  name="shortName"
                  error={errors.shortName && touched.shortName}
                />
              </div>
            </div>
          </section>
        </div>
        {/* <div className={shared.sidepanel}>
          <div className={shared.sidepanelHeading}>
            <h4 className={shared.sectionHeader}>Accesses</h4>
            <ActionText title="Add user or API key" onClick={addUserOfApiKey} />
          </div>
          <div className={shared.sidepanelTable}>
            <div className={shared.sidepanelTableHeading}>
              <div className={shared.line}>
                <div>Name</div>
                <div>Type</div>
                <div>Role</div>
              </div>
            </div>
            <hr />
            <ul className={shared.sidepanelTableBody}>
              {inviteList && inviteList.map((item) => (
                <li className={shared.line} key={item.userEmail}>
                  <div title={item.userEmail}>
                    {item.userEmail === currentUser.email ? (
                      <span>{`${item.userFirstName} ${item.userLastName}`}</span>
                    ) : (
                      <span>{item.userEmail}</span>
                    )}
                  </div>
                  <div>
                    {item.userEmail === currentUser.email ? (
                      <ChipsStatus title="User" type="user" />
                    ) : (
                      <ChipsStatus title="Invite" type="invite" />
                    )}
                  </div>
                  <div>
                    {item.userEmail === currentUser.email ? (
                      <Select
                        list={[
                          { value: 2, title: roleMapper(2) },
                        ]}
                        value={item.roleId}
                        handleChange={(email, value) => handeChangeRole(email, value)}
                        name={item.userEmail}
                        disabled
                      />
                    ) : (
                      <Select
                        list={[
                          { value: 3, title: roleMapper(3) },
                          { value: 4, title: roleMapper(4) },
                        ]}
                        value={item.roleId}
                        handleChange={(email, value) => handeChangeRole(email, value)}
                        name={item.userEmail}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
        {shareModalOpened && (
          <InviteToResourceModal
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={{ name, shortName }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createProject: (data, callback) => dispatch(createProjectRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsCreate);
