/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable quote-props */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { uid } from 'uid';
import {
  useFormik,
} from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { format, parseISO } from 'date-fns';
import { createToastRequest } from '../../store/toasts/actions';
import Button from '../../components/Button/Button';
import CommitDiff from '../../components/CommitDiff';
import { useCopyToClipboard } from '../../hooks/hooks';
import Input from '../../components/Input/Input';
import ChipsStatus, { ChipsMap } from '../../components/ChipsStatus/ChipsStatus';
import Select from '../../components/Select/Select';
import {
  typesAPI,
  loadStatus,
  backendHost,
  listRolesName,
} from '../../helpers/const';
import ActionText from '../../components/ActionText/ActionText';
import ActionIcon from '../../components/ActionIcon/ActionIcon';
import InviteToResourceModal from '../../components/InviteToResourceModal/InviteToResourceModal';
import RemoveAccessModal from '../../components/RemoveAccessModal/RemoveAccessModal';
import CodeEditor from '../../components/CodeEditor';
import DebugConsole from '../../components/DebugConsole';
import DBIcon from '../../components/DBIcon/DBIcon';
import Loader from '../../components/Loader';
import {
  updateEndpointRequest,
  testEndpointRequest,
  gptRequest as gptRequestData,
} from '../../store/endpoints/actions';
import {
  activeEndpointRequest,
  activateOriginVersion,
  displayVersion,
} from '../../store/activeEndpoint/actions';
import { listConnectionsRequest } from '../../store/connections/actions';
import { getAccessUsersRequest } from '../../store/users/actions';
import { addAccessRequest } from '../../store/invites/actions';
import { isRoleAction } from '../../helpers/hocs';
import {
  getProjectRoles,
  formatingJSONToRender,
  paramsParser,
  isJsonString,
  parseSqlTags,
  parseIfJSON,
  stringifyIfJSON,
  isEmptyObject,
  sqlPlaceholder,
} from '../../helpers/utils';
import IconChecked from '../../pictures/icon-checked.svg';
import IconAlert from '../../pictures/icon-alert.svg';
import { ParamentRows } from '../../components/ParametrRows/ParamentRows';
import * as shared from '../../styles/shared.module.scss';
import {
  createVersionRequest,
  historyRequest,
  overrideVersionRequest,
  userRequest,
  diffVersionRequest,
  clearHistoryData,
} from '../../store/history/actions';
import ArrowIcon from '../../pictures/icon-arrow.svg';
import InfoIcon from '../../pictures/info.svg';
import BlueInfoIcon from '../../pictures/blue-info.svg';
import { WarningModal } from '../../components/WarningModal';
import Modal from '../../components/Modal/Modal';
import { ConfigsEnum, WarningsConfigsMap } from '../../components/WarningModal/constants';

const getGptButtonText = (status) => {
  switch (status) {
    case loadStatus.LOAD:
      return 'Generating SQL';
    case loadStatus.SUCCESS:
      return 'Regenerate SQL';
    case loadStatus.ERROR:
      return 'Try again';
    default:
      return 'Generate SQL';
  }
};

const defaultValues = {
  string: '',
  number: 0,
  object: {},
  array: [],
};

const emptyParam = {
  name: '', description: '', defaultValue: '', type: 'string', id: uid(8), required: 'no',
};

const validResponseExample = (val) => isJsonString(val) || val === '';
const maxAllowedLength = 64;

const capitalized = (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

const DEFAULT_DATE_FORMAT = 'dd.MM.yy HH:mm:ss';

export const formatISODate = (isoDate, dateFormat) => {
  if (!isoDate) return '-';
  return format(parseISO(isoDate.toISOString()), dateFormat ?? DEFAULT_DATE_FORMAT);
};

const SUBROUTING = {
  ACCESS: 'access',
  HISTORY: 'history',
};

const connectionTypeList = [
  {
    value: 'database',
    title: 'Database',
    icon: <DBIcon type="database" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'corezoid',
    title: 'Corezoid',
    icon: <DBIcon type="corezoid" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'AWS',
    title: 'AWS',
    icon: <DBIcon type="AWS" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'chatGPT',
    title: 'ChatGPT',
    icon: <DBIcon type="chatGPT" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
  {
    value: 'none',
    title: 'None',
    icon: <DBIcon type="database" style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
  },
];

const ApisModifySchema = Yup.object().shape({
  name: Yup.string().min(2).max(64).matches(/^(?!\s)(?!.* {2})/)
    .required('Name required'),
  endpoint: Yup.string().min(2).max(64).matches(/^[\/{}_&=?\-\w]+$/g)
    .required('Endpoint required'),
  method: Yup.string().required('Method required'),
  authorizationMethod: Yup.string().required('AuthMethod required'),
  authLogin: Yup.string()
    .when('authorizationMethod', {
      is: (authorizationMethod) => authorizationMethod === 'basic',
      then: Yup.string().required('Login required'),
    }),
  authPassword: Yup.string()
    .when('authorizationMethod', {
      is: (authorizationMethod) => authorizationMethod === 'basic',
      then: Yup.string().required('Password required'),
    }),
  connectionId: Yup.string()
    .when('connectionType', {
      is: (connectionType) => ['database', 'corezoid', 'chatGPT', 'AWS'].includes(connectionType),
      then: Yup.string().required('connectionId required'),
    }),
  RPS: Yup.number()
    .required('RPS required')
    .typeError('RPS must be a number')
    .test('isInteger', 'Not integer value provided', (value) => Number.isInteger(value))
    .max(100, 'Max limit'),
  processId: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'corezoid',
      then: Yup.string().required('Process Id required'),
    }),
  bucketName: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'AWS',
      then: Yup.string().required('Bucket Name required'),
    }),
  fileName: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'AWS',
      then: Yup.string().required('File Name required'),
    }),
  s3Method: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'AWS',
      then: Yup.string().required('S3 method required'),
    }),
  file: Yup.string()
    .when(['connectionType', 's3Method'], {
      is: (connectionType, s3Method) => connectionType === 'AWS' && ['put', 'post'].includes(s3Method),
      then: Yup.string().required('File required'),
    }),
  query: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'database',
      then: Yup.string().required('SQL required'),
    }),
  gptData: Yup.string()
    .when('connectionType', {
      is: (connectionType) => connectionType === 'chatGPT',
      then: Yup.string().required('Query required'),
    }),
  gptConnectionId: Yup.string()
    .when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string().required('ConnectionId required'),
    }),
  gptRequest: Yup.string()
    .when(['connectionType', 'sqlType'], {
      is: (connectionType, sqlType) => connectionType === 'database' && sqlType === 'chatGptSql',
      then: Yup.string().required('gptRequest required'),
    }),
  params: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Name required'),
        type: Yup.string().required('Type required'),
      }),
    ),
});

let timingAddParametr = new Date().getTime();

function ApisModify({
  updateEndpoint,
  getConnections,
  connections,
  currentUser,
  getActiveEndpoint,
  activeEndpoint,
  createToast,
  activeProject,
  getAccessUsers,
  addAccess,
  testEndpoint,
  roles,
  dataTypes,
  isRoleAction,
  gptSendRequest,
  getHistoryData,
  historyList,
  getUserData,
  createVersion,
  displayVersion,
  activateVersion,
  diffVersion,
  activateOriginVersion,
  storedInviteList,
}) {
  const { data: connectionsData = [] } = connections || {};
  const [gptResponseStatus, setGptResponseStatus] = useState(loadStatus.INITIAL);
  const [chatGptQueryTriggered, setChatGptQueryTrigerred] = useState(false);

  const [testDebugStatus, setTestDebugStatus] = useState(loadStatus.INITIAL);
  const navigate = useNavigate();
  const { shortName, endpointId } = useParams();
  const [shareModalOpened, setShareModalOpened] = useState(false);
  const [createVersionModalOpened, setCreateVersionOpened] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [parameterType, setParameterType] = useState('desription');
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [editorModeJs, setEditorModeJs] = useState(false);
  const listAPI = () => (activeEndpoint?.folder ? navigate(`/${shortName}/endpoints/folder/${activeEndpoint?.folder}`) : navigate(`/${shortName}/endpoints`));
  const [copiedText, copyText] = useCopyToClipboard();
  const viewRead = !isRoleAction({ key: 'endpoint', role: 'update', modelRole: activeEndpoint?.role });

  const [initSQLExist, setInitSQLExist] = useState(false);

  const [requestExample, setRequestExample] = useState('');
  const [requestExampleData, setRequestExampleData] = useState('');
  const [responseExample, setResponseExample] = useState('');
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [responseRequestCustom, setResponseRequestCustom] = useState({});
  const [responseExampleActiveCode, setResponseExampleActiveCode] = useState('200');

  const [typesList, setTypesList] = useState([
    { value: 'string', title: 'String' },
    { value: 'number', title: 'Number' },
  ]);

  const [gptResponseError, setGptResponseError] = useState(false);

  useEffect(() => {
    activeProject?.id && getConnections({ id: activeProject.id });
  }, [activeProject?.id]);

  useEffect(() => {
    setResponseRequestCustom({
      ...responseRequestCustom,
      [responseExampleActiveCode]: responseExample?.length ? parseIfJSON(responseExample) : '',
    });
  }, [responseExample]);

  useEffect(() => {
    const result = { ...responseRequestCustom, active: responseExampleActiveCode };
    setResponseRequestCustom(result);
    setResponseExample(result[responseExampleActiveCode] ? stringifyIfJSON(result[responseExampleActiveCode]) : '');
  }, [responseExampleActiveCode]);

  const dispatch = useDispatch();

  useEffect(() => {
    getAccessUsers({
      id: +endpointId,
      resource: 'endpoint',
    });
    return () => {
      dispatch(clearHistoryData());
    };
  }, []);

  useEffect(() => {
    setInviteList(storedInviteList || []);
  }, [storedInviteList]);

  useEffect(() => {
    getActiveEndpoint({ id: endpointId }, () => null);
  }, [endpointId]);

  const [areHandlersBlocked, setHandlersBlock] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      endpoint: '',
      method: '',
      s3Method: '',
      outputFormat: 'json',
      status: 'active',
      authLogin: '',
      authPassword: '',
      connectionId: '',
      connectionType: '',
      processId: '',
      query: '',
      params: [],
    },
    validationSchema: ApisModifySchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (v) => {
      if (v.connectionType === 'corezoid' && !v.processId) {
        return;
      }

      if ((v.connectionType === 'none' || !v.connectionType?.length) && !validResponseExample(responseExample)) {
        setSubmitTriggered(true);
        return;
      }

      const req = {
        auth: {
          authParams: v.authorizationMethod === 'basic' ? {
            user: v.authLogin,
            password: v.authPassword,
          } : {},
          authType: v.authorizationMethod,
        },
        folder: activeEndpoint?.folder || 0,
        connectionId: v.connectionId,
        name: v.name,
        description: v.description,
        httpRequestParams: v.params.map((p) => ({
          name: p.name,
          description: p.description,
          defaultValue: p.defaultValue,
          type: p.type,
          required: p.required === 'yes',
        })),
        method: v.method,
        path: v.endpoint,
        limit: +v.RPS,
        projectId: +activeProject.id,
        id: endpointId,
      };

      if (v.connectionType === 'corezoid') {
        req.connectionId = v.connectionId;
        req.processId = v.processId;
        req.httpCode = v.httpCode;
      } else if (v.connectionType === 'AWS') {
        req.connectionId = v.connectionId;
        req.bucketName = v.bucketName;
        req.folderName = v.folderName;
        req.fileName = v.fileName;
        req.file = v.file;
      } else if (v.connectionType === 'chatGPT') {
        req.connectionId = v.connectionId;
        req.query = v.gptData;
        req.context = v.context;
      } else if (v.connectionType === 'database') {
        req.connectionId = v.connectionId;
        req.dbQueryParams = {
          query: v.query,
        };
        req.gptConnectionId = v.sqlType === 'chatGptSql' ? v.gptConnectionId : 0;
        req.gptRequest = v.sqlType === 'chatGptSql' ? v.gptRequest : undefined;
        req.gptContext = v.sqlType === 'chatGptSql' ? v.gptContext : undefined;
      }

      const getResponseExample = () => {
        const result = Object.entries(responseRequestCustom).reduce((acc, [code, jsonResp]) => (jsonResp === '' ? acc : { ...acc, [code]: jsonResp }), {});
        return Object.keys(result).length === 1 ? {} : result;
      };

      if (!v.connectionType?.length || v.connectionType === 'none') {
        req.status = 'draft';
        req.responseExample = getResponseExample();
      } else {
        req.responseExample = {};
        req.status = 'active';
      }

      updateEndpoint(req, () => getActiveEndpoint({ id: endpointId }, () => null));
    },
  });

  const {
    submitForm, handleChange, setFieldValue, validateForm, values, errors, setValues,
  } = formik;

  const setFieldValueProxy = (...arg) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setIsChange(true);
    setFieldValue(...arg);
  };

  const handleChangeProxy = (...arg) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setIsChange(true);
    handleChange(...arg);
  };

  const handleBlurField = (e) => {
    parseParams(e.target.value);
  };

  const changeTypesList = (it) => {
    let list = [
      { value: 'string', title: 'String' },
      { value: 'number', title: 'Number' },
    ];
    if (it && dataTypes && dataTypes[it]) {
      list = dataTypes[it].map((t) => ({ value: t.toLowerCase(), title: t }));
    }
    return list;
  };

  useEffect(() => {
    if (activeEndpoint?.id) {
      const {
        name, description, path, method, auth, dbQueryParams, httpRequestParams = [], context,
        connectionType, processId, connectionId, bucketName, folderName, fileName, file,
        query: gptData, limit, gptConnectionId, gptRequest, gptContext, httpCode, status, responseExample: resExample,
      } = activeEndpoint || {};
      const { query } = dbQueryParams || {};
      const { authParams, authType } = auth || {};
      const { user, password } = authParams || {};
      const transformed = httpRequestParams.map((f) => ({ ...f, id: uid(8), required: f.required ? 'yes' : 'no' }));

      const getConnectionType = (n) => {
        let name = 'database';
        if (n === 'corezoid') name = 'corezoid';
        if (n === 'AWS') name = 'AWS';
        if (n === 'chatGPT') name = 'chatGPT';
        return name;
      };

      setValues({
        name,
        description,
        endpoint: path,
        method,
        s3Method: connectionType === 'AWS' ? method : '',
        processId,
        httpCode,
        outputFormat: 'json',
        status: status === 'active' ? status : 'draft',
        authorizationMethod: authType,
        authLogin: user,
        authPassword: password,
        connectionId,
        instanceType: connectionType,
        connectionType: connectionId ? getConnectionType(connectionType) : (connectionType || 'none'),
        gptData: connectionType === 'chatGPT' ? gptData : '',
        bucketName,
        context,
        folderName,
        fileName,
        file,
        query,
        params: transformed,
        sqlType: gptConnectionId ? 'chatGptSql' : 'rawSql',
        gptConnectionId,
        gptRequest,
        gptContext,
        RPS: limit,
      });

      const iT = connectionsData?.find((c) => c.id === connectionId)?.type;
      if (iT) setTypesList(changeTypesList(iT));
      if (query && query.length) setInitSQLExist(true);
      if (resExample && (!isEmptyObject(resExample)) && (!connectionType?.length || connectionType === 'none')) {
        setResponseRequestCustom(resExample);
        setResponseExampleActiveCode(resExample.active);
        setResponseExample(resExample[resExample.active] ? stringifyIfJSON(resExample[resExample.active]) : '');
      } else {
        setResponseExample('');
      }
    }
  }, [activeEndpoint]);

  const addUserOfApiKey = () => {
    setShareModalOpened(true);
  };

  const toggleCreateVersionModal = () => setCreateVersionOpened((prev) => !prev);

  const [warningConfig, setWarningConfig] = useState(ConfigsEnum.CREATE);
  const [versionToActivate, setVersionToActivate] = useState(0);

  const onOpenWarningModal = (warningConfigName, version = 0) => {
    setWarningConfig(warningConfigName);
    toggleCreateVersionModal();
    if (warningConfigName === ConfigsEnum.ACTIVATION) {
      setVersionToActivate(version);
    }
  };

  // const saveInviteList = (data) => {
  //   const filtered = data.accessList.filter((el) => el.userEmail !== currentUser.email);
  //   const unique = Object.values(
  //     [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.userEmail]: obj }), {}),
  //   );
  //   setInviteList(unique);
  //   setShareModalOpened(false);
  // };

  // const handeChangeRole = (email, role) => {
  //   const newInviteList = inviteList.map((el) => (el.userEmail === email ? { ...el, roleId: role } : el));
  //   setInviteList(newInviteList);
  // };

  const addParameter = () => {
    if (new Date().getTime() - timingAddParametr > 500) {
      const prev = values.params;
      setFieldValueProxy('params', [...prev, { ...emptyParam, id: uid(8) }]);
    }
  };

  const updateRequestExample = (name, value, id) => {
    const requestData = {};
    let oldRequest = {};
    if (requestExample.length) {
      try {
        parsed = JSON.parse(requestExample);
        oldRequest = parsed;
      } catch (e) {
        console.log('failed to parse JSON from input');
      }
    }
    values?.params?.forEach((p) => {
      let newValue = oldRequest[p.name];
      const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

      if (!newValue || isEmptyArray(newValue) || isEmptyObject(newValue)) {
        newValue = p.defaultValue || defaultValues[p.type];
      }

      if (name && p.name === name) {
        requestData[p.name] = value;
      } else if (id && p.id === id) {
        requestData[value] = p.defaultValue;
        delete requestData[p.name];
      } else {
        requestData[p.name] = newValue;
      }
    });
    const newR = JSON.stringify(requestData);
    if (newR !== requestExampleData) {
      setRequestParams(newR);
    }
  };

  useEffect(() => {
    updateRequestExample();
  }, [values.params.length]);

  const deleteAccess = (user) => {
    const newInviteList = inviteList.filter((el) => el.email !== user.email);
    setInviteList(newInviteList);
    setRemoveModalOpened(false);
    setActiveUser({});
  };

  const handeChangeRole = (user, role) => {
    if (role === 'delete') {
      setRemoveModalOpened(true);
      setActiveUser(user);
      return;
    }

    const newInviteList = inviteList.map((el) => (el.email === user.email ? { ...el, role } : el));
    addAccess({
      id: +endpointId,
      resource: 'endpoint',
      projectId: +activeProject.id,
      role,
      userId: user.id,
    }, () => {
      getAccessUsers({ id: +endpointId, resource: 'endpoint' });
      setInviteList(newInviteList);
    });
  };

  const connectionsList = (dbs) => {
    if (dbs) {
      return [...dbs.map((d) => ({
        value: d.id,
        title: `${d.instanceName} (${typesAPI[d.type] || d.type})`,
        icon: <DBIcon type={d.type} style={{ marginRight: '12px', width: '24px', height: '24px' }} />,
      }))];
    }
    return [];
  };

  const connectionsCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type !== 'corezoid' && d.type !== 'AWS' && d.type !== 'chatGPT') : [];
  const chatGptCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type === 'chatGPT') : [];
  const corezoidCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type === 'corezoid') : [];
  const awsCollection = connectionsData && connectionsData.length ? connectionsData.filter((d) => d.type === 'AWS') : [];

  const handeChangeConnectionType = (key, value) => {
    if (value === 'corezoid' && corezoidCollection.length > 0) {
      setFieldValueProxy('connectionId', corezoidCollection[0].id);
      changeInstanceType(corezoidCollection[0].id);
    } else if (value === 'AWS' && awsCollection.length > 0) {
      setFieldValueProxy('connectionId', awsCollection[0].id);
      changeInstanceType(awsCollection[0].id);
    } else if (value === 'chatGPT' && chatGptCollection.length > 0) {
      setFieldValueProxy('connectionId', chatGptCollection[0].id);
      changeInstanceType(chatGptCollection[0].id);
    } else if (value === 'database' && connectionsCollection.length > 0) {
      setFieldValueProxy('connectionId', connectionsCollection[0].id);
      changeInstanceType(connectionsCollection[0].id);
    } else {
      setFieldValueProxy('connectionId', '');
    }
    setFieldValueProxy([key], value);
  };

  const handleChangeEndpoint = (key, value) => {
    setIsChange(true);
    let newValue = value;
    if (value && value[0] !== '/') {
      newValue = `/${value}`;
    }
    const validationRegex = /^[\/{}_&=?\-\w]+$/g;
    if (value === '' || (validationRegex.test(value) && value.length <= maxAllowedLength)) {
      setFieldValueProxy([key], newValue.replace(/\s/g, ''));
    }
  };

  const handleChangeName = ({ target }) => {
    const validationRegex = /^(?!\s)(?!.* {2})/;
    if (target.value === '' || (validationRegex.test(target.value) && target.value.length <= maxAllowedLength)) {
      setFieldValueProxy(target.name, target.value);
      setIsChange(true);
    }
  };

  useEffect(() => {
    if (connectionsCollection?.length && values.connectionId) {
      const find = connectionsCollection.find((el) => el.id === values.connectionId);
      const isMongo = find && find.type === 'mongo';

      setEditorModeJs(isMongo);
    }
  }, [connectionsCollection, values.connectionId]);

  const parseParams = (valueEdit) => {
    const { params } = values;
    const par = paramsParser(valueEdit);
    const unique = [...new Set(par)];
    const newParams = [];
    let resulting = [];
    unique.forEach((el) => {
      const exist = params.find((p) => p.name === el);
      if (!exist) {
        newParams.push({
          name: el, description: '', defaultValue: '', type: 'string', id: uid(8), required: 'no',
        });
      }
    });
    if (newParams.length === 0 && params[0]?.name === '') {
      resulting = params;
    } else if (params.length === 1 && params[0].name === '') {
      timingAddParametr = new Date().getTime();
      resulting = newParams;
    } else {
      timingAddParametr = new Date().getTime();
      resulting = [...params, ...newParams];
    }
    if (JSON.stringify(resulting) !== JSON.stringify(values.params)) {
      setFieldValueProxy('params', resulting);
    }
  };

  const sendDebugRequest = async () => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length) return;
      let requestData = requestExampleData
        .replace(/\n/g, '')
        .replace(/ /g, '');

      if (requestExampleData.length) {
        try {
          requestData = JSON.parse(requestData);
        } catch (e) {
          console.log('failed to parse JSON from input');
        }
      }

      setTestDebugStatus(loadStatus.LOAD);

      const {
        authPassword, authLogin, connectionId,
        description, query, params,
        method, status, name, endpoint,
        connectionType, processId, gptData, context,
        bucketName, folderName, fileName, file, sqlType,
        gptConnectionId, gptRequest, gptContext, authorizationMethod,
        httpCode,
      } = values;
      const data = {
        api: {
          description,
          httpRequestParams: params.map((p) => ({
            name: p.name,
            description: p.description,
            defaultValue: p.defaultValue,
            type: p.type,
            required: p.required === 'yes',
          })),
          folder: activeEndpoint?.folder || 0,
          method,
          status,
          name,
          auth: {
            authParams: authorizationMethod === 'basic' ? {
              user: authLogin,
              password: authPassword,
            } : {},
            authType: authorizationMethod,
          },
          projectId: +activeProject.id,
          path: endpoint,
        },
        request: requestData,
      };

      if (connectionType === 'corezoid') {
        data.api.connectionId = connectionId;
        data.api.processId = processId;
        data.api.httpCode = httpCode;
      } else if (connectionType === 'AWS') {
        data.api.connectionId = connectionId;
        data.api.bucketName = bucketName;
        data.api.folderName = folderName;
        data.api.fileName = fileName;
        data.api.file = file;
      } else if (connectionType === 'chatGPT') {
        data.api.connectionId = connectionId;
        data.api.dbQueryParams = {
          query: gptData,
        };
        data.api.query = gptData;
        data.api.context = context;
      } else if (connectionType === 'database') {
        data.api.connectionId = connectionId;
        data.api.dbQueryParams = {
          query,
        };
        data.api.gptConnectionId = sqlType === 'chatGptSql' ? gptConnectionId : 0;
        data.api.gptRequest = sqlType === 'chatGptSql' ? gptRequest : undefined;
        data.api.gptContext = sqlType === 'chatGptSql' ? gptContext : undefined;
      }

      if (!connectionType?.length || connectionType === 'none') {
        data.api.status = 'draft';
        if (responseRequestCustom.length) data.api.responseExample = parseIfJSON(responseRequestCustom);
      } else {
        data.api.responseExample = {};
        data.api.status = 'active';
      }

      testEndpoint(data, (stats, response) => {
        if (stats !== 'ERROR') setResponseExample(stringifyIfJSON(parseIfJSON(response)));
        if (stats === 'ERROR') setResponseExample(formatingJSONToRender(response));
        setTestDebugStatus(loadStatus[stats]);
      });
    });
  };

  const setRequestParams = (param) => {
    setTestDebugStatus(loadStatus.INITIAL);
    setRequestExampleData(param);
    setRequestExample(formatingJSONToRender(param));
  };

  const renderDebugReqStatus = (st) => {
    switch (st) {
      case loadStatus.INITIAL:
        return <ActionText title="Send test request" onClick={sendDebugRequest} />;
      case loadStatus.SUCCESS:
        return (
          <>
            <p className={shared.successText}>Success</p>
            <IconChecked />
          </>
        );
      case loadStatus.ERROR:
        return (
          <>
            <p className={shared.errorText}>Error</p>
            <IconAlert />
          </>
        );
      case loadStatus.LOAD:
        return (
          <>
            <ActionText disabled title="Sending test request..." onClick={sendDebugRequest} />
            <Loader disabled />
          </>
        );
      default:
        return <ActionText title="Send test request" onClick={sendDebugRequest} />;
    }
  };

  const getRoles = (modelRole) => {
    const listRoles = [];
    const filredRoles = getProjectRoles(roles, activeProject.id).filter((r) => r.name !== 'owner');
    if (isRoleAction({ key: 'endpoint', role: 'share', modelRole: activeEndpoint?.role })) {
      listRoles.push(...filredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name })));
    } else {
      listRoles.push(...filredRoles.map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name, disabled: true })));
    }
    if (isRoleAction({ key: 'endpoint', role: 'delete', modelRole: activeEndpoint?.role })) {
      listRoles.push({ value: 'delete', title: 'Delete', hidden: currentUser.role === 'viewer' });
    }
    return listRoles;
  };

  const changeInstanceType = (id) => {
    const iT = connectionsData?.find((c) => c.id === id)?.type;
    if (!iT) return;
    setFieldValueProxy('instanceType', iT);
    const newParams = [];
    const checkType = (oldType) => {
      let type = 'string';
      if (dataTypes[iT]?.findIndex((t) => t.toLowerCase() === oldType) !== -1) {
        type = oldType;
      }
      return type;
    };
    values.params.forEach((p) => newParams.push({ ...p, type: checkType(p.type) }));
    setFieldValueProxy('params', newParams);
    setTypesList(changeTypesList(iT));
  };

  const saveInviteList = (data) => {
    // const filtered = data.filter(el => el.id);
    // const unique = Object.values(
    //   [...inviteList, ...filtered].reduce((acc, obj) => ({ ...acc, [obj.email]: obj }), {}),
    // );
    // setInviteList(unique);
    getAccessUsers({ id: +endpointId, resource: 'endpoint' });
    setShareModalOpened(false);
  };

  const handleGenerateSQL = () => {
    setGptResponseStatus(loadStatus.LOAD);
    gptSendRequest({
      gptConnectionId: values.gptConnectionId,
      connectionId: values.connectionId,
      query: values.gptRequest,
      context: values.gptContext,
    }, (result, res) => {
      setChatGptQueryTrigerred(true);
      if (result !== 'ERROR') {
        const sqlTags = parseSqlTags(res);
        setGptResponseStatus(loadStatus.SUCCESS);
        setFieldValueProxy('chatGptResponse', res);
        setFieldValueProxy('query', sqlTags);
        setGptResponseError(!sqlTags.length);
      } else {
        setGptResponseStatus(loadStatus.ERROR);
        setFieldValueProxy('chatGptResponse', 'Error!');
        setGptResponseError(true);
      }
    });
  };

  const [subroute, setSubroute] = useState(SUBROUTING.ACCESS);

  const [openedHistory, setOpenedHistory] = useState({});
  const [knownUsers, setKnownUsers] = useState([]);

  const getUnknownUsersIds = (fatherId) => {
    const allChildUsersIds = historyList.children.reduce((acc, val) => {
      if (val.father === fatherId) {
        return [...acc, val.author];
      }
      return acc;
    }, []);
    const allUniqueChildUsersIds = [...new Set(allChildUsersIds)];
    const usersToRequest = allUniqueChildUsersIds.filter((element) => knownUsers.indexOf(element) < 0);
    setKnownUsers((prev) => [...prev, ...usersToRequest]);
    return usersToRequest;
  };

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [commitId, setCommitId] = useState(null);

  const clearTempState = () => {
    setIsReadOnly(false);
    setOpenedHistory({});
    setKnownUsers([]);
    setCommitId(null);
  };

  const handlerCheckDetails = (versionId, versionAuthorId, fatherId) => {
    setOpenedHistory((prev) => {
      const wasOpen = prev[versionId];
      if (wasOpen) {
        activateOriginVersion();
        setIsReadOnly(false);
        setCommitId(null);
        return {};
      }
      return { [versionId]: versionAuthorId };
    });
    if (!Object.keys(openedHistory).some((el) => el === versionId.toString())) {
      const usersArray = getUnknownUsersIds(fatherId);
      usersArray.map((userId) => getUserData({ id: userId }));
    }
  };

  const handelShowCommit = (commitID, ignoreDiffReq) => {
    if (commitID !== commitId && historyList.children.length > 1 && !ignoreDiffReq) {
      diffVersion({ id: +endpointId, resource: 'endpoint', commitId: commitID });
    }
    setCommitId((prev) => (prev === commitID ? null : commitID));
  };

  const onCreateNewVersion = () => {
    const reqData = { id: +endpointId, resource: 'endpoint' };
    createVersion(reqData, () => {
      clearTempState();
      getActiveEndpoint({ id: endpointId }, () => {
        getHistoryData(reqData, toggleCreateVersionModal);
      });
    });
  };

  const onActivateClick = () => {
    activateVersion({ id: +endpointId, resource: 'endpoint', commitId }, () => {
      clearTempState();
      getActiveEndpoint({ id: +endpointId }, () => {
        getHistoryData({ id: +endpointId, resource: 'endpoint' }, toggleCreateVersionModal);
      });
    });
  };

  const getOnSaveWarningCallback = () => {
    switch (warningConfig) {
      case ConfigsEnum.ACTIVATION:
        return onActivateClick;
      default:
        return onCreateNewVersion;
    }
  };

  const handleActivateCommit = (id) => {
    const commit = historyList.children.find((commit) => commit.id === id);
    if (commit) {
      setIsReadOnly(true);
      displayVersion(commit.data);
    }
  };

  return (
    <div className={shared.page}>
      <div className={shared.header}>
        <div className={shared.headerTitleGroup}>
          <h1 className={shared.headerTitle}>Modify API</h1>
          <p className={shared.headerDescription}>Version 1.2.0</p>
        </div>
        <div className={shared.headerButtonGroup}>
          <Button title="Cancel" type="secondary" onClick={listAPI} />
          <Button
            title="Modify API"
            onClick={submitForm}
            disabled={isReadOnly || viewRead || !isChange}
          />
        </div>
      </div>
      <div className={shared.body}>
        <div className={shared.mainContent}>
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>API Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Name</span>
                <Input
                  placeholder="Enter API name"
                  handleChange={handleChangeName}
                  value={values.name}
                  name="name"
                  error={!!errors.name && values.name?.length < 2}
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Description (Optional)</span>
                <Input
                  placeholder="Describe API"
                  handleChange={handleChangeProxy}
                  value={values.description}
                  name="description"
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Endpoint</span>
                <Input
                  placeholder="/endpoint"
                  handleChange={(e) => handleChangeEndpoint('endpoint', e.target.value)}
                  value={values.endpoint}
                  name="endpoint"
                  iconRight={values.endpoint && 'copy'}
                  handleAction={() => {
                    copyText(`${backendHost}/${activeProject.shortName}${values.endpoint}`) && createToast({ type: 'success', text: 'Copied' });
                  }}
                  error={!!errors.endpoint && values.endpoint?.length < 2}
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>HTTP method</span>
                <Select
                  list={[
                    { value: 'get', title: 'GET' },
                    { value: 'post', title: 'POST' },
                    { value: 'put', title: 'PUT' },
                    { value: 'patch', title: 'PATCH' },
                    { value: 'delete', title: 'DELETE' },
                  ]}
                  value={values.method}
                  handleChange={setFieldValueProxy}
                  name="method"
                  placeholder="Select method"
                  error={!!errors.method && !values.method}
                  disabled={viewRead || isReadOnly || (values.s3Method?.length && values.connectionType === 'AWS')}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Output format</span>
                <Select
                  list={[
                    { value: 'json', title: 'JSON' },
                  ]}
                  value={values.outputFormat}
                  placeholder="Select format"
                  handleChange={setFieldValueProxy}
                  name="outputFormat"
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Status</span>
                <Select
                  list={[
                    { value: 'active', title: 'Active' },
                    { value: 'draft', title: 'Draft' },
                    { value: 'inactive', title: 'Inactive' },
                  ]}
                  value={values.status}
                  handleChange={setFieldValueProxy}
                  name="status"
                  disabled
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Authorization</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Authorization method</span>
                <Select
                  list={[{ value: 'basic', title: 'Basic' }, { value: 'none', title: 'None' }]}
                  value={values.authorizationMethod}
                  handleChange={setFieldValueProxy}
                  name="authorizationMethod"
                  placeholder="Choose authorization method"
                  disabled={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper} />
              {values.authorizationMethod === 'basic' ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Login</span>
                    <Input
                      placeholder="Enter login"
                      handleChange={handleChangeProxy}
                      value={values.authLogin}
                      iconRight={values.authLogin && 'copy'}
                      handleAction={() => {
                        copyText(values.authLogin) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      name="authLogin"
                      error={!!errors.authLogin && !values.authLogin}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Password</span>
                    <Input
                      placeholder="Enter password"
                      handleChange={handleChangeProxy}
                      value={values.authPassword}
                      iconRight={values.authPassword && 'copy'}
                      isPassword
                      handleAction={() => {
                        copyText(values.authPassword) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      name="authPassword"
                      id="authPassword"
                      type="password"
                      error={!!errors.authPassword && !values.authPassword}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Connection Settings</h4>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Connection type</span>
                <Select
                  list={connectionTypeList}
                  value={values.connectionType}
                  placeholder="Choose connetion type"
                  handleChange={(key, value) => {
                    handeChangeConnectionType(key, value);
                    // method and s3 method must be equeal when AWS connection used
                    if (value === 'AWS' && values.s3Method?.length) setFieldValueProxy('method', values.s3Method);
                  }}
                  name="connectionType"
                  error={!!errors.connectionType && !values.connectionType}
                  disabled={viewRead || isReadOnly}
                />
              </div>
              {(values.connectionType === 'AWS' && awsCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(awsCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>S3 method</span>
                    <Select
                      list={[
                        { value: 'post', title: 'Save file' },
                        { value: 'get', title: 'Search file' },
                        { value: 'put', title: 'Update file' },
                        { value: 'delete', title: 'Delete file' },
                      ]}
                      value={values.s3Method}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        setFieldValueProxy('method', value);
                      }}
                      name="s3Method"
                      placeholder="Select method"
                      error={!!errors.s3Method && !values.s3Method}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Bucket name</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingBucket.html"
                        title={(
                          <ActionIcon
                            id="bucketName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="Bucket name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.bucketName}
                      name="bucketName"
                      error={!!errors.bucketName && !values.bucketName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Folder name</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html"
                        title={(
                          <ActionIcon
                            id="folderName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="Folder name"
                      handleChange={handleChangeProxy}
                      onBlur={handleBlurField}
                      value={values.folderName}
                      name="folderName"
                      error={!!errors.folderName && !values.folderName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>File name</span>
                      <ActionText
                        target="https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html"
                        title={(
                          <ActionIcon
                            id="fileName_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open AWS S3 documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <Input
                      placeholder="File name"
                      handleChange={handleChangeProxy}
                      value={values.fileName}
                      onBlur={handleBlurField}
                      name="fileName"
                      error={!!errors.fileName && !values.fileName}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  {(values.s3Method === 'put' || values.s3Method === 'post') ? (
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>File</span>
                      </span>
                      <Input
                        placeholder="File"
                        handleChange={handleChangeProxy}
                        value={values.file}
                        onBlur={handleBlurField}
                        name="file"
                        error={!!errors.file && !values.file}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>
                  ) : null}
                </>
              ) : null}
              {(values.connectionType === 'corezoid' && corezoidCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(corezoidCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Corezoid Process ID</span>
                    <Input
                      placeholder="Enter process ID"
                      handleChange={handleChangeProxy}
                      value={values.processId}
                      name="processId"
                      iconRight={values.processId && 'copy'}
                      handleAction={() => {
                        copyText(values.processId) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.processId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Custom HTTP code for Response</span>
                    <Input
                      placeholder="Enter Custom HTTP code value"
                      handleChange={handleChangeProxy}
                      value={values.httpCode}
                      name="httpCode"
                      iconRight={values.httpCode && 'copy'}
                      handleAction={() => {
                        copyText(values.httpCode) && createToast({ type: 'success', text: 'Copied' });
                      }}
                      error={!!errors.httpCode}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                </>
              ) : null}
              {(values.connectionType === 'chatGPT' && chatGptCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(chatGptCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>ChatGPT Request</span>
                      <ActionText
                        target="https://platform.openai.com/docs/introduction"
                        title={(
                          <ActionIcon
                            id="request_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open Chat GPT documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <CodeEditor
                      value={values.gptData}
                      handleChange={(value) => setFieldValueProxy('gptData', value)}
                      handleBlurCallback={parseParams}
                      name="gptData"
                      type="json"
                      placeholder="Enter request to ChatGPT"
                      error={!!errors.gptData && !values.gptData}
                      readOnly={viewRead || isReadOnly}
                      style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                    />
                  </div>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>
                      <span>Context</span>
                      <ActionText
                        target="https://platform.openai.com/docs/introduction"
                        title={(
                          <ActionIcon
                            id="context_tooltip"
                            icon="info"
                            style={{ marginLeft: '8px' }}
                            tooltip={{
                              content: 'Open Chat GPT documentation',
                              place: 'right',
                            }}
                          />
                        )}
                      />
                    </span>
                    <CodeEditor
                      value={values.context}
                      handleChange={(value) => setFieldValueProxy('context', value)}
                      handleBlurCallback={parseParams}
                      name="context"
                      type="json"
                      placeholder="Context"
                      error={!!errors.context && !values.context}
                      readOnly={viewRead || isReadOnly}
                      style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                    />
                  </div>
                </>
              ) : null}
              {(values.connectionType === 'database' && connectionsCollection) ? (
                <>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>Connection instance</span>
                    <Select
                      list={[
                        ...connectionsList(connectionsCollection),
                        { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                      ]}
                      handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                      value={values.connectionId}
                      placeholder="Select instance"
                      handleChange={(key, value) => {
                        setFieldValueProxy(key, value);
                        changeInstanceType(value);
                      }}
                      name="connectionId"
                      error={!!errors.connectionId && !values.connectionId}
                      disabled={viewRead || isReadOnly}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {(values.connectionType === 'database') ? (
              <>
                {!editorModeJs ? (
                  <div className={cn(shared.sectionContainer)}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>Request type</span>
                      <Select
                        list={[
                          { value: 'rawSql', title: 'Raw SQL' },
                          { value: 'chatGptSql', title: 'ChatGPT-generated SQL' },
                        ]}
                        value={values.sqlType}
                        placeholder="Select SQL mode"
                        handleChange={(key, value) => {
                          setFieldValueProxy(key, value);
                        }}
                        name="sqlType"
                        error={!!errors.sqlType && !values.sqlType}
                        disabled={viewRead || isReadOnly}
                      />
                    </div>

                    {(values.sqlType === 'chatGptSql' && !editorModeJs) ? (
                      <div className={shared.inputWrapper}>
                        <span className={shared.inputLabel}>ChatGPT instance</span>
                        <Select
                          list={[
                            ...connectionsList(chatGptCollection),
                            { value: 'new', component: <ActionText title="Create new connection" target={`/${shortName}/connections/create`} /> },
                          ]}
                          value={values.gptConnectionId}
                          placeholder="Select instance"
                          handleToggleOptions={() => getConnections({ id: activeProject?.id })}
                          handleChange={(key, value) => {
                            setFieldValueProxy(key, value);
                          }}
                          name="gptConnectionId"
                          error={!!errors.gptConnectionId && !values.gptConnectionId}
                          disabled={viewRead || isReadOnly}
                        />
                      </div>
                    ) : <div /> }
                  </div>
                ) : null}
                {(values.sqlType === 'chatGptSql' && !editorModeJs) ? (
                  <div className={shared.sectionContainer}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>ChatGPT Request</span>
                        <ActionText
                          target="https://platform.openai.com/docs/introduction"
                          title={(
                            <ActionIcon
                              id="database_request_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Open Chat GPT documentation',
                                place: 'right',
                              }}
                            />
                          )}
                        />
                      </span>
                      <CodeEditor
                        readOnly={viewRead || isReadOnly}
                        value={values.gptRequest}
                        handleChange={(value) => setFieldValueProxy('gptRequest', value)}
                        handleBlurCallback={parseParams}
                        name="gptRequest"
                        type="json"
                        placeholder="Enter request to ChatGPT"
                        error={!!errors.gptRequest && !values.gptRequest}
                        style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                      />
                    </div>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>
                        <span>Context</span>
                        <ActionText
                          target="https://platform.openai.com/docs/introduction"
                          title={(
                            <ActionIcon
                              id="database_context_tooltip"
                              icon="info"
                              style={{ marginLeft: '8px' }}
                              tooltip={{
                                content: 'Open Chat GPT documentation',
                                place: 'right',
                              }}
                            />
                          )}
                        />
                      </span>
                      <CodeEditor
                        readOnly={viewRead || isReadOnly}
                        value={values.gptContext}
                        handleChange={(value) => setFieldValueProxy('gptContext', value)}
                        handleBlurCallback={parseParams}
                        name="gptContext"
                        type="json"
                        placeholder="Context"
                        error={!!errors.gptContext && !values.gptContext}
                        style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                      />
                    </div>
                  </div>
                ) : null}
                {(values.sqlType === 'chatGptSql' && !editorModeJs) && (
                  <div className={cn(shared.sectionContainer, shared.wide)}>
                    <div className={shared.inputWrapper}>
                      <span className={shared.inputLabel}>ChatGPT Response</span>
                      {!chatGptQueryTriggered ? (
                        <Input
                          disabled
                          value=""
                          placeholder="Waiting for request..."
                          handleChange={() => {}}
                          name="chatGptResponseFake"
                        />
                      ) : (
                        <CodeEditor
                          readOnly
                          value={values.chatGptResponse}
                          handleChange={(value) => setFieldValueProxy('chatGptResponse', value)}
                          name="chatGptResponse"
                          type="json"
                          placeholder=""
                          error={!!errors.chatGptResponse && !values.chatGptResponse}
                          style={{ maxWidth: 'calc(100vw - 651px)', minWidth: '400px' }}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className={cn(shared.sectionContainer, shared.wide)}>
                  <div className={shared.inputWrapper}>
                    <span className={shared.inputLabel}>{editorModeJs ? 'Query' : 'SQL'}</span>
                    {values.sqlType && values.sqlType === 'chatGptSql' && (!chatGptQueryTriggered && !initSQLExist) ? (
                      <Input
                        disabled
                        value=""
                        placeholder="Waiting for request..."
                        handleChange={() => {}}
                        name="queryFake"
                      />
                    ) : (
                      <CodeEditor
                        readOnly={viewRead || isReadOnly}
                        value={values.query}
                        handleChange={(value) => {
                          setFieldValueProxy('query', value);
                        }}
                        handleBlurCallback={parseParams}
                        name="query"
                        type={editorModeJs ? 'javascript' : 'sql'}
                        placeholder={sqlPlaceholder(editorModeJs, values.sqlType === 'chatGptSql', gptResponseError)}
                        error={!!errors.query && !values.query}
                        style={{ maxWidth: 'calc(100vw - 651px)', minWidth: '400px' }}
                      />
                    )}
                  </div>
                  {values.sqlType === 'chatGptSql' && !editorModeJs && (
                    <div className={cn(shared.chatGptButtonContainer)}>
                      <div className={shared.btnContainer}>
                        <Button
                          disabled={!((values.gptRequest?.length && values.gptConnectionId)) || gptResponseStatus === loadStatus.LOAD}
                          title={getGptButtonText(gptResponseStatus)}
                          onClick={() => handleGenerateSQL()}
                          loading={gptResponseStatus === loadStatus.LOAD}
                        />
                        {!!errors.query && !values.query && !chatGptQueryTriggered ? (
                          <p
                            className={shared.errorText}
                          >
                            Generate SQL to be able to create API
                          </p>
                        ) : null}
                      </div>
                      <div className={shared.statusContainer}>
                        {gptResponseStatus === loadStatus.SUCCESS && (
                        <>
                          <p className={shared.successText}>Success</p>
                          <IconChecked />
                        </>
                        )}
                        {gptResponseStatus === loadStatus.ERROR && (
                        <>
                          <p className={shared.errorText}>Error</p>
                          <IconAlert />
                        </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </section>
          <hr />
          <section className={shared.section}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={shared.sectionHeader}>Input Parameters</h4>
              {!viewRead && <ActionText title="Add parameter" onClick={addParameter} />}
            </div>
            {values?.params?.length > 0
              && (
              <div className={cn(shared.sectionContainer, shared.triple)} style={{ marginBottom: '-20px' }}>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Name</span>
                </div>
                <div className={shared.inputWrapper}>
                  <div className={shared.inputLabel}>
                    <ActionText
                      title="Description"
                      onClick={() => setParameterType('desription')}
                      style={parameterType !== 'desription' ? { color: '#93939F' } : {}}
                    />
                    {' / '}
                    <ActionText
                      title="Default value"
                      onClick={() => setParameterType('default')}
                      style={parameterType !== 'default' ? { color: '#93939F' } : {}}
                    />
                  </div>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Type</span>
                </div>
                <div className={shared.inputWrapper}>
                  <span className={shared.inputLabel}>Required</span>
                </div>
                <div className={cn(shared.inputWrapper, shared.btn)} />
              </div>
              )}
            {values.params
              && (
              <DndProvider backend={HTML5Backend}>
                <ParamentRows
                  params={values.params}
                  errors={errors}
                  parameterType={parameterType}
                  viewRead={viewRead}
                  handleUpdate={setFieldValueProxy}
                  typesList={typesList}
                  updateRequestExample={updateRequestExample}
                  requestExample={requestExample}
                />
              </DndProvider>
              )}
          </section>
          <hr />
          <section className={shared.section}>
            <h4 className={shared.sectionHeader}>Limits</h4>
            <div className={cn(shared.sectionContainer)}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Requests per second</span>
                <Input
                  placeholder="RPS up to 100"
                  handleChange={handleChangeProxy}
                  value={values.RPS}
                  name="RPS"
                  error={!!errors.RPS && (!values.RPS || !Number.isInteger(+values.RPS) || values.RPS > 100)}
                  style={{ maxWidth: 140 }}
                  disabled={viewRead || isReadOnly}
                />
              </div>
            </div>
          </section>
          <hr />
          <section className={shared.section}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className={shared.sectionHeader}>Debug console</h4>
              {(values.connectionType !== 'none' && values.connectionType?.length) ? (
                <div style={{
                  display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px',
                }}
                >
                  {renderDebugReqStatus(testDebugStatus)}
                </div>
              ) : null}
            </div>
            <div className={cn(shared.sectionContainer, shared.wide)}>
              <div className={shared.inputWrapper}>
                {shortName && values.endpoint && (
                  <DebugConsole
                    method={values.method}
                    url={`${backendHost}/${shortName}${values.endpoint}`}
                    params={requestExample}
                    onCopy={(text) => {
                      copyText(text) && createToast({ type: 'success', text: 'Copied' });
                    }}
                  />
                )}
              </div>
            </div>
            <div className={shared.sectionContainer}>
              <div className={shared.inputWrapper}>
                <span className={shared.inputLabel}>Request example</span>
                <CodeEditor
                  value={requestExample}
                  handleChange={(param) => setRequestExample(param)}
                  type="json"
                  placeholder=" "
                  style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                  readOnly={viewRead || isReadOnly}
                />
              </div>
              <div className={shared.inputWrapper}>
                <span className={cn(shared.inputLabel, shared.flexBetween)}>
                  <span>Response example</span>
                  {(values.connectionType === 'none' || !values.connectionType?.length) ? (
                    <span style={{
                      display: 'flex', justifyContent: 'space-between', flexDirection: 'row', whiteSpace: 'nowrap',
                    }}
                    >
                      <span>Status code:</span>
                      <Select
                        type="text"
                        list={[
                          { value: '200', title: '200' },
                          { value: '400', title: '400' },
                          { value: '404', title: '404' },
                          { value: '500', title: '500' },
                          { value: '503', title: '503' },
                        ]}
                        value={responseExampleActiveCode}
                        handleChange={(key, value) => {
                          setResponseExampleActiveCode(value);
                          setIsChange(true);
                        }}
                        name="responseExampleActiveCode"
                      />
                    </span>
                  ) : null}
                </span>
                <CodeEditor
                  value={responseExample}
                  handleChange={(v) => {
                    setResponseExample(v);
                    setIsChange(true);
                  }}
                  name="responseExample"
                  type="json"
                  readOnly={isReadOnly || !(values.connectionType === 'none' || !values.connectionType?.length)}
                  placeholder=" "
                  style={{ maxWidth: 'calc((100vw - 674px) / 2)', minWidth: '200px' }}
                  error={(values.connectionType === 'none' || !values.connectionType?.length) && submitTriggered && !validResponseExample(responseExample)}
                />
              </div>
            </div>
          </section>
        </div>
        <div className={shared.sidepanel}>
          <div className={shared.sidepanelHeading}>
            <div className={shared.subHeaders}>
              <h4
                onClick={() => setSubroute(SUBROUTING.ACCESS)}
                className={cn(shared.sectionHeader, { [shared.passive]: subroute !== SUBROUTING.ACCESS })}
              >
                Accesses
              </h4>
              {/* <h4 */}
              {/*  onClick={() => setSubroute(SUBROUTING.HISTORY)} */}
              {/*  className={cn(shared.sectionHeader, shared.clickable, { [shared.passive]: subroute !== SUBROUTING.HISTORY })} */}
              {/* > */}
              {/*  History */}
              {/* </h4> */}
            </div>
            {subroute === SUBROUTING.ACCESS && isRoleAction({ key: 'endpoint', role: 'share', modelRole: activeEndpoint?.role })
              && (
              <div>
                <ActionText title="Add user or API key" onClick={addUserOfApiKey} />
              </div>
              )}
            {subroute === SUBROUTING.HISTORY && (
              <div>
                <ActionText onClick={() => onOpenWarningModal(ConfigsEnum.CREATE)} title="Create new version" />
              </div>
            )}
          </div>
          <div className={shared.sidepanelTable}>
            <div className={shared.sidepanelTableHeading}>
              {subroute === SUBROUTING.ACCESS && (
                <div className={shared.lineHeader}>
                  <div>Name</div>
                  <div>Type</div>
                  <div>Role</div>
                </div>
              )}
              {subroute === SUBROUTING.HISTORY && (
                <div className={shared.lineHeader}>
                  <div>Version</div>
                  <div>Status</div>
                  <div>Time</div>
                </div>
              )}
            </div>
            <hr />
            {subroute === SUBROUTING.ACCESS && (
              <ul className={shared.sidepanelTableBody}>
                {inviteList?.map((item) => (
                  <li className={shared.historyLine} key={item.email}>
                    <div title={item.email}>
                      {item.email === currentUser.email ? (
                        <span>{`${item.firstName} ${item.lastName}`}</span>
                      ) : item.firstName || item.lastName ? (
                        <span>{`${item.firstName} ${item.lastName} (${item.email})`}</span>
                      ) : (
                        <span>{item.email}</span>
                      )}
                    </div>
                    <div>
                      {item.email ? (
                        <ChipsStatus title="User" type="user" />
                      ) : (
                        <ChipsStatus title="Invite" type="invite" />
                      )}
                    </div>
                    <div>
                      {viewRead ? (
                        <ChipsStatus title={listRolesName[item.role] || item.role} type="input" className={shared.chipsStatus} style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }} />
                      ) : item.role === 'owner' ? (
                        <ChipsStatus title="Owner" type="input" className={shared.chipsStatus} style={{ width: '90px', justifyContent: 'left', paddingLeft: '12px' }} />
                      ) : (
                        <Select
                          style={{ width: 90 }}
                          list={getRoles(item.role)}
                          value={item.role}
                          placeholder={item.role}
                          handleChange={(email, value) => handeChangeRole(item, value)}
                          name={item.email}
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {subroute === SUBROUTING.HISTORY && (
              <ul className={shared.sidepanelTableBody}>
                {historyList.parents?.map(({
                  data, number, created, author, id,
                }, idx, arr) => {
                  const isLastVersion = arr.length - idx === 1;
                  return (
                    <li key={data.id + idx}>
                      <div
                        className={cn(shared.historyLine, {
                          [shared.activeVersion]: openedHistory?.[id] === author,
                        })}
                        style={{ cursor: 'pointer' }}
                        onClick={() => !areHandlersBlocked && handlerCheckDetails(id, author, id)}
                        onKeyDown={() => !areHandlersBlocked && handlerCheckDetails(id, author, id)}
                        role="button"
                        tabIndex={0}
                      >
                        <div title={data.name}>
                          <span className={cn({ [shared.bold]: openedHistory?.[id] === author })}>
                            {idx === 0 ? 'Latest' : `Version ${arr.length - idx}` }
                          </span>
                        </div>
                        <div>
                          <ChipsStatus title={capitalized(data.status)} type={ChipsMap[data.status] || 'status'} />
                        </div>
                        <div>
                          {formatISODate(new Date(created))}
                        </div>
                        <div className={cn({
                          [shared.arrowUp]: openedHistory?.[id] === author,
                          [shared.arrowDown]: openedHistory?.[id] !== author,
                        })}
                        >
                          <ArrowIcon />
                        </div>
                      </div>
                      {openedHistory?.[id] === author && (
                        <div className={shared.commitSection}>
                          {historyList.children?.some((commit) => commit.father === id && commit.id === commitId) && (
                            <div className={shared.commitActiveBtn}>
                              <Button
                                style={{ width: 366 }}
                                title="Activate this version"
                                onClick={() => onOpenWarningModal(ConfigsEnum.ACTIVATION, arr.length - idx)}
                              />
                            </div>
                          )}
                          {historyList.children?.filter((historyItem) => historyItem.father === id).map((commit, index, arr) => {
                            const shouldIgnoreDiffRequest = isLastVersion && commit.id === arr[arr.length - 1].id;
                            return (
                              <li
                                key={commit.id}
                                onClick={() => {
                                  handleActivateCommit(commit.id);
                                  handelShowCommit(commit.id, shouldIgnoreDiffRequest);
                                }}
                              >
                                <div className={cn(shared.historyLine, {
                                  [shared.activeCommit]: commit.id === commitId,
                                })}
                                >
                                  <div>
                                    {commit?.userName || ''}
                                  </div>
                                  <div>
                                    <ChipsStatus title="Change" type="user" />
                                  </div>
                                  <div>
                                    {formatISODate(new Date(commit.created))}
                                  </div>
                                  <div style={{ cursor: 'pointer' }}>
                                    {commit.id === commitId ? (
                                      <BlueInfoIcon />
                                    ) : (
                                      <InfoIcon />
                                    )}
                                  </div>
                                </div>
                                {commit.id === commitId && commit.diff && (
                                  <CommitDiff data={commit.diff} />
                                )}
                                {arr[arr.length - 1].id !== commit.id && <hr />}
                              </li>
                            );
                          })}
                        </div>
                      )}
                      <hr />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        {shareModalOpened && (
          <InviteToResourceModal
            id={endpointId}
            onClose={() => setShareModalOpened(false)}
            onSubmit={saveInviteList}
            model={values}
            type="endpoint"
            inviteList={inviteList?.map((i) => i?.userEmail || i?.email) || []}
          />
        )}
        <Modal isOpen={createVersionModalOpened} onClose={toggleCreateVersionModal}>
          <WarningModal
            onCancel={toggleCreateVersionModal}
            onSave={getOnSaveWarningCallback()}
            headerText={WarningsConfigsMap[warningConfig].headerText}
            mainText={typeof WarningsConfigsMap[warningConfig].mainText === 'function'
              ? WarningsConfigsMap[warningConfig].mainText(warningConfig === ConfigsEnum.CREATE ? historyList.parents?.length + 1 : versionToActivate)
              : WarningsConfigsMap[warningConfig].mainText}
            mainTextSub={WarningsConfigsMap[warningConfig].mainTextSub}
            closeBtnText={WarningsConfigsMap[warningConfig].closeBtnText}
            saveBtnText={typeof WarningsConfigsMap[warningConfig].saveBtnText === 'function'
              ? WarningsConfigsMap[warningConfig].saveBtnText(warningConfig === ConfigsEnum.CREATE ? historyList.parents?.length + 1 : versionToActivate)
              : WarningsConfigsMap[warningConfig].saveBtnText}
          />
        </Modal>
        {removeModalOpened && (
          <RemoveAccessModal
            setIsOpen={setRemoveModalOpened}
            deleteAccessUser={deleteAccess}
            user={activeUser}
            resource="endpoint"
            id={endpointId}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({
  activeEndpoint: { activeEndpoint, inviteList },
  connections,
  activeProject,
  auth: { currentUser },
  directory: { dataTypes, authorizationMethods },
  roles,
  history: { historyList },
}) => ({
  roles,
  dataTypes,
  connections,
  activeProject,
  currentUser,
  activeEndpoint,
  authorizationMethods,
  historyList,
  storedInviteList: inviteList,
});

const mapDispatchToProps = (dispatch) => ({
  updateEndpoint: (data, callback) => dispatch(updateEndpointRequest(data, callback)),
  testEndpoint: (data, callback) => dispatch(testEndpointRequest(data, callback)),
  getConnections: (data) => dispatch(listConnectionsRequest(data)),
  getActiveEndpoint: (data, callback) => dispatch(activeEndpointRequest(data, callback)),
  createToast: (data, callback) => dispatch(createToastRequest(data, callback)),
  getAccessUsers: (data) => dispatch(getAccessUsersRequest(data)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
  gptSendRequest: (data, callback) => dispatch(gptRequestData(data, callback)),
  getHistoryData: (data, callback) => dispatch(historyRequest(data, callback)),
  getUserData: (data, callback) => dispatch(userRequest(data, callback)),
  diffVersion: (data, callback) => dispatch(diffVersionRequest(data, callback)),
  createVersion: (data, callback) => dispatch(createVersionRequest(data, callback)),
  displayVersion: (data) => dispatch(displayVersion(data)),
  activateVersion: (data, callback) => dispatch(overrideVersionRequest(data, callback)),
  activateOriginVersion: () => dispatch(activateOriginVersion()),
});

export default connect(mapStateToProps, mapDispatchToProps)(isRoleAction(ApisModify));
