@use "sass:map";
@import '../../styles/variables.scss';

.header {
  padding: 18px 20px;
  border-bottom: 1px solid map.get($colors-basic, "divider");
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-weight: map.get($font-weights, "semibold");
  }
}

.body {
  padding: 16px 20px 20px 20px;
  background-color: map.get($colors-basic, "sideMenu");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .mainContent {
    display: flex;
    flex-direction: column;
    padding: 8px 0 51px 0;
    position: relative;
  }

  .buttonsGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
  }
}


