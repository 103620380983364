/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { createProjectRequest } from '../../store/projects/actions';
import { invitesRequest } from '../../store/invites/actions';
import ChipsAction from '../ChipsAction';
import Button from '../Button/Button';
import Select from '../Select/Select';
import * as styles from './CreateProjectModal.module.scss';

const CreateProjectModalSchema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  email: Yup.string().email('Use valid email address'),
});

function CreateProjectModal({ setIsOpen, createProject, sendInvite }) {
  const [mainRendered, setMainRendered] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const [inputFocused, chnangeInputFocused] = useState(false);
  const [title, setTitle] = useState('Create new project');

  const {
    submitForm, handleChange, validateForm, setFieldValue, values, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 3,
      emailList: [],
    },
    validationSchema: CreateProjectModalSchema,
    onSubmit: (v) => {
      createProject({ name: v.name }, (id) => {
        if (!v.emailList.length) setIsOpen(false);
        if (v.emailList.length) {
          const invReq = {
            accessList: [...v.emailList.map((m) => ({ roleId: values.role, userEmail: m }))],
            projectId: id,
            resourceId: id,
            resource: 'project',
          };
          sendInvite(invReq, () => {
            setIsOpen(false);
          });
        }
      });
    },
  });

  const goForward = () => {
    setTitle(`Invite users to ${values.name}`);
    setActiveStep(activeStep + 1);
  };
  const goBack = () => {
    setTitle('Create new project');
    setActiveStep(activeStep - 1);
  };

  const handleContinue = async () => {
    const { name } = await validateForm();

    if (!name) goForward();
  };

  const saveEmail = (e) => {
    if (e.keyCode === 13 && !errors.email) {
      const newEmailList = [...new Set([...values.emailList, values.email])];
      setFieldValue('email', '');
      setFieldValue('emailList', newEmailList);
    }
  };

  const setOuterStyle = () => {
    chnangeInputFocused(true);
  };

  const removeOuterStyle = () => {
    chnangeInputFocused(false);
  };

  const openFonfirmCancel = () => setMainRendered(false);
  const closeFonfirmCancel = () => setMainRendered(true);

  const clearChip = (email) => {
    const newChips = values.emailList.filter((el) => el !== email);
    setFieldValue('emailList', newChips);
  };

  const nextStep = (e) => {
    if (e.keyCode === 13) {
      handleContinue();
    }
  };

  const renderMainModal = () => (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.steps}>
          Step
          {' '}
          <span className={styles.activeStep}>{activeStep}</span>
          {' '}
          of 2
        </div>
      </div>
      {activeStep === 2 ? (
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div className={cn(styles.inputWrapper, {
              [styles.inputFocused]: inputFocused,
              [styles.withControls]: true,
            })}
            >
              <span className={styles.inviteLabel}>To:</span>
              {values.emailList.map((email) => (
                <ChipsAction
                  title={email}
                  type="email"
                  onClose={clearChip}
                />
              ))}
              <input
                onKeyDown={saveEmail}
                autoComplete="off"
                type="text"
                name="email"
                autoFocus
                placeholder="Enter email"
                onChange={handleChange}
                value={values.email}
                onFocus={setOuterStyle}
                onBlur={removeOuterStyle}
              />
              <div className={styles.selectRole}>
                <Select
                  list={[
                    { value: 3, title: 'Editor' },
                    { value: 4, title: 'Viewer' },
                  ]}
                  value={values.role}
                  handleChange={handleChange}
                  name="role"
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button title="Back to previous" type="secondary" onClick={goBack} />
            <div className={styles.buttonGroupCol}>
              <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={openFonfirmCancel} />
              <Button style={{ width: 154, marginLeft: 12 }} title="Invite and create" onClick={() => submitForm()} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div className={cn(styles.inputWrapper, {
              [styles.inputFocused]: inputFocused,
              [styles.withControls]: false,
            })}
            >
              <input
                onKeyDown={nextStep}
                autoComplete="off"
                type="text"
                name="name"
                autoFocus
                placeholder="Enter project name"
                onChange={handleChange}
                onFocus={setOuterStyle}
                onBlur={removeOuterStyle}
                value={values.name}
              />
            </div>

          </div>
          <div className={styles.buttonGroupCol}>
            <Button style={{ width: '85px' }} title="Cancel" type="secondary" onClick={() => setIsOpen(false)} />
            <Button style={{ width: '154px', marginLeft: '12px' }} title="Continue" onClick={() => handleContinue()} />
          </div>
        </div>
      )}
    </div>
  );

  const renderSecondaryModal = () => (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <div className={styles.title}>Cancel project creation</div>
      </div>
      <div className={styles.body}>
        <div className={styles.descriptionContent}>
          <span>Are you sure you want to cancel the creation of the project?</span>
          <strong>If you cancel, all progress will be lost.</strong>
        </div>
        <div className={styles.buttonGroupCol}>
          <Button title="Back to creation" type="secondary" onClick={closeFonfirmCancel} />
          <Button style={{ marginLeft: 12 }} type="red" title="Cancel project" onClick={() => setIsOpen(false)} />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.modal}>
      {mainRendered ? renderMainModal() : renderSecondaryModal()}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createProject: (data, callback) => dispatch(createProjectRequest(data, callback)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
});

export default connect(null, mapDispatchToProps)(CreateProjectModal);
