/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import { meRequest, meInProjectRequest } from '../../store/auth/actions';
import { listProjectsRequest } from '../../store/projects/actions';
import { listDirectoryRequest } from '../../store/directory/actions';
import { listRolesRequest } from '../../store/roles/actions';

import * as style from './ProtectedRoute.module.scss';

function Protected({
  currentUser, projects, children, getProjects,
  getMe, getDirectory, meInProject, getRoles,
}) {
  const { shortName } = useParams();
  const currentActive = useMemo(() => (arr) => arr?.some((p) => p.shortName === shortName), [shortName]);

  useEffect(() => {
    getDirectory();
  }, []);

  useEffect(() => {
    getProjects(shortName);
  }, [shortName]);

  useEffect(() => {
    if (projects?.length > 0 && !currentUser?.id) {
      getMe();
    }

    if (projects?.length > 0 && currentUser?.id) {
      const activeProject = projects?.find((el) => el.shortName === shortName);
      if (!activeProject?.id) return;
      getRoles({ id: activeProject.id });
      meInProject({
        id: currentUser.id,
        projectId: activeProject.id,
      });
    }
  }, [projects, shortName, currentUser?.id]);

  if (projects && !projects?.length) {
    return <Navigate to="/new-project" replace />;
  }

  if (projects && projects?.length && (!shortName || !currentActive(projects))) {
    return <Navigate to={`/${projects[0].shortName}/endpoints`} replace />;
  }

  return (
    <>
      {currentUser && projects && projects.length ? (
        children
      ) : (
        <div className={style.loaderWrapper}>
          <ThreeDots
            height="100"
            width="100"
            radius="8"
            color="#4149F7"
            ariaLabel="three-dots-loading"
            visible
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ auth: { currentUser }, projects }) => ({
  currentUser,
  projects,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (data) => dispatch(listProjectsRequest(data)),
  getRoles: (data) => dispatch(listRolesRequest(data)),
  getMe: () => dispatch(meRequest()),
  getDirectory: () => dispatch(listDirectoryRequest()),
  meInProject: (id) => dispatch(meInProjectRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Protected);
