.modal {
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.7);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  padding-top: 60px;

  .modalContent {
    background-color: #fff;
    width: 70%;
    max-width: 640px;
    height: auto;
    margin: auto;
    border-radius: 10px;
  }
}
