/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ActionIcon from '../../../../components/ActionIcon/ActionIcon';
import DBIcon from '../../../../components/DBIcon/DBIcon';
import ChipsStatus from '../../../../components/ChipsStatus/ChipsStatus';
import { typesAPI } from '../../../../helpers/const';
// import * as styles from './Directory.module.scss';

import ConnectionIcon from '../../../../pictures/api-database.svg';
import FolderIcon from '../../../../pictures/folder.svg';

import * as shared from '../../../../styles/shared.module.scss';

function Directory({
  folderId,
  foldersData,
  connectionsData,
  selectedFolders,
  selectedItems,
  handleContextMenu,
  setContextMenuModelActive,
  openFolderAction,
  selectOneFolder,
  selectItems,
  contextMenuModelActive,
  contextMenuTop,
  contextMenuLeft,
  modifyConnection,
  selectOne,
  renameFolder,
  clickShare,
  removeFolder,
  isRoleAction,
  exportOne,
  clickRemove,
  copyCon,
  activeProject,
  getFolder,
  depth = 0,
  moveFolder,
  moveObject,
}) {
  const [sortBy, setSortBy] = useState('instanceName');
  const [expanded, setExpanded] = useState({});
  const [activeTarget, setActiveTarget] = useState(null);
  const sortConnections = (connectionsList) => connectionsList?.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  const sortFolders = (foldersList) => foldersList?.sort((a, b) => a.name.localeCompare(b.name));

  const openDirectory = (id, f) => {
    if (f) {
      getFolder({ folder: id || 0, merge: true, projectId: activeProject?.id }, (res) => {
        const { folders, data } = res || {};
        const foldersIds = folders.map((fld) => fld.id);
        const dataIds = data.map((d) => d.id);
        const currentIdSelected = selectedFolders?.find((el) => el === (id || 0));

        if (currentIdSelected) selectItems(foldersIds, dataIds);
      });
    }
    setExpanded({ ...expanded, [id]: f });
  };

  const handleDragStart = (e, model) => {
    e.dataTransfer.setData('model', JSON.stringify(model));
  };

  const handleDragEnter = (e, model) => {
    setActiveTarget(model?.id);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e, model) => {
    setActiveTarget(model?.id);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e, model) => {
    setActiveTarget(null);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e, model, isObj) => {
    const destinationId = isObj ? model.folder : model.id;
    setActiveTarget(null);
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'connections' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  return (
    <>
      {foldersData && sortFolders(foldersData).map((model) => (
        <React.Fragment key={`folder_${model.id}`}>
          <tr
            className={cn(
              shared.connectionTableRowConnections,
              (selectedFolders.includes(model.id) && shared.selected),
            )}
            style={{
              backgroundColor: activeTarget === model.id ? '#DBD4F9' : undefined,
            }}
            role="button"
            tabIndex={0}
            onContextMenu={(e) => handleContextMenu(e, model.id)}
            onClick={() => setContextMenuModelActive(false)}
            onDoubleClick={() => openFolderAction(model.id)}
            draggable
            onDrop={(e) => handleDrop(e, model)}
            onDragStart={(e) => handleDragStart(e, model)}
            onDragEnter={(e) => handleDragEnter(e, model)}
            onDragOver={(e) => handleDragOver(e, model)}
            onDragLeave={(e) => handleDragLeave(e, model)}
          >
            <td>
              <Checkbox
                handleChange={(isCheked) => selectOneFolder(isCheked, model.id)}
                value={selectedFolders.includes(model.id)}
                size="small"
              />
            </td>
            <td style={{ position: 'relative', gap: '8px' }}>
              <div style={{ marginLeft: `calc(${depth} * 32px)`, transform: !expanded[model.id] ? 'rotate(0)' : 'rotate(90deg)', transition: '.3s all' }}>
                <ActionIcon
                  large
                  icon="openArrow"
                  onClick={() => openDirectory(model.id, !expanded[model.id])}
                />
              </div>
              <FolderIcon />
              <span>
                {model.name}
              </span>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
            {contextMenuModelActive === model.id && (
            <div
              className={shared.contextMenu}
              style={{
                display: contextMenuTop !== 0 ? 'flex' : 'none',
                top: contextMenuTop,
                left: contextMenuLeft,
              }}
            >
              <ul className={shared.contextMenuList}>
                <li className={shared.contextMenuItem}>
                  <button type="button" className={shared.contextMenuButton} onClick={() => renameFolder(model)}>
                    Rename
                  </button>
                </li>
                <li className={shared.contextMenuItem}>
                  <button type="button" className={shared.contextMenuButton} onClick={() => clickShare(model)}>
                    Share
                  </button>
                </li>
                <li className={shared.contextMenuItem}>
                  <button type="button" className={shared.contextMenuButton} onClick={() => removeFolder(model)}>
                    Delete
                  </button>
                </li>
              </ul>
            </div>
            )}
          </tr>
          {expanded[model.id] && model.items && (
            <Directory
              folderId={model.id}
              depth={depth + 1}
              key={`directory__${model.id}`}
              foldersData={model.items.foldersData}
              connectionsData={model.items.data}
              selectedFolders={selectedFolders}
              selectedItems={selectedItems}
              handleContextMenu={handleContextMenu}
              setContextMenuModelActive={setContextMenuModelActive}
              openFolderAction={openFolderAction}
              selectOneFolder={selectOneFolder}
              selectItems={selectItems}
              contextMenuModelActive={contextMenuModelActive}
              contextMenuTop={contextMenuTop}
              contextMenuLeft={contextMenuLeft}
              modifyConnection={modifyConnection}
              selectOne={selectOne}
              renameFolder={renameFolder}
              clickShare={clickShare}
              removeFolder={removeFolder}
              isRoleAction={isRoleAction}
              exportOne={exportOne}
              clickRemove={clickRemove}
              copyCon={copyCon}
              getFolder={getFolder}
              merge
              moveFolder={moveFolder}
              moveObject={moveObject}
              activeProject={activeProject}
            />
          )}
        </React.Fragment>
      ))}
      {sortConnections(connectionsData).map((model, index) => (
        <React.Fragment key={`connection_${model.id}`}>
          <tr
            style={{ position: 'relative' }}
            className={cn(
              shared.connectionTableRowConnections,
              (selectedItems.includes(model.id) && shared.selected),
            )}
            role="button"
            tabIndex={0}
            onDoubleClick={() => modifyConnection(model.id)}
            onClick={() => setContextMenuModelActive(false)}
            draggable
            onDrop={(e) => handleDrop(e, model, true)}
            onDragStart={(e) => handleDragStart(e, model)}
            onDragEnter={(e) => handleDragEnter(e, model)}
            onDragOver={(e) => handleDragOver(e, model)}
            onDragLeave={(e) => handleDragLeave(e, model)}
          >
            <td>
              <Checkbox
                handleChange={(isCheked) => selectOne(isCheked, model.id)}
                value={selectedItems.includes(model.id)}
                size="small"
                style={{ marginRight: '0' }}
              />
            </td>
            {/* <td><span>{shortenText(model.id)}</span></td> */}
            <td style={{ marginLeft: `calc(${depth + 1} * 32px)`, gap: '8px' }}>
              <ConnectionIcon />
              <span>
                {model.instanceName}
              </span>
              {model.description
            && (
            <div className={shared.controlIcons}>
              <ActionIcon
                id={`${index}_${model.id}_tooltip`}
                icon="info"
                style={{ marginRight: '8px' }}
                onClick={() => {}}
                tooltip={{
                  content: model.description,
                  place: 'right',
                }}
              />
            </div>
            )}
            </td>
            <td>
              <span>{model.connectionSettings.database}</span>
            </td>
            <td>
              <DBIcon type={model.type} style={{ marginRight: '12px' }} />
              <span>{typesAPI[model.type]}</span>
            </td>
            <td><span>{model.connectionSettings.user}</span></td>
            <td><ChipsStatus title="Active" type="success" /></td>
            <td style={{ minWith: '400px' }}>
              <div className={shared.controlIcons}>
                <ActionIcon
                  id={`${index}_${model.id}_edit`}
                  icon="edit"
                  onClick={() => modifyConnection(model.id)}
                  hidden={!isRoleAction({ key: 'connection', role: 'update', modelRole: model.role })}
                  tooltip={{
                    content: 'Edit',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_copy`}
                  icon="copy"
                  onClick={() => copyCon(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'create', modelRole: model.role })}
                  tooltip={{
                    content: 'Copy',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_addUser`}
                  icon="addUser"
                  onClick={() => clickShare(model)}
                  hidden={!isRoleAction({ key: 'connection', role: 'share', modelRole: model.role })}
                  tooltip={{
                    content: 'Share',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_export`}
                  icon="export"
                  hiddenFill
                  onClick={() => exportOne(model)}
                  tooltip={{
                    content: 'Export',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_delete`}
                  icon="trash"
                  onClick={() => clickRemove(model)}
                  hidden={!isRoleAction({ key: 'connection', role: 'delete', modelRole: model.role })}
                  tooltip={{
                    content: 'Delete',
                  }}
                />
              </div>
            </td>
          </tr>
        </React.Fragment>
      ))}
    </>
  );
}

Directory.propTypes = {
  selectedFolders: PropTypes.array,
  selectedItems: PropTypes.array,
  foldersData: PropTypes.array,
  connectionsData: PropTypes.array,
};

export default Directory;
