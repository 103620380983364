/* eslint-disable max-len */
import { ActiveUser } from './constants';

export const activeUserRequest = (data) => ({ type: ActiveUser.activeUserRequest, data });
export const activeUserSuccess = (data) => ({ type: ActiveUser.activeUserSuccess, data });
export const activeUserError = () => ({ type: ActiveUser.activeUserError });

export const userAccessRequest = (data) => ({ type: ActiveUser.userAccessRequest, data });
export const userAccessSuccess = (data) => ({ type: ActiveUser.userAccessSuccess, data });
export const userAccessError = () => ({ type: ActiveUser.userAccessError });
