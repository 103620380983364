import { Endpoints, latestVersionId } from './constants';
import { ActiveEndpoint } from '../activeEndpoint/constants';

const initialState = {
  historyList: {
    parents: [],
    children: [],
  },
  status: 'idle',
};

export const historyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActiveEndpoint.activeEndpointSuccess:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          parents: [{
            author: 'unknown',
            created: new Date().getTime(),
            data: action.data,
            father: null,
            id: latestVersionId,
            number: -1,
            userName: null,
          }],
        },
      };
    case Endpoints.historySuccess:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          parents: [...state.historyList.parents, ...action.payload.parents],
          children: [...state.historyList.children, ...action.payload.children],
        },
        status: action.payload.status,
      };
    case Endpoints.userSuccess:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          children: state.historyList.children
            .map((commit) => (commit.author === action.payload.id
              ? ({ ...commit, userName: `${action.payload.firstName} ${action.payload.lastName}` })
              : commit)),
        },
      };
    case Endpoints.diffVersionSuccess:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          children: state.historyList.children
            .map((commit) => (commit.id === action.payload.id
              ? ({ ...commit, diff: action.payload.data })
              : commit)),
        },
      };
    case Endpoints.clearHistoryData:
    case Endpoints.createVersionSuccess:
      return initialState;

    default:
      return state;
  }
};
