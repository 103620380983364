import { Toasts } from './constants';

const initialState = [];

export const toastsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Toasts.createToastSuccess:
      return state ? [...state, action.data] : [action.data];
    case Toasts.removeToastSuccess:
      return [
        ...state.filter((el) => el.id !== action.data.id),
      ];
    default:
      return state;
  }
};
