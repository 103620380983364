/* eslint-disable import/prefer-default-export */
export const Auth = {
  registerRequest: 'REGISTER_USER_PENDING',
  registerSuccess: 'REGISTER_USER_SUCCESS',
  registerError: 'REGISTER_USER_ERROR',

  resetPasswordRequest: 'RESET_PASSWORD_USER_PENDING',
  resetPasswordSuccess: 'RESET_PASSWORD_USER_SUCCESS',
  resetPasswordError: 'RESET_PASSWORD_USER_ERROR',

  changePasswordRequest: 'CHANGE_PASSWORD_USER_PENDING',
  changePasswordSuccess: 'CHANGE_PASSWORD_USER_SUCCESS',
  changePasswordError: 'CHANGE_PASSWORD_USER_ERROR',
  
  authRequest: 'AUTH_USER_PENDING',
  authSuccess: 'AUTH_USER_SUCCESS',
  authError: 'AUTH_USER_ERROR',

  logoutRequest: 'LOGOUT_USER_PENDING',
  logoutSuccess: 'LOGOUT_USER_SUCCESS',
  logoutError: 'LOGOUT_USER_ERROR',

  refreshRequest: 'REFRESH_TOKEN_PENDING',
  refreshSuccess: 'REFRESH_TOKEN_SUCCESS',
  refreshError: 'REFRESH_TOKEN_ERROR',

  meRequest: 'ME_PENDING',
  meSuccess: 'ME_SUCCESS',
  meError: 'ME_ERROR',

  meInProjectRequest: 'ME_IN_PROJECT_PENDING',
  meInProjectSuccess: 'ME_IN_PROJECT_SUCCESS',
  meInProjectError: 'ME_IN_PROJECT_ERROR',

  checkHashRequest: 'CHECK_HASH_REQUEST',
  checkHashSuccess: 'CHECK_HASH_SUCCESS',
  checkHashError: 'CHECK_HASH_ERROR',

  inviteRequest: 'INVITE_REQUEST',
  inviteSuccess: 'INVITE_SUCCESS',
  inviteError: 'INVITE_ERROR',
};
