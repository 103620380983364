import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  historySuccess,
  historyRequest,
  userRequest,
  userSuccess,
  createVersionSuccess,
  createVersionRequest, overrideVersionRequest,
  diffVersionRequest, diffVersionSuccess,
} from './actions';
import { createToastRequest } from '../toasts/actions';
import {
  refreshRequest,
} from '../auth/actions';
import { Endpoints, latestVersionId } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

const prepareData = (data) => {
  const newData = data.items.sort((a, b) => b.created - a.created)
    .reduce((acc, val) => {
      const isVersion = val.number !== 0;
      if (isVersion) {
        return {
          ...acc,
          parents: [
            ...acc.parents,
            { ...val, father: null, userName: null },
          ],
        };
      }

      return {
        ...acc,
        children: [
          ...acc.children,
          {
            ...val,
            father: acc.parents[acc.parents.length - 1]?.id || latestVersionId,
            userName: null,
          },
        ],
      };
    }, { parents: [], children: [] });

  return {
    children: newData.children.sort((a, b) => b.created - a.created),
    parents: newData.parents.sort((a, b) => b.created - a.created),
  };
};

export function* apiHistory({ data, callback }) {
  const { resource, id } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/versions?resource=${resource}&id=${id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(historySuccess(prepareData(parsed)));
        if (callback) callback('SUCCESS', parsed.response);
      } else if (response.status === 401) {
        yield put(refreshRequest(historyRequest, data, callback));
      } else {
        const parsed = yield response.json();
        if (callback) callback('ERROR', parsed?.error);
      }
    }
  } catch (e) {
    console.log('e', e);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getUser(ops) {
  const authData = yield select(getAuth);
  const { id } = ops.data;
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users/${id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(userSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(userRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* createVersion({ data, callback }) {
  const authData = yield select(getAuth);
  const { resource, id } = data;
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/versions?resource=${resource}&id=${id}`, 'POST', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createVersionSuccess(parsed));
        if (callback) callback('SUCCESS', parsed.response);
      } else if (response.status === 401) {
        yield put(refreshRequest(createVersionRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* overrideVersion({ data, callback }) {
  const authData = yield select(getAuth);
  const { resource, id, commitId } = data;
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(
        api,
        `/versions?resource=${resource}&id=${id}&commitId=${commitId}`,
        'PUT',
        null,
        authData.access_token,
      );
      if (response.ok) {
        const parsed = yield response.json();
        yield put(createVersionSuccess(parsed));
        if (callback) callback('SUCCESS', parsed.response);
      } else if (response.status === 401) {
        yield put(refreshRequest(overrideVersionRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* diffVersion({ data, callback }) {
  const authData = yield select(getAuth);
  const { resource, id, commitId } = data;
  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(
        api,
        `/versions/diff?resource=${resource}&id=${id}&commitId=${commitId}`,
        'GET',
        null,
        authData.access_token,
      );
      if (response.ok) {
        const parsed = yield response.json();
        yield put(diffVersionSuccess({ id: commitId, data: parsed }));
        if (callback) callback('SUCCESS', parsed.response);
      } else if (response.status === 401) {
        yield put(refreshRequest(diffVersionRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* historySaga() {
  yield takeEvery(Endpoints.historyRequest, apiHistory);
  yield takeEvery(Endpoints.userRequest, getUser);
  yield takeEvery(Endpoints.createVersionRequest, createVersion);
  yield takeEvery(Endpoints.overrideVersionRequest, overrideVersion);
  yield takeEvery(Endpoints.diffVersionRequest, diffVersion);
}

function* configSaga() {
  yield all([fork(historySaga)]);
}

export default configSaga;
