@use "sass:map";
@import '../../styles/variables.scss';

.chipsAction {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid map.get($colors-blue, 70);
    background-color: map.get($colors-blue, 30);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px 7px;
    position: relative;
    top: -10px;

    span.nested {
        margin: 0;
        margin-right: 12px;
    }

    &.name {
        border: 1px solid map.get($colors-peach, 100);
        background-color: map.get($colors-peach, 30);
    }
}