/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ActionIcon from '../../../../components/ActionIcon/ActionIcon';
import DBIcon from '../../../../components/DBIcon/DBIcon';
import ChipsStatus from '../../../../components/ChipsStatus/ChipsStatus';
import { typesAPI } from '../../../../helpers/const';
// import * as styles from './Directory.module.scss';

import ApiGraphIcon from '../../../../pictures/api-graph.svg';
import FolderIcon from '../../../../pictures/folder.svg';

import * as shared from '../../../../styles/shared.module.scss';

function Directory({
  folderId,
  foldersData,
  endpointsData,
  selectedFolders,
  selectedItems,
  handleContextMenu,
  setContextMenuModelActive,
  openFolderAction,
  selectOneFolder,
  selectItems,
  contextMenuModelActive,
  contextMenuTop,
  contextMenuLeft,
  modifyEndpoint,
  copyEndpoint,
  selectOne,
  renameFolder,
  clickShare,
  removeFolder,
  isRoleAction,
  exportOne,
  clickRemove,
  getFolder,
  depth = 0,
  moveFolder,
  moveObject,
  copyCon,
  activeProject,
}) {
  const [sortBy, setSortBy] = useState('name');
  const [expanded, setExpanded] = useState({});
  const [activeTarget, setActiveTarget] = useState(null);

  const sortEndpoints = (endpointsList) => endpointsList.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  const sortFolders = (foldersList) => foldersList.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));

  const openDirectory = (id, f) => {
    if (f) {
      getFolder({ folder: id || 0, merge: true, projectId: activeProject?.id }, (res) => {
        const { folders, data } = res || {};
        const foldersIds = folders.map((fld) => fld.id);
        const dataIds = data.map((d) => d.id);
        const currentIdSelected = selectedFolders?.find((el) => el === (id || 0));

        if (currentIdSelected) selectItems(foldersIds, dataIds);
      });
    }
    setExpanded({ ...expanded, [id]: f });
  };

  const handleDragStart = (e, model) => {
    e.dataTransfer.setData('model', JSON.stringify(model));
  };

  const handleDragEnter = (e, model) => {
    setActiveTarget(model?.id);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e, model) => {
    setActiveTarget(model?.id);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e, model) => {
    setActiveTarget(null);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e, model, isObj) => {
    const destinationId = isObj ? model.folder : model.id;
    setActiveTarget(null);
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('model');
    const parsed = JSON.parse(data);

    if (parsed.type === 'endpoints' && parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveFolder(parsed, destinationId);
    } else if (parsed.id !== destinationId && parsed.parentId !== destinationId && parsed.folder !== destinationId) {
      moveObject(parsed, destinationId);
    }
  };

  return (
    <>
      {sortFolders(foldersData).map((model) => (
        <React.Fragment key={`folder_${model.id}`}>
          <tr
            className={cn(
              shared.connectionTableRowEndpoints,
              (selectedFolders.includes(model.id) && shared.selected),
            )}
            style={{
              backgroundColor: activeTarget === model.id ? '#DBD4F9' : undefined,
            }}
            role="button"
            tabIndex={0}
            onContextMenu={(e) => handleContextMenu(e, model.id)}
            onClick={() => setContextMenuModelActive(false)}
            onDoubleClick={() => openFolderAction(model.id)}
            draggable
            onDrop={(e) => handleDrop(e, model)}
            onDragStart={(e) => handleDragStart(e, model)}
            onDragEnter={(e) => handleDragEnter(e, model)}
            onDragOver={(e) => handleDragOver(e, model)}
            onDragLeave={(e) => handleDragLeave(e, model)}
          >
            <td>
              <Checkbox
                handleChange={(isCheked) => selectOneFolder(isCheked, model.id)}
                value={selectedFolders.includes(model.id)}
                size="small"
              />
            </td>
            <td style={{ position: 'relative', gap: '8px' }}>
              <div style={{ marginLeft: `calc(${depth} * 32px)`, transform: !expanded[model.id] ? 'rotate(0)' : 'rotate(90deg)', transition: '.3s all' }}>
                <ActionIcon
                  large
                  icon="openArrow"
                  onClick={() => openDirectory(model.id, !expanded[model.id])}
                />
              </div>
              <FolderIcon />
              <span>
                {model.name}
              </span>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            {contextMenuModelActive === model.id && (
            <div
              className={shared.contextMenu}
              style={{
                display: contextMenuTop !== 0 ? 'flex' : 'none',
                top: contextMenuTop,
                left: contextMenuLeft,
              }}
            >
              <ul className={shared.contextMenuList}>
                <li className={shared.contextMenuItem}>
                  <button type="button" className={shared.contextMenuButton} onClick={() => renameFolder(model)}>
                    Rename
                  </button>
                </li>
                <li className={shared.contextMenuItem}>
                  <button type="button" className={shared.contextMenuButton} onClick={() => clickShare(model)}>
                    Share
                  </button>
                </li>
                <li className={shared.contextMenuItem}>
                  <button type="button" className={shared.contextMenuButton} onClick={() => removeFolder(model)}>
                    Delete
                  </button>
                </li>
              </ul>
            </div>
            )}
          </tr>
          {expanded[model.id] && model.items && (
          <Directory
            folderId={model.id}
            key={`directory__${model.id}`}
            foldersData={model.items.foldersData}
            endpointsData={model.items.data}
            selectedFolders={selectedFolders}
            selectedItems={selectedItems}
            handleContextMenu={handleContextMenu}
            setContextMenuModelActive={setContextMenuModelActive}
            openFolderAction={openFolderAction}
            selectOneFolder={selectOneFolder}
            selectItems={selectItems}
            contextMenuModelActive={contextMenuModelActive}
            contextMenuTop={contextMenuTop}
            contextMenuLeft={contextMenuLeft}
            modifyEndpoint={modifyEndpoint}
            copyEndpoint={copyEndpoint}
            selectOne={selectOne}
            renameFolder={renameFolder}
            clickShare={clickShare}
            removeFolder={removeFolder}
            isRoleAction={isRoleAction}
            exportOne={exportOne}
            clickRemove={clickRemove}
            getFolder={getFolder}
            moveFolder={moveFolder}
            moveObject={moveObject}
            copyCon={copyCon}
            activeProject={activeProject}
            depth={depth + 1}
            merge
          />
          )}
        </React.Fragment>
      ))}
      {sortEndpoints(endpointsData).map((model, index) => (
        <React.Fragment key={`endpoint_${model.id}`}>
          <tr
            style={{ position: 'relative' }}
            key={`endpoint_${model.id}`}
            className={cn(
              shared.connectionTableRowEndpoints,
              (selectedItems.includes(model.id) && shared.selected),
            )}
            role="button"
            tabIndex={0}
            onDoubleClick={() => modifyEndpoint(model.id)}
            onClick={() => setContextMenuModelActive(false)}
            draggable
            onDrop={(e) => handleDrop(e, model, true)}
            onDragStart={(e) => handleDragStart(e, model)}
            onDragEnter={(e) => handleDragEnter(e, model)}
            onDragOver={(e) => handleDragOver(e, model)}
            onDragLeave={(e) => handleDragLeave(e, model)}
          >
            <td>
              <Checkbox
                handleChange={(isCheked) => selectOne(isCheked, model.id)}
                value={selectedItems.includes(model.id)}
                size="small"
                style={{ marginRight: '0' }}
              />
            </td>
            {/* <td><span>{shortenText(model.id)}</span></td> */}
            <td style={{ marginLeft: `calc(${depth + 1} * 32px)`, gap: '8px' }}>
              <ApiGraphIcon />
              <span>
                {model.name}
              </span>
              {model.description
                      && (
                      <div className={shared.controlIcons}>
                        <ActionIcon
                          id={`${index}_${model.id}_tooltip`}
                          icon="info"
                          style={{ marginRight: '8px' }}
                          onClick={() => {}}
                          tooltip={{
                            content: model.description,
                            place: 'right',
                          }}
                        />
                      </div>
                      )}
            </td>
            <td>
              <span className={shared.uppercased}>{model.method}</span>
            </td>
            <td>
              <span>{model.path}</span>
              <div className={shared.controlIcons}>
                <ActionIcon
                  icon="copy"
                  style={{ marginRight: '8px' }}
                  onClick={() => copyCon(model.path)}
                />
              </div>
            </td>
            <td>
              {model.connectionType && <DBIcon type={model.connectionType} style={{ marginRight: '12px', minWidth: '32px' }} />}
              <span>{model.connectionName}</span>
            </td>
            <td>
              <span>{model.auth?.authType && `${model.auth?.authType[0].toUpperCase()}${model.auth?.authType.substr(1)}`}</span>
            </td>
            <td>{model.status === 'active' ? <ChipsStatus title="Active" type="success" /> : <ChipsStatus title="Draft" type="draft" />}</td>
            <td>
              <div className={shared.controlIcons}>
                <ActionIcon
                  id={`${index}_${model.id}_edit`}
                  icon="edit"
                  onClick={() => modifyEndpoint(model.id)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'update', modelRole: model.role }) && !isRoleAction({ key: 'endpoint', role: 'read', modelRole: model.role })}
                  tooltip={{
                    content: 'Edit',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_copy`}
                  icon="copy"
                  onClick={() => copyEndpoint(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'create', modelRole: model.role })}
                  tooltip={{
                    content: 'Copy',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_addUser`}
                  icon="addUser"
                  onClick={() => clickShare(model)}
                  hidden={!isRoleAction({ key: 'endpoint', role: 'share', modelRole: model.role })}
                  tooltip={{
                    content: 'Share',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_export`}
                  icon="export"
                  hiddenFill
                  onClick={() => exportOne(model)}
                  tooltip={{
                    content: 'Export',
                  }}
                />
                <ActionIcon
                  id={`${index}_${model.id}_delete`}
                  icon="trash"
                  onClick={() => clickRemove(model)}
                  hidden={!isRoleAction({ key: 'connection', role: 'delete', modelRole: model.role })}
                  tooltip={{
                    content: 'Delete',
                  }}
                />
              </div>
            </td>
          </tr>
        </React.Fragment>
      ))}
    </>
  );
}

Directory.propTypes = {
  selectedFolders: PropTypes.array,
  selectedItems: PropTypes.array,
  foldersData: PropTypes.array,
  endpointsData: PropTypes.array,
};

export default Directory;
