/* eslint-disable max-len */
import { Connections } from './constants';

export const listConnectionsRequest = (data, callback) => ({ type: Connections.listConnectionsRequest, data, callback });
export const listConnectionsSuccess = (data) => ({ type: Connections.listConnectionsSuccess, data });
export const listConnectionsError = () => ({ type: Connections.listConnectionsError });

export const searchConnectionsRequest = (data, callback) => ({ type: Connections.searchConnectionsRequest, data, callback });
export const searchConnectionsSuccess = (data) => ({ type: Connections.searchConnectionsSuccess, data });
export const searchConnectionsError = () => ({ type: Connections.searchConnectionsError });

export const createConnectionRequest = (data, callback) => ({ type: Connections.createConnectionRequest, data, callback });
export const createConnectionSuccess = (data) => ({ type: Connections.createConnectionSuccess, data });
export const createConnectionError = () => ({ type: Connections.createConnectionError });

export const removeConnectionRequest = (data, callback) => ({ type: Connections.removeConnectionRequest, data, callback });
export const removeConnectionSuccess = (data) => ({ type: Connections.removeConnectionSuccess, data });
export const removeConnectionError = () => ({ type: Connections.removeConnectionError });

export const removeConnectionsListRequest = (data, callback) => ({ type: Connections.removeConnectionsListRequest, data, callback });
export const removeConnectionsListSuccess = (data) => ({ type: Connections.removeConnectionsListSuccess, data });
export const removeConnectionsListError = () => ({ type: Connections.removeConnectionsListError });

export const updateConnectionRequest = (data, callback) => ({ type: Connections.updateConnectionRequest, data, callback });
export const updateConnectionSuccess = (data) => ({ type: Connections.updateConnectionSuccess, data });
export const updateConnectionError = () => ({ type: Connections.updateConnectionError });

export const copyConnectionRequest = (data, callback) => ({ type: Connections.copyConnectionRequest, data, callback });
export const copyConnectionSuccess = (data) => ({ type: Connections.copyConnectionSuccess, data });
export const copyConnectionError = () => ({ type: Connections.copyConnectionError });

export const exportConnectionRequest = (data, callback) => ({ type: Connections.exportConnectionRequest, data, callback });
export const exportConnectionSuccess = (data) => ({ type: Connections.exportConnectionSuccess, data });
export const exportConnectionError = () => ({ type: Connections.exportConnectionError });

export const importConnectionRequest = (data, callback) => ({ type: Connections.importConnectionRequest, data, callback });
export const importConnectionSuccess = (data) => ({ type: Connections.importConnectionSuccess, data });
export const importConnectionError = () => ({ type: Connections.importConnectionError });

export const configConnectionsRequest = () => ({ type: Connections.configConnectionsRequest });
export const configConnectionsSuccess = (data) => ({ type: Connections.configConnectionsSuccess, data });
export const configConnectionsError = () => ({ type: Connections.configConnectionsError });

export const createConnectionFolderRequest = (data, callback) => ({ type: Connections.createConnectionFolderRequest, data, callback });
export const createConnectionFolderSuccess = (data) => ({ type: Connections.createConnectionFolderSuccess, data });

export const updateConnectionFolderRequest = (data, callback) => ({ type: Connections.updateConnectionFolderRequest, data, callback });
export const updateConnectionFolderSuccess = (data) => ({ type: Connections.updateConnectionFolderSuccess, data });

export const removeConnectionFolderRequest = (data, callback) => ({ type: Connections.removeConnectionFolderRequest, data, callback });
export const removeConnectionFolderSuccess = (data) => ({ type: Connections.removeConnectionFolderSuccess, data });

export const getConnectionFolderContentRequest = (data, callback) => ({ type: Connections.getConnectionFolderContentRequest, data, callback });
export const getConnectionFolderContentSuccess = (data) => ({ type: Connections.getConnectionFolderContentSuccess, data });

export const moveConnectionFolderRequest = (data, callback) => ({ type: Connections.moveConnectionFolderRequest, data, callback });
export const moveConnectionFolderSuccess = (data) => ({ type: Connections.moveConnectionFolderSuccess, data });

export const moveConnectionRequest = (data, callback) => ({ type: Connections.moveConnectionRequest, data, callback });
export const moveConnectionSuccess = (data) => ({ type: Connections.moveConnectionSuccess, data });
