/* eslint-disable max-len */
import { ActiveEndpoint } from './constants';

export const activeEndpointRequest = (data, callback) => ({ type: ActiveEndpoint.activeEndpointRequest, data, callback });
export const activeEndpointSuccess = (data) => ({ type: ActiveEndpoint.activeEndpointSuccess, data });
export const activeEndpointError = () => ({ type: ActiveEndpoint.activeEndpointError });

export const getAccessUsersEndpointSuccess = (data) => ({ type: ActiveEndpoint.getAccessUsersEndpointSuccess, data });
export const displayVersion = (data) => ({ type: ActiveEndpoint.activeDisplayVersion, data });
export const activateOriginVersion = () => ({ type: ActiveEndpoint.activateOriginVersion });
