/* eslint-disable max-len */
import { Endpoints } from './constants';

export const historyRequest = (data, callback) => ({ type: Endpoints.historyRequest, data, callback });
export const historySuccess = (data) => ({ type: Endpoints.historySuccess, payload: data });
export const historyError = () => ({ type: Endpoints.historyError });

export const userRequest = (data, callback) => ({ type: Endpoints.userRequest, data, callback });
export const userSuccess = (data) => ({ type: Endpoints.userSuccess, payload: data });
export const userError = () => ({ type: Endpoints.userError });

export const createVersionRequest = (data, callback) => ({ type: Endpoints.createVersionRequest, data, callback });
export const createVersionSuccess = (data) => ({ type: Endpoints.createVersionSuccess, payload: data });
export const createVersionError = () => ({ type: Endpoints.createVersionError });

export const overrideVersionRequest = (data, callback) => ({ type: Endpoints.overrideVersionRequest, data, callback });
export const overrideVersionSuccess = (data) => ({ type: Endpoints.overrideVersionSuccess, payload: data });
export const overrideVersionError = () => ({ type: Endpoints.overrideVersionError });

export const diffVersionRequest = (data, callback) => ({ type: Endpoints.diffVersionRequest, data, callback });
export const diffVersionSuccess = (data) => ({ type: Endpoints.diffVersionSuccess, payload: data });
export const diffVersionError = () => ({ type: Endpoints.diffVersionError });

export const clearHistoryData = () => ({ type: Endpoints.clearHistoryData });
