/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CodeMirror from '@uiw/react-codemirror';
import * as events from '@uiw/codemirror-extensions-events';
import { githubLightInit } from '@uiw/codemirror-theme-github';
import { sql } from '@codemirror/lang-sql';
import { json } from '@codemirror/lang-json';
import { javascript } from '@codemirror/lang-javascript';
import ActionIcon from '../ActionIcon';
import CodeEditorModal from './CodeEditorModal';
import * as styles from './CodeEditor.module.scss';
import { ViewModes, InlineClasses } from './codeEditorConstants';

function CodeEditor({
  value,
  handleChange,
  handleBlurCallback,
  name,
  style = {},
  error,
  readOnly,
  expand = true,
  placeholder,
  type = 'json',
  height = '180px',
  viewMode = ViewModes.Multiline,
}) {
  const [valueEdit, setValueEdit] = useState(value);
  const [modal, setModal] = useState(false);
  const eventExt = events.content({
    blur: () => {
      if (handleBlurCallback) handleBlurCallback(valueEdit);
      if (handleChange) handleChange(valueEdit);
    },
  });

  useEffect(() => {
    setValueEdit(value);
  }, [value]);

  const onChange = React.useCallback((v) => {
    setValueEdit(v);
    handleChange?.(v);
  }, [handleChange]);

  const onExpand = () => {
    setModal(true);
  };

  const getType = (t) => {
    switch (t) {
      case 'sql':
        return sql();
      case 'json':
        return json();
      case 'javascript':
        return javascript({ jsx: false, typescript: false });
      default:
        return json();
    }
  };

  const extensions = [getType(type), eventExt];

  return (
    <div
      className={cn(styles.codeEditor, {
        [styles.error]: error,
        [styles.scrollConfigInline]: viewMode === ViewModes.Inline,
      })}
      style={style}
    >
      <CodeMirror
        className={InlineClasses[viewMode]}
        value={valueEdit}
        height={height}
        extensions={extensions}
        onChange={onChange}
        autoFocus={false}
        editable={!readOnly}
        placeholder={placeholder || 'Enter SQL query'}
        theme={githubLightInit({
          settings: {
            caret: '#000',
            fontFamily: 'Menlo',
            background: '#F8F8F9',
          },
        })}
        basicSetup={{
          lineNumbers: false,
          highlightActiveLine: false,
          foldGutter: false,
        }}
      />
      {expand
        && (
        <div className={styles.expandWrapper}>
          <ActionIcon
            icon="expand"
            onClick={onExpand}
          />
        </div>
        )}
      {modal
        && (
        <CodeEditorModal
          value={value}
          height="calc(100vh - 280px)"
          handleChange={(valueEdit) => {
            handleBlurCallback && handleBlurCallback(valueEdit);
            handleChange && handleChange(valueEdit);
          }}
          name={name}
          error={error}
          readOnly={readOnly}
          expand={false}
          placeholder={placeholder}
          type={type}
          setIsOpen={setModal}
        />
        )}
    </div>
  );
}

CodeEditor.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
  handleBlurCallback: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
};

export default CodeEditor;
