@use "sass:map";
@import '../../styles/variables.scss';

.projectSelection {
    position: relative;
    overflow: visible;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 0 13px 0;
    border-top: 1px solid map.get($colors-basic, "divider");
}

.iconGroup {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.actionGroup {
    margin: auto 6px;
}

.popupMenu {
    position: absolute;
    background-color: white;
    bottom: 12px;
    left: 12px;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(8,8,40, 0.1);
    z-index: 9999;

    li {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 12px;
    }
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 12px;

    img.logo {
        width: 32px;
        height: 32px;
    }
}

.titleGroup {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.heading {
    font-size: map.get($font-sizes, "caption");
    color: map.get($colors-basic, "textGray");
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}