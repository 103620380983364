@use "sass:map";
@import '../../styles/variables.scss';

.wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .imageContainer {
        flex-grow: 2;
        background-image: url(../../pictures/create-project-background.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 95%;
        flex-grow: 1;
    }

    .container {
        box-sizing: border-box;
        flex-grow: 2;
        max-width: 600px;
        width: 40%;
        padding-left: 132px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex-grow: 1;
        padding-bottom: 60px;

        .title {
            font-weight: map.get($font-weights, 'medium');
            font-size: map.get($font-sizes, 'h3');
            margin: 16px 0;
        }

        input {
            margin: 32px 0 16px 0;
            width: 100%;
            border: 0;
            outline: 0;
            padding-bottom: 19px;
            border-bottom: 1px solid map.get($colors-basic, "textDisabled");
            background-color: transparent;
            font-size: map.get($font-sizes, 'h4');
            color: map.get($colors-basic, "primaryBlue");

            &:focus {
                border-bottom: 1px solid map.get($colors-basic, "primaryBlue");
            }

            &::placeholder {
                color: map.get($colors-basic, "textGray");
            }
        }

        .buttonWrapper {
            margin: 32px 0 16px 0;
        }
    }
}