import { Roles } from './constants';

const initialState = [];

export const rolesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Roles.listRolesSuccess:
      return action.data;
    case Roles.removeRoleSuccess:
      return state.filter(r => r.id !== action.data.roleId);
    default:
      return state;
  }
};
