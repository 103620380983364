/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Button from '../Button/Button';
import CodeEditor from './CodeEditor';
import * as styles from './CodeEditorModal.module.scss';

function CodeEditorModal(props) {
  const { setIsOpen, handleChange, handleBlurCallback, value } = props;
  const [valueEdit, setVelueEdit] = useState(value);

  const onChange = React.useCallback((v, viewUpdate) => {
    setVelueEdit(v);
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>CodeEditor</div>
        </div>
        <div className={styles.body}>
          <CodeEditor
            {...props}
            value={valueEdit}
            handleChange={onChange}
          />
          <div className={styles.buttonGroupCol}>
            <Button title="Cancel" type="secondary" onClick={() => setIsOpen(false)} />
            <Button
              style={{ width: '85px', marginLeft: 12 }}
              title="Save"
              onClick={() => {
                if (handleChange) handleChange(valueEdit)
                if (handleBlurCallback) handleBlurCallback(valueEdit)
                setIsOpen(false)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CodeEditorModal;
