import { combineReducers } from 'redux';

import { authReducer } from './auth/reducer';
import { usersReducer } from './users/reducer';
import { rolesReducer } from './roles/reducer';
import { projectsReducer } from './projects/reducer';
import { endpointsReducer } from './endpoints/reducer';
import { connectionsReducer } from './connections/reducer';
import { toastsReducer } from './toasts/reducer';
import { activeConnectionReducer } from './activeConnection/reducer';
import { activeEndpointReducer } from './activeEndpoint/reducer';
import { activeUserReducer } from './activeUser/reducer';
import { activeProjectReducer } from './activeProject/reducer';
import { invitesReducer } from './invites/reducer';
import { directoryReducer } from './directory/reducer';
import { errorsReducer } from './errors/reducer';
import { historyReducer } from './history/reducer';

const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  roles: rolesReducer,
  projects: projectsReducer,
  endpoints: endpointsReducer,
  history: historyReducer,
  connections: connectionsReducer,
  toasts: toastsReducer,
  activeConnection: activeConnectionReducer,
  activeEndpoint: activeEndpointReducer,
  activeUser: activeUserReducer,
  activeProject: activeProjectReducer,
  invites: invitesReducer,
  directory: directoryReducer,
  errors: errorsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER_SUCCESS') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
