import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  listUsersRequest, listUsersSuccess,
  searchUsersRequest, searchUsersSuccess,
  sendInviteSuccess, sendInviteRequest,
  removeUserSuccess,
  getAccessUsersRequest,
} from './actions';
import { Users } from './constants';
import { createToastRequest } from '../toasts/actions';
import { getAccessUsersConnectionSuccess } from '../activeConnection/actions';
import { getAccessUsersEndpointSuccess } from '../activeEndpoint/actions';
import {
  refreshRequest,
} from '../auth/actions';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* fetchUsers(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users?resourceId=${data.id}&resourceType=project`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(listUsersSuccess(parsed.items));
      } else if (response.status === 401) {
        yield put(refreshRequest(listUsersRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }

      if (callback) callback();
    }
  } catch (e) {
    if (callback) callback();
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* searchUsers(ops) {
  const { data } = ops;
  const { projectId, query } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users/search?projectId=${projectId}&query=${query}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(searchUsersSuccess(parsed.items));
      } else if (response.status === 401) {
        yield put(refreshRequest(searchUsersRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* sendInvite(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/invite', 'POST', data, authData.access_token);
      if (response.ok) {
        yield put(sendInviteSuccess());
        yield put(createToastRequest({ type: 'success', title: data.email, text: 'has been invited' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(sendInviteRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* removeUser(ops) {
  const { data, callback } = ops;
  const { id, resource, userId, email } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/access', 'DELETE', { id, resource, userId }, authData.access_token);
      if (response.ok) {
        yield put(removeUserSuccess(data));
        yield put(createToastRequest({ type: 'success', title: email, text: 'has been deleted' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeUserRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* modifyUser(ops) {
  const { data, callback } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users/${data.id}`, 'PUT', data, authData.access_token);
      if (response.ok) {
        yield put(createToastRequest({ type: 'success', title: data.email, text: 'has been updated' }));
        if (callback) callback();
      } else if (response.status === 401) {
        yield put(refreshRequest(removeUserRequest, data, callback));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getAccessUsers(ops) {
  const { data } = ops;
  const { resource, id } = data;
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/access/users?resource=${resource}&id=${id}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        if (resource === 'connection') {
          yield put(getAccessUsersConnectionSuccess(parsed.items));
        } else if (resource === 'endpoint') {
          yield put(getAccessUsersEndpointSuccess(parsed.items));
        }
      } else if (response.status === 401) {
        yield put(refreshRequest(getAccessUsersRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* usersSaga() {
  yield takeEvery(Users.listUsersRequest, fetchUsers);
  yield takeEvery(Users.searchUsersRequest, searchUsers);
  yield takeEvery(Users.sendInviteRequest, sendInvite);
  yield takeEvery(Users.removeUserRequest, removeUser);
  yield takeEvery(Users.modifyUserRequest, modifyUser);
  yield takeEvery(Users.getAccessUsersRequest, getAccessUsers);
}

function* configSaga() {
  yield all([fork(usersSaga)]);
}

export default configSaga;
