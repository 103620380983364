/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { shortenEmail } from '../../helpers/utils';
import ChipsAction from '../ChipsAction';
import Button from '../Button/Button';
import Select from '../Select/Select';
import { addAccessRequest, invitesRequest } from '../../store/invites/actions';
import { listConnectionsRequest, searchConnectionsRequest } from '../../store/connections/actions';
import DBIcon from '../DBIcon/DBIcon';
import { typesAPI, listRolesName } from '../../helpers/const';
import * as styles from './AddConnectionToUserModal.module.scss';
import * as shared from '../../styles/shared.module.scss';

const CreateProjectModalSchema = Yup.object().shape({
  email: Yup.string().email('Use valid email address'),
});

let searchTimeout = null;

function AddConnectionToUserModal({
  onClose,
  onSubmit,
  connections,
  inviteList,
  getConnections,
  searchConnections,
  activeProject,
  addAccess,
  activeUser,
  roles,
}) {
  const [inputFocused, changeInputFocused] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const { shortName } = useParams();
  const listContainerRef = useRef(null);
  const prevListRef = useRef(null);
  const { data: connectionsData = [] } = connections || {};

  useEffect(() => {
    getConnections({ id: activeProject?.id });
  }, [activeProject?.id]);

  const {
    submitForm, handleChange, setFieldValue, values, errors,
  } = useFormik({
    initialValues: {
      email: '',
      role: 'viewer',
      list: [],
    },
    validationSchema: CreateProjectModalSchema,
    onSubmit: (v) => {
      if (v.list.length === 0) {
        onClose();
        return;
      }
      values.list?.forEach((c) => {
        const sendCon = {
          id: +c.id,
          resource: 'connection',
          projectId: +activeProject.id,
          role: v.role,
          userId: activeUser.id,
        };
        addAccess(sendCon, () => {
          onSubmit && onSubmit([{ ...c, role: v.role }]);
          onClose();
        });
      });
    },
  });

  const onSearch = (e) => {
    handleChange(e);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (e.target.value) {
        searchConnections({
          query: e.target.value,
          projectId: activeProject.id,
        });
      } else {
        getConnections({
          id: activeProject?.id,
        });
      }
    }, 400);
  };

  useEffect(() => {
    const renderItems = values.list.map((i) => i.id);
    const filtredList = connectionsData?.filter((u) => !renderItems.includes(u.id) && !inviteList.includes(u.id) && u.role !== 'viewer');
    setFilteredList(filtredList);
  }, [connectionsData, values.list?.length]);

  useEffect(() => {
    if (prevListRef.current < values.list?.length) {
      listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight;
    }
    prevListRef.current = values.list?.length || 0;
  }, [values.list]);

  const addConnection = (connection) => {
    setFieldValue('email', '');
    setFieldValue('list', [...values.list, connection]);
  };

  const setOuterStyle = () => {
    changeInputFocused(true);
  };

  const removeOuterStyle = () => {
    changeInputFocused(false);
  };

  const clearChip = (connection) => {
    const newChips = values.list.filter((item) => item.id !== connection.id);
    setFieldValue('list', newChips);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={styles.title}>Add Connection access</div>
        </div>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <div
              className={cn(styles.inputWrapper, {
                [styles.inputFocused]: inputFocused,
                [styles.withControls]: true,
              })}
              ref={listContainerRef}
            >
              <span className={styles.inviteLabel}>To:</span>
              {values.list.map((item) => (
                <ChipsAction
                  title={shortenEmail(item.instanceName, 50)}
                  type={item.type}
                  onClose={() => clearChip(item)}
                />
              ))}
              <input
                autoComplete="off"
                type="text"
                name="name"
                autoFocus
                placeholder="Enter connection name"
                onChange={onSearch}
                value={values.instanceName}
                onFocus={setOuterStyle}
                onBlur={removeOuterStyle}
              />
              <div className={styles.selectRole}>
                <Select
                  list={roles.filter((r) => r.name !== 'owner').map((r) => ({ value: r.name, title: listRolesName[r.name] || r.name }))}
                  value={values.role}
                  handleChange={(key, value) => setFieldValue(key, value)}
                  name="role"
                />
              </div>
            </div>
            <span className={styles.listTitle}>Search results:</span>
            <div className={styles.listWrapper}>
              {filteredList?.length ? filteredList.map((item) => (
                <div key={`${item.id}`} className={styles.listItem} onClick={() => addConnection(item)}>
                  <div className={styles.listChip}>
                    <DBIcon type={item.type} style={{ marginRight: '12px' }} />
                    <span>{typesAPI[item.type]}</span>
                  </div>
                  <span>{`${item.instanceName}`}</span>
                </div>
              )) : (
                <div className={shared.table}>
                  <div className={shared.emptyContent} style={{ marginTop: '60px' }}>
                    <h3 className={shared.caption}>No Matches</h3>
                    <p>Please check your request or create new Connection.</p>
                    <div style={{ margin: '8px 4px 0 4px' }}>
                      <a href={`/${shortName}/connections/create`} target="_blank" rel="noreferrer">
                        Create new Connection
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonGroupCol}>
            <Button style={{ width: 85 }} title="Cancel" type="secondary" onClick={onClose} />
            <Button
              style={{ width: 154, marginLeft: 12 }}
              title="Add access"
              onClick={() => submitForm()}
              disabled={values.list.length === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  users, roles, activeProject, connections,
}) => ({
  users,
  roles,
  activeProject,
  connections,
});

const mapDispatchToProps = (dispatch) => ({
  getConnections: (data) => dispatch(listConnectionsRequest(data)),
  searchConnections: (data) => dispatch(searchConnectionsRequest(data)),
  sendInvite: (data, callback) => dispatch(invitesRequest(data, callback)),
  addAccess: (data, callback) => dispatch(addAccessRequest(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConnectionToUserModal);
