/* eslint-disable camelcase */
import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  authRequest, authSuccess,
  registerSuccess, refreshRequest, refreshSuccess,
  logoutSuccess, meRequest, meSuccess,
  checkHashSuccess,
  inviteSuccess,
  meInProjectRequest, meInProjectSuccess,
  resetPasswordSuccess, changePasswordSuccess
} from './actions';
import { createToastRequest } from '../toasts/actions';
import { Auth } from './constants';
import history from '../../history';

const getAuth = (state) => state.auth?.auth;

export function* refreshToken(ops) {
  const { action, params } = ops;
  const authData = yield select(getAuth);

  try {
    if (!authData?.refresh_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/refresh-token', 'POST', { refresh_token: authData.refresh_token });
      if (response.ok) {
        const parsed = yield response.json();
        localStorage.setItem('auth', JSON.stringify(parsed));
        yield put(refreshSuccess(parsed));
        yield put(action(...params));
      } else {
        localStorage.removeItem('auth');
        history.push('/login');
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* authUser(ops) {
  const { data, callback } = ops;

  try {
    const response = yield call(api, '/login', 'POST', data);
    if (response.ok) {
      const parsed = yield response.json();
      localStorage.setItem('auth', JSON.stringify(parsed));
      yield put(authSuccess(parsed));
      if (callback) callback(true);
    } else {
      const parsed = yield response.json();
      if (callback) callback(false);
      yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
    }
  } catch (e) {
    if (callback) callback(false);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* registerUser(ops) {
  const { data, callback } = ops;
  const { email, ...other } = data;

  try {
    const response = yield call(api, '/sign-up', 'POST', data);
    if (response.ok) {
      yield put(registerSuccess());
      yield put(authRequest({ username: email, ...other }, callback));
    } else {
      const parsed = yield response.json();
      if (callback) callback(false);
      yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
    }
  } catch (e) {
    if (callback) callback(false);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* logoutUser() {
  const authData = yield select(getAuth);

  try {
    if (!authData?.refresh_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/logout', 'POST', { refresh_token: authData.refresh_token }, authData.access_token);
      if (response.ok) {
        yield put(logoutSuccess());
        localStorage.removeItem('auth');
        history.push('/login');
      } else if (response.status === 401) {
        yield put(refreshRequest(logoutUser));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getCurrentUser() {
  const authData = yield select(getAuth);

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, '/users/me', 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(meSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(meRequest));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* getCurrentUserInProject(ops) {
  const authData = yield select(getAuth);
  const { id, projectId } = ops.data;

  try {
    if (!authData?.access_token) {
      history.push('/login');
    } else {
      const response = yield call(api, `/users/${id}?projectId=${projectId}`, 'GET', null, authData.access_token);
      if (response.ok) {
        const parsed = yield response.json();
        yield put(meInProjectSuccess(parsed));
      } else if (response.status === 401) {
        yield put(refreshRequest(meInProjectRequest, data));
      } else {
        const parsed = yield response.json();
        yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
      }
    }
  } catch (e) {
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* inviteUser(ops) {
  const { data, callback } = ops;
  const { email, ...other } = data;

  try {
    const response = yield call(api, '/sign-up', 'POST', other);
    if (response.ok) {
      yield put(inviteSuccess());
      yield put(authRequest({ username: email, password: data.password }, callback));
    } else {
      const parsed = yield response.json();
      if (callback) callback(false);
      yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
    }
  } catch (e) {
    if (callback) callback(false);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* checkHash(ops) {
  const { data, callback } = ops;

  try {
    const response = yield call(api, '/invite/validate', 'POST', data);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(checkHashSuccess());
      if (callback) callback(parsed);
    } else if (callback) callback(false);
  } catch (e) {
    if (callback) callback(false);
  }
}

export function* resetPassword(ops) {
  const { data, callback } = ops;

  try {
    const response = yield call(api, '/reset-password', 'POST', { email: data.email });
    if (response.ok) {
      const parsed = yield response.json();
      if (callback) callback(true);
      yield put(resetPasswordSuccess());
    } else {
      if (callback) callback(false);
      const parsed = yield response.json();
      yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
    }
  } catch (e) {
    if (callback) callback(false);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

export function* changePassword(ops) {
  const { data, callback } = ops;
  const { code, password } = data;

  try {
    const response = yield call(api, '/reset-password/validate', 'POST', { code, password });
    if (response.ok) {
      if (callback) callback(true);
      yield put(changePasswordSuccess());
    } else {
      if (callback) callback(false);
      const parsed = yield response.json();
      yield put(createToastRequest({ type: 'error', text: parsed?.error, code: parsed?.code }));
    }
  } catch (e) {
    if (callback) callback(false);
    yield put(createToastRequest({ type: 'error', text: 'Unexpected Error' }));
  }
}

function* authSaga() {
  yield takeEvery(Auth.meRequest, getCurrentUser);
  yield takeEvery(Auth.meInProjectRequest, getCurrentUserInProject);
  yield takeEvery(Auth.authRequest, authUser);
  yield takeEvery(Auth.resetPasswordRequest, resetPassword);
  yield takeEvery(Auth.changePasswordRequest, changePassword);
  yield takeEvery(Auth.registerRequest, registerUser);
  yield takeEvery(Auth.inviteRequest, inviteUser);
  yield takeEvery(Auth.refreshRequest, refreshToken);
  yield takeEvery(Auth.logoutRequest, logoutUser);
  yield takeEvery(Auth.checkHashRequest, checkHash);
}

function* configSaga() {
  yield all([fork(authSaga)]);
}

export default configSaga;
