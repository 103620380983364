@use "sass:map";
@import '../../styles/variables.scss';

.checkboxWrap {
  display: flex;
  align-items: center;
  margin-right: 40px;

  input {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  & b {
    font-weight: 700;
  }

  .small {
    width: 16px;
    height: 16px;
  }

  .middle {
    width: 24px;
    height: 24px;
  }

  .checkbox {
    display: inline-block;
    transition: all 150ms;
    margin-right: 12px;
    border: 1px solid map.get($colors-basic, "textBlack");
    box-sizing: border-box;
    border-radius: 2px;
    
    &.checked {
      svg {
        fill: none;
        stroke: map.get($colors-basic, "textBlack");
        stroke-width: 2px;
      }
    }
    
    &.unchecked {
      svg {
        display: none;
      }
    }
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  &.colored {

    .checkbox {
      border: 1px solid map.get($colors-basic, "textGray");
      border-radius: 4px;

      &.checked {
        background: map.get($colors-basic, "primaryBlue");
        border: 1px solid map.get($colors-basic, "primaryBlue");

        svg {
          stroke: white;
        }
      }

      &.unchecked {
        background: map.get($colors-basic, "inputGrey");
        border: 1px solid map.get($colors-basic, "divider");
      }

    }

  }

}

