@use "sass:map";
@import '../../styles/variables.scss';

.select {
    width: 100%;
    position: relative;

    &.active {
      button {
        border: 1px solid map.get($colors-basic, "primaryBlue");

        &:hover:not(:disabled) {
          background-color: map.get($colors-basic, "inputGrey");
        }

        &:disabled {
          text-align: center;
          padding-right: 12px;
          padding-left: 12px;
        }

        .selectAngle {
          transform: translateY(-40%) rotate(-180deg);
        }
      }
    }

    &.text {
      &.active {
        button {
          border: none;
        }
      }
      button {
        cursor: pointer;
        height: auto;
        border: none;
        background-color: inherit;

        &:focus, &:hover:not(:disabled) {
          border: none;
          background-color: inherit;
        }
      }

      ul.options {
        top: 28px;

        li {
          padding: 6px 0;
        }
      }
    }

    &.error {
      button {
        border: 1px solid map.get($colors-red, 100);
        background-color: #FEF4F4;
      }
    }

    button {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        color: map.get($colors-basic, "textBlack");
        outline: none;
        font-size: map.get($font-sizes, "body");
        background-color: map.get($colors-basic, "inputGrey");
        height: 36px;
        padding-right: 28px;
        padding-left: 12px;
        border: 1px solid map.get($colors-basic, "inputGrey");
        border-radius: 4px;
        text-align: left;

        .selectAngle {
          position: absolute;
          right: 12px;
          top: 45%;
          transform: translateY(-50%);
          transition: transform .2s;
        }

        .placeholder {
          color: map.get($colors-basic, "textGray");
        }

        .selectedTitle {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:hover:not(:disabled) {
          background-color: map.get($colors-basic, "selectedOption");
        }

        &:focus {
          border: 1px solid map.get($colors-basic, "primaryBlue");
        }

        &:disabled {
          text-align: center;
          padding-right: 12px;
          padding-left: 12px;
        }
      }
    
      ul.options {
        max-height: 160px;
        overscroll-behavior: none;
        overflow: auto;
        z-index: 9999;
        background-color: #fff;
        box-sizing: border-box;
        position: absolute;
        top: 40px;
        width: 100%;
        display: none;
        list-style: none;
        margin-top: 0px;
        border-radius: 4px;
        box-shadow: 0px 2px 6px rgba(8,8,40, 0.1);

        &.top {
          top: unset;
          bottom: 40px;
        }

        &.show {
            display: block;
        }

        li {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 0 9px 0;
            margin: 0 12px;
            border-bottom: 1px solid map.get($colors-basic, "divider");
            text-align: start;
            overflow: hidden;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            
            &.delete {
              color: map.get($colors-red, 100);
            }
            
            &.hidden {
              display: none;
            }

            &[disabled] {
              color: map.get($colors-basic, "textDisabled");
            }
            
            &:last-child {
              border-bottom: none;
            }
          }
          
        li:active,
        li[aria-selected="true"] {
          color: map.get($colors-basic, "primaryBlue");

          span {
            padding-right: 12px;
          }

          .iconSelected {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        li:active,
        li:focus,
        li:hover,
        li[aria-selected="true"] {
          cursor: pointer;
        }
      }
}

